import { render, staticRenderFns } from "./CollectionsSection.vue?vue&type=template&id=5f1bffd3&scoped=true&"
import script from "./CollectionsSection.vue?vue&type=script&lang=js&"
export * from "./CollectionsSection.vue?vue&type=script&lang=js&"
import style0 from "./CollectionsSection.vue?vue&type=style&index=0&id=5f1bffd3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f1bffd3",
  null
  
)

export default component.exports
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QExpansionItem,QItemSection,QItem,QSkeleton,QIcon,QBtn,QMenu,QList,QSpinnerDots});qInstall(component, 'directives', {ClosePopup});
