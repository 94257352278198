<template>
  <div class="q-pa-md">
    <div class="stats-bar">
      <q-chip icon="o_check_box" square class="stats-badge">{{ selectedImages.length }}</q-chip>
      <q-chip icon="o_image" square class="stats-badge">{{ images.length }} / {{ pagination.total }} </q-chip>
      <q-chip icon="o_select_all" square class="stats-badge" style="margin-right: auto" clickable @click="toggleSelectAll">
        {{ selectedImages.length === images.length ? 'Deselect All' : 'Select All' }}
      </q-chip>

      <q-btn
        :disabled="selectedImages.length === 0"
        class="action-button"
        icon="o_cloud_download"
        :label="'Download'"
        no-caps
        @click="handleExport"
        :loading="isExporting"
        :percentage="exportProgress"
        flat
      />

      <q-btn
        v-if="selectedCollectionId"
        :disabled="selectedImages.length === 0"
        class="action-button remove-button"
        icon="o_delete_outline"
        :label="'Remove'"
        no-caps
        @click="handleRemoveFromCollection"
        :loading="isRemovingFromCollection"
        flat
      />

      <q-btn
        v-if="!selectedCollectionId"
        :disabled="selectedImages.length === 0"
        class="action-button add-to-collection-button"
        icon="o_collections_bookmark"
        :label="'Add to Collection'"
        no-caps
        @click="openAddToCollectionDialog"
        :loading="isAddingToCollection"
        flat
      />
    </div>
    <div class="images-grid">
      <div v-for="image in images" :key="image.id" class="grid-item" :class="[getImageClass(image), { selected: isSelected(image) }]" @click="toggleSelection(image)">
        <div class="selection-overlay" v-if="isSelected(image)">
          <q-icon name="check_circle" size="32px" color="white" />
        </div>
        <q-img :src="image.preview_url" :placeholder-src="image.placeholder_url" class="grid-image" fit="cover" />
        <div class="image-overlay">
          <div class="overlay-info">
            <q-chip icon="o_camera" text-color="grey-6" square size="xs" class="overlay-chip">{{ image.shoot_id }}</q-chip>
            <q-chip icon="o_image" text-color="grey-6" v-if="image.width && image.height" square size="xs" class="overlay-chip">
              {{ `${image.width}x${image.height}` }}
            </q-chip>
          </div>
          <div class="action-buttons">
            <q-btn color="black" flat round class="action-btn" @click.stop="handleDownload(image)" :loading="downloadingStates[image.id]">
              <q-icon name="o_download" size="18px" />
              <q-tooltip>Download Image</q-tooltip>
            </q-btn>

            <q-btn color="black" flat round class="action-btn" @click.stop="handleCopyLink(image.url)">
              <q-icon name="o_link" size="18px" />
              <q-tooltip>Copy Link</q-tooltip>
            </q-btn>

            <q-btn v-if="role === 'admin' || role === 'client'" color="black" flat round class="action-btn" @click.stop="shareToMeta(image)">
              <q-icon name="share" size="18px" />
              <q-tooltip>Share on Meta</q-tooltip>
            </q-btn>
            <q-btn color="black" flat round class="action-btn" @click.stop="openLightbox(images.indexOf(image))">
              <q-icon name="o_fullscreen" size="18px" />
              <q-tooltip>Open in Lightbox</q-tooltip>
            </q-btn>
            <q-btn color="black" flat round class="action-btn" @click.stop="openEditor(image)">
              <q-icon name="o_edit" size="18px" />
              <q-tooltip>Edit Image</q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- Removal Confirmation Dialog -->
    <q-dialog v-model="showRemoveConfirmDialog" transition-show="scale" transition-hide="scale" seamless>
      <q-card class="modal-card">
        <div class="modal-pattern-bg delete-pattern"></div>
        <q-card-section class="modal-header delete-header">
          <q-icon name="remove_circle" color="negative" class="modal-header-icon" />
          <div class="modal-title delete-title">Remove from Collection</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup class="close-btn" />
        </q-card-section>

        <q-separator class="delete-separator" />

        <q-card-section class="modal-body">
          <div class="warning-box">
            <q-icon name="warning" color="negative" class="warning-icon" />
            <div class="warning-text">This will remove the selected images from this collection.</div>
          </div>

          <p class="confirmation-text">Do you want to remove {{ selectedImages.length }} image{{ selectedImages.length !== 1 ? 's' : '' }} from this collection?</p>

          <div class="note-text">
            <q-icon name="info" class="note-icon" />
            <div>This will only remove the images from the collection, not delete them from your library.</div>
          </div>
        </q-card-section>

        <q-card-actions class="modal-actions">
          <q-btn flat no-caps label="Cancel" color="grey-7" v-close-popup class="modal-btn cancel-btn btn-with-transition" />
          <q-btn flat no-caps label="Remove" :loading="isRemovingFromCollection" @click="confirmRemoveFromCollection" class="modal-btn delete-btn btn-with-transition" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <ImageFormatDialog v-if="showExportDialog" v-model="showExportDialog" @format="exportImages" />
    <q-dialog v-if="showShareDialog" v-model="showShareDialog" @hide="selectedShareImage = null" maximized>
      <MetaShareDialog
        :metaIntegration="metaIntegration"
        :mediaUrl="selectedShareImage.url"
        :previewUrl="selectedShareImage.preview_url"
        :placeholderUrl="selectedShareImage.placeholder_url"
        :imageRatio="selectedShareImage.aspect_ratio"
        @hide="selectedShareImage = null"
      />
    </q-dialog>
    <GalleryLightbox v-if="lightboxOpen" :images="images" :startIndex="lightboxStartIndex" @close="lightboxOpen = false" />
    <ImagesEditor
      v-if="selectedImageForEditting !== null && showEditorDialog"
      :isOpen="showEditorDialog"
      :image="selectedImageForEditting"
      @update:isOpen="showEditorDialog = $event"
      @close="closeEditor"
    />
    <AddToCollectionDialog
      :show="showAddToCollectionDialog"
      @update:show="showAddToCollectionDialog = $event"
      :selectedImages="selectedImages"
      @add-success="handleAddToCollectionSuccess"
      @loading-state="isAddingToCollection = $event"
    />
  </div>
</template>

<script>
import { ContentApi } from '@api/index';
import MetaShareDialog from './meta/MetaShareDialog.vue';
import ImageFormatDialog from './ImageFormatDialog.vue';
import GalleryLightbox from './GalleryLightbox.vue';
import ImagesEditor from './ImagesEditor.vue';
import AddToCollectionDialog from '@components/gallery/AddToCollectionDialog.vue';

const ASPECT_RATIO_THRESHOLD = 1.5;
const TALL_RATIO_THRESHOLD = 0.7;

export default {
  name: 'ImagesGrid',
  components: {
    MetaShareDialog,
    ImageFormatDialog,
    GalleryLightbox,
    ImagesEditor,
    AddToCollectionDialog
  },
  computed: {
    showShareDialog: {
      get() {
        return !!this.selectedShareImage;
      },
      set(value) {
        if (!value) {
          this.selectedShareImage = null;
        }
      }
    }
  },
  watch: {
    '$route.query.editImage': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          try {
            const imageData = JSON.parse(newValue);
            console.log('Opening editor', imageData);
            this.selectedImageForEditting = imageData;
            this.showEditorDialog = true;
          } catch (error) {
            console.error('Error parsing image data:', error);
          }
        }
      }
    }
  },

  props: {
    images: {
      type: Array,
      required: true,
      default: () => []
    },
    pagination: {},
    metaIntegration: {
      type: Object,
      required: false,
      default: () => {}
    },
    isMetaConnected: {
      type: Boolean,
      required: false,
      default: false
    },
    visualSearchValue: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: '',
      required: true
    },
    selectedCollectionId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      downloadingStates: {},
      selectedImages: [],
      downloadProgress: 0,
      showExportDialog: false,
      isExporting: false,
      exportProgress: 0,
      selectedShareImage: null,
      lightboxOpen: false,
      lightboxStartIndex: 0,
      showEditorDialog: false,
      selectedImageForEditting: null,
      showAddToCollectionDialog: false,
      isAddingToCollection: false,
      isRemovingFromCollection: false,
      showRemoveConfirmDialog: false
    };
  },

  methods: {
    isSelected(image) {
      return this.selectedImages.some(selected => selected.id === image.id);
    },

    toggleSelection(image) {
      if (this.isSelected(image)) {
        this.selectedImages = this.selectedImages.filter(selected => selected.id !== image.id);
      } else {
        this.selectedImages.push(image);
      }
    },

    handleExport() {
      if (this.selectedImages.length === 0) return;
      this.showExportDialog = true;
    },

    handleRemoveFromCollection() {
      if (!this.selectedCollectionId || this.selectedImages.length === 0) return;
      // Show confirmation dialog instead of immediately removing
      this.showRemoveConfirmDialog = true;
    },

    async confirmRemoveFromCollection() {
      if (!this.selectedCollectionId || this.selectedImages.length === 0) return;

      this.isRemovingFromCollection = true;

      try {
        // Import CollectionsApi from the same location as in Gallery.vue
        const { CollectionsApi } = require('@api/modules/collections');

        // Call the API to remove items from collection
        await CollectionsApi.removeItemsFromCollection(
          this.selectedCollectionId,
          this.selectedImages.map(img => img.id)
        );

        // Close the dialog
        this.showRemoveConfirmDialog = false;

        // Notify success
        this.$q.notify({
          type: 'positive',
          message: `${this.selectedImages.length} image(s) removed from collection`,
          position: 'top',
          timeout: 2000
        });

        // Clear selected images
        this.selectedImages = [];

        // Emit event to notify parent to refresh the images
        this.$emit('items-removed-from-collection');
      } catch (error) {
        console.error('Error removing items from collection:', error);
        this.$q.notify({
          type: 'negative',
          message: `Failed to remove images: ${error.message}`,
          position: 'top',
          timeout: 2000
        });
      } finally {
        this.isRemovingFromCollection = false;
      }
    },

    async exportImages(formatOptions) {
      if (this.selectedImages.length === 0) return;
      this.showExportDialog = false;
      this.isExporting = true;
      this.exportProgress = 0;

      try {
        const exportedImages = await ContentApi.exportImages({
          imageIds: this.selectedImages.map(img => img.id),
          formatOptions
        });

        const JSZip = require('jszip');
        const zip = new JSZip();
        const totalImages = exportedImages.length;
        let completedImages = 0;

        await Promise.all(
          exportedImages.map(async image => {
            try {
              const response = await fetch(image.url, { redirect: 'follow' });
              const blob = await response.blob();
              const filename = image.url.split('/').pop().split('?')[0];
              zip.file(filename, blob);
              completedImages++;
              this.exportProgress = Math.round((completedImages / totalImages) * 100);
            } catch (error) {
              console.error(`Error downloading image ${image.id}:`, error);
            }
          })
        );

        const content = await zip.generateAsync({ type: 'blob' });
        const url = window.URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = url;
        a.download = `formatted-images-${new Date().getTime()}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error in batch format and download:', error);
      } finally {
        this.isExporting = false;
        this.exportProgress = 0;
      }
    },

    getImageRatio(image) {
      return image.aspect_ratio || image.width / image.height;
    },

    getImageClass(image) {
      const aspectRatio = this.getImageRatio(image);
      if (aspectRatio > ASPECT_RATIO_THRESHOLD) {
        return 'grid-item--landscape';
      } else if (aspectRatio < TALL_RATIO_THRESHOLD) {
        return 'grid-item--portrait';
      }
      return '';
    },

    async handleDownload(image) {
      this.$set(this.downloadingStates, image.id, true);
      try {
        const response = await fetch(image.url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = image.url.split('/').pop().split('?')[0];
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error downloading image:', error);
      } finally {
        this.$set(this.downloadingStates, image.id, false);
      }
    },

    async handleCopyLink(imageUrl) {
      try {
        await navigator.clipboard.writeText(imageUrl);
        this.$q.notify({
          type: 'primary',
          message: 'Link copied to clipboard'
        });
      } catch (error) {
        console.error('Error copying to clipboard:', error);
      }
    },

    shareToMeta(image) {
      if (!this.isMetaConnected) {
        this.$router.push('/integrations');
        return;
      }
      this.selectedShareImage = image;
    },

    handleMetaShare() {
      this.$q.notify({
        type: 'positive',
        message: 'Image shared successfully!'
      });
    },
    toggleSelectAll() {
      if (this.selectedImages.length === this.images.length) {
        // Deselect all
        this.selectedImages = [];
      } else {
        // Select all
        this.selectedImages = [...this.images];
      }
    },
    openLightbox(index) {
      this.lightboxStartIndex = index;
      this.lightboxOpen = true;
    },
    openEditor(image) {
      this.selectedImageForEditting = image;
      this.showEditorDialog = true;
    },
    closeEditor() {
      this.showEditorDialog = false;
      this.selectedImageForEditting = null;
    },
    openAddToCollectionDialog() {
      this.showAddToCollectionDialog = true;
    },
    handleAddToCollectionSuccess(message) {
      this.isAddingToCollection = false;
      this.$q.notify({
        type: 'positive',
        message: message,
        position: 'top',
        timeout: 2000
      });
      this.selectedImages = []; // Clear selected images after successful addition
    }
  }
};
</script>

<style scoped lang="scss">
.content-wrapper {
  position: relative;
}

.stats-bar {
  position: fixed;
  height: 60px;
  width: calc(100% - 24px);
  max-width: calc(100vw - 336px);
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0 16px;
  left: 320px;
  right: 16px;

  @media (max-width: 1024px) {
    width: calc(100% - 32px);
    max-width: 100%;
    left: 16px;
  }

  .stats-badge {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 4px;
    margin-right: 8px;
    @include inter-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
    height: 40px;
    padding: 0 20px;
  }
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-auto-rows: 275px;
  grid-auto-flow: dense;
  gap: 12px;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding-bottom: 70px;
}

.grid-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;

  &.selected {
    transform: scale(0.98);

    .grid-image {
      filter: brightness(0.7);
    }
  }

  &:hover .image-overlay {
    opacity: 1;
    pointer-events: auto;
  }

  &--landscape {
    grid-column: span 2;
    grid-row: span 2;
    @media (max-width: 900px) {
      grid-column: span 1;
      grid-row: span 1;
    }
  }

  &--portrait {
    grid-row: span 2;
  }
}

.selection-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  pointer-events: none;
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  transition: filter 0.3s ease;
  content-visibility: auto;
  contain: content;
  contain-intrinsic-size: 275px;
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.overlay-info {
  display: flex;
  position: absolute;
  top: 8px;
  left: 8px;
}

.overlay-chip {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 18px;
  font-size: 12px;
  padding: 12px;
  color: #151515 !important;
}

.action-buttons {
  display: flex;
  gap: 6px;
  margin-top: auto;
  padding: 8px;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
}

.action-btn {
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #151515;

  &:hover {
    transform: scale(1.1);
    color: var(--main-btn-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}

.action-button {
  @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
  margin: 0 4px;
  background: var(--main-btn-color);
  color: var(--primary-text-color);
  border-radius: 8px;
  height: 40px;
  transition: all 0.2s ease;
  padding: 0 10px;
  min-width: 0;
  max-width: fit-content;
  white-space: nowrap;

  &:hover:not(:disabled) {
    opacity: 1.2;
  }

  &:active:not(:disabled) {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.6;
    background-color: #f0f0f0;
    color: #9e9e9e;
    box-shadow: none;
  }

  .q-icon {
    margin-right: 4px;
    font-size: 1.2em;
  }
}

.add-to-collection-button {
  background-color: #ffeaac;
  color: var(--primary-text-color);
}

.remove-button {
  background-color: var(--button-bg-danger);
  color: var(--terziary-text-color);

  &:hover:not(:disabled) {
    background-color: var(--button-bg-danger);
    opacity: 0.9;
  }

  &:disabled {
    background-color: rgba(244, 67, 54, 0.3);
    color: rgba(255, 255, 255, 0.7);
  }

  .q-icon {
    color: var(--terziary-text-color);
  }
}

@media (max-width: 600px) {
  .images-grid {
    grid-template-columns: 1fr;
    grid-auto-rows: 200px;
  }
  .grid-image {
    contain-intrinsic-size: 200px;
  }
}

/* Dialog Styles */
.modal-card {
  min-width: 450px;
  border-radius: 16px;
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15);
  will-change: transform, opacity;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.2s ease;
}

.modal-pattern-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: radial-gradient(circle at 10px 10px, rgba(0, 0, 0, 0.03) 1px, transparent 0);
  background-size: 20px 20px;
  z-index: 0;
  opacity: 0.3;
  pointer-events: none;
}

.delete-pattern {
  background-image: radial-gradient(circle at 10px 10px, rgba(244, 67, 54, 0.03) 1px, transparent 0);
}

.modal-header {
  display: flex;
  align-items: center;
  border-radius: 16px 16px 0 0;
  padding: 24px 24px;
  position: relative;
  z-index: 1;
}

.delete-header {
  background: white;
  border-bottom: 1px solid rgba(244, 67, 54, 0.1);
}

.modal-header-icon {
  font-size: 24px;
  margin-right: 12px;
}

.modal-title {
  @include mona-sans-font($size: 20px, $bolded: true, $color: #333);
  letter-spacing: 0.3px;
}

.delete-title {
  color: var(--q-negative);
}

.close-btn {
  opacity: 0.7;
  transition: opacity 0.2s ease, background-color 0.2s ease;

  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.05);
  }
}

.modal-body {
  padding: 28px 24px 16px;
  position: relative;
  z-index: 1;
  background-color: white;
}

.warning-box {
  display: flex;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.04);
  padding: 16px;
  border-radius: 10px;
  border-left: 4px solid #f44336;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(244, 67, 54, 0.1);
}

.warning-icon {
  font-size: 24px;
  margin-right: 12px;
}

.warning-text {
  @include mona-sans-font($size: 15px, $bolded: true, $color: var(--danger));
}

.confirmation-text {
  @include inter-font($size: 15px, $color: var(--primary-text-color));
  margin: 20px 0;
  line-height: 1.6;
}

.note-text {
  @include inter-font($size: 11px, $bolded: false, $color: var(--secondary-text-color));
  margin-top: 28px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 10px;
  border-radius: 6px;
}

.note-icon {
  font-size: 14px;
  margin-right: 6px;
  flex-shrink: 0;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px 24px;
  background-color: white;
  position: relative;
  z-index: 1;
}

.modal-btn {
  @include inter-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
  height: 44px;
  border-radius: 8px;
  letter-spacing: 0.3px;
  padding: 0 20px;
}

.btn-with-transition {
  transition: background-color 0.2s ease, opacity 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
  }
}

.cancel-btn {
  margin-right: 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.delete-btn {
  background-color: var(--button-bg-danger);
  color: var(--terziary-text-color);

  &:hover {
    background-color: var(--button-bg-danger);
  }
}

.delete-separator {
  background-color: #ffebee;
  height: 2px;
}
</style>
