import { render, staticRenderFns } from "./OutpaintingControls.vue?vue&type=template&id=0d9069dc&scoped=true&"
import script from "./OutpaintingControls.vue?vue&type=script&lang=js&"
export * from "./OutpaintingControls.vue?vue&type=script&lang=js&"
import style0 from "./OutpaintingControls.vue?vue&type=style&index=0&id=0d9069dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d9069dc",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QIcon,QInput,QSpinner});
