<template>
  <q-expansion-item default-opened style="text-align: left" class="sidebar-section">
    <template v-slot:header>
      <q-item-section class="section-title">All Collections</q-item-section>
    </template>

    <div class="collections-wrapper">
      <div class="collections-container q-mt-sm">
        <div v-if="isLoading" class="text-center q-py-sm">
          <!-- Skeleton loading for collections -->
          <div v-for="i in 3" :key="i" class="collection-item q-mb-xs">
            <q-item>
              <q-item-section style="text-align: left">
                <q-skeleton type="text" width="70%" class="q-mb-xs" />
                <q-skeleton type="text" width="40%" />
              </q-item-section>
              <q-item-section side>
                <q-skeleton size="24px" type="circle" />
              </q-item-section>
            </q-item>
          </div>
        </div>
        <template v-else>
          <!-- All Assets option -->
          <div class="collection-item q-mb-xs" :class="{ 'collection-item-selected': selectedCollection === null }">
            <q-item>
              <q-item-section style="text-align: left" class="cursor-pointer" @click="$emit('select-collection', null)">
                <div class="collection-name">All Assets</div>
                <div class="collection-count">View all images</div>
              </q-item-section>
            </q-item>
          </div>

          <!-- Empty state for collections -->
          <div v-if="collections.length === 0" class="empty-state q-py-md text-center">
            <q-icon name="folder_open" size="48px" color="grey-4" />
            <div class="empty-state-text q-mt-sm">No collections yet</div>
            <div class="empty-state-subtext">Create a collection to organize your content</div>
          </div>

          <div v-else v-for="collection in collections" :key="collection.id" class="collection-item q-mb-xs" :class="{ 'collection-item-selected': selectedCollection === collection.id }">
            <q-item>
              <q-item-section style="text-align: left" class="cursor-pointer" @click="$emit('select-collection', collection.id)">
                <div class="collection-name">{{ collection.name }}</div>
                <div class="collection-count">{{ collection.item_count }} items</div>
              </q-item-section>
              <q-item-section side>
                <q-btn flat round dense icon="more_horiz" size="sm" @click.stop>
                  <q-menu>
                    <q-list style="min-width: 120px">
                      <q-item clickable v-close-popup @click="$emit('confirm-edit-collection', collection)">
                        <q-item-section avatar class="menu-icon">
                          <q-icon name="o_edit" size="18px" color="primary" />
                        </q-item-section>
                        <q-item-section class="menu-action rename-action">Rename</q-item-section>
                      </q-item>
                      <q-item clickable v-close-popup @click="$emit('confirm-delete-collection', collection)">
                        <q-item-section avatar class="menu-icon">
                          <q-icon name="o_delete" size="18px" color="negative" />
                        </q-item-section>
                        <q-item-section class="menu-action delete-action">Delete</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
              </q-item-section>
            </q-item>
          </div>
        </template>
      </div>

      <q-btn no-caps class="create-collection-btn q-mt-md" icon="add" label="Add New Collection" flat @click="$emit('create-collection')" :loading="isCreatingCollection">
        <template v-slot:loading>
          <q-spinner-dots color="white" size="20px" />
        </template>
      </q-btn>
    </div>
  </q-expansion-item>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'CollectionsSection',

  props: {
    collections: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    selectedCollection: {
      type: [String, Number],
      default: null
    },
    isCreatingCollection: {
      type: Boolean,
      default: false
    }
  },

  emits: ['select-collection', 'confirm-edit-collection', 'confirm-delete-collection', 'create-collection']
});
</script>

<style lang="scss" scoped>
.sidebar-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 16px;
  background: white;
  overflow: hidden;
  border: 1px solid var(--terziary-btn-color);
}

.section-title {
  @include mona-sans-font($size: 16px, $bolded: true, $color: var(--main-text-color));
  letter-spacing: -0.02em;
}

.collections-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
  padding: 0 8px;
}

.collections-container {
  margin-bottom: 20px;
  max-height: 280px;
  overflow-y: auto;
  padding-right: 2px;
  padding-left: 2px;
  overflow-x: hidden;
  scrollbar-width: thin;
  box-sizing: border-box;
  overscroll-behavior: contain;

  /* Custom scrollbar to prevent layout shifts */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
}

.collection-item {
  border-radius: 8px;
  transition: all 0.2s ease;
  border: 1px solid transparent;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 8px;
  padding: 2px 0;
}

.collection-item:hover {
  background: rgba(0, 0, 0, 0.03);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.collection-item-selected {
  border: 1px solid var(--main-btn-color);
  background-color: rgba(21, 21, 21, 0.04);
}

.collection-name {
  @include mona-sans-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collection-count {
  @include inter-font($size: 12px, $bolded: false, $color: var(--secondary-text-color));
}

.menu-icon {
  min-width: 24px;
  padding-right: 16px;
}

.menu-action {
  @include inter-font($size: 14px, $bolded: false, $color: var(--primary-text-color));
  transition: color 0.2s ease;
}

.rename-action {
  color: var(--primary-color);

  &:hover {
    color: var(--primary-color-dark);
  }
}

.delete-action {
  color: var(--negative-color);

  &:hover {
    color: var(--negative-color-dark);
  }
}

.create-collection-btn {
  height: 50px;
  transition: opacity 0.2s ease;
  @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
  border-radius: 8px;
  background-color: var(--main-btn-color);
  width: 100%;
  padding: 16px;
  justify-content: center;
  margin-top: 8px;

  &:hover {
    opacity: 1;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px 0;
}

.empty-state-text {
  @include mona-sans-font($size: 14px, $bolded: true, $color: var(--secondary-text-color));
  opacity: 0.8;
}

.empty-state-subtext {
  @include inter-font($size: 12px, $bolded: false, $color: var(--secondary-text-color));
  opacity: 0.6;
  max-width: 80%;
  text-align: center;
  margin-top: 4px;
}
</style>
