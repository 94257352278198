<template>
  <div class="editor-layout">
    <!-- Sticky Sidebar with Tool Icons -->
    <div class="sticky-sidebar">
      <ToolIcon v-for="tool in tools" :key="tool.id" :tool="tool" :is-active="activeTool === tool.id" @toggle="toggleTool" @hover="showTooltip" @hover-exit="hideTooltip" />
    </div>

    <!-- Main Content Sidebar -->
    <div class="content-sidebar">
      <!-- Tool Content Container -->
      <div class="tool-content" v-if="activeTool">
        <!-- Tool Header -->
        <div class="tool-header">
          <h2 class="tool-title">{{ getActiveTool.name }}</h2>
          <p class="tool-description">{{ getToolDescription(activeTool) }}</p>
        </div>

        <q-separator class="q-my-sm" />

        <p class="tool-guide">{{ getToolGuide }}</p>

        <!-- Tool Controls -->
        <div class="tool-controls">
          <ObjectRemovalControls
            v-if="activeTool === 'removeObjects'"
            :brush-size="brushSize"
            :is-removing="isRemoving"
            :operation-status="operationStatus"
            :has-mask-data="hasMaskData"
            @update:brushSize="$emit('update:brushSize', $event)"
            @clearMask="handleClearMask"
            @removeObjects="$emit('removeObjects')"
          />

          <BackgroundRemovalControls v-if="activeTool === 'removeBackground'" :is-removing="isRemoving" :operation-status="operationStatus" @removeBackground="$emit('removeBackground')" />

          <OutpaintingControls
            v-if="activeTool === 'outpainting'"
            :is-outpainting="isOutpainting"
            :operation-status="operationStatus"
            :selected-mode="selectedOutpaintingMode"
            :prompt="prompt"
            @update:selected-mode="selectedOutpaintingMode = $event"
            @update:prompt="$emit('update:prompt', $event)"
            @outpaint="$emit('outpaint', $event)"
          />

          <GenerativeFillControls
            v-if="activeTool === 'generate'"
            :brush-size="brushSize"
            :is-generating="isGenerating"
            :operation-status="operationStatus"
            :prompt="prompt"
            :has-mask-data="hasMaskData"
            @update:brushSize="$emit('update:brushSize', $event)"
            @update:prompt="$emit('update:prompt', $event)"
            @clearMask="handleClearMask"
            @generateFill="$emit('generateFill')"
          />

          <BackgroundGenerationControls
            v-if="activeTool === 'generateBackground'"
            :is-generating-background="isGeneratingBackground"
            :operation-status="operationStatus"
            @generateBackground="$emit('generateBackground')"
          />
        </div>
      </div>

      <!-- Welcome screen when no tool is selected -->
      <EmptyState v-else />
    </div>

    <!-- Custom tooltip -->
    <ToolTooltip
      :tool-id="hoveredTool"
      :name="hoveredTool ? getToolName(hoveredTool) : ''"
      :description="hoveredTool ? getToolDescription(hoveredTool) : ''"
      :is-disabled="isToolDisabled(hoveredTool)"
      :position-style="tooltipStyles"
    />
  </div>
</template>

<script>
import ToolIcon from './tools/ToolIcon.vue';
import ToolTooltip from './tools/ToolTooltip.vue';
import EmptyState from './tools/EmptyState.vue';
import ObjectRemovalControls from './tools/tool-controls/ObjectRemovalControls.vue';
import BackgroundRemovalControls from './tools/tool-controls/BackgroundRemovalControls.vue';
import OutpaintingControls from './tools/tool-controls/OutpaintingControls.vue';
import GenerativeFillControls from './tools/tool-controls/GenerativeFillControls.vue';
import BackgroundGenerationControls from './tools/tool-controls/BackgroundGenerationControls.vue';

export default {
  name: 'ImageEditorSidebar',
  components: {
    ToolIcon,
    ToolTooltip,
    EmptyState,
    ObjectRemovalControls,
    BackgroundRemovalControls,
    OutpaintingControls,
    GenerativeFillControls,
    BackgroundGenerationControls
  },
  props: {
    brushSize: {
      type: Number,
      required: true,
      default: 75
    },
    isRemoving: {
      type: Boolean,
      required: true
    },
    isGenerating: {
      type: Boolean,
      required: true
    },
    isGeneratingBackground: {
      type: Boolean,
      required: false,
      default: false
    },
    isOutpainting: {
      type: Boolean,
      required: false,
      default: false
    },
    operationStatus: {
      type: String,
      required: false
    },
    hasMaskData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTool: '',
      hoveredTool: null,
      tooltipStyles: {
        top: '0px',
        left: '0px'
      },
      selectedOutpaintingMode: '',
      prompt: '',
      tools: [
        { id: 'removeObjects', name: 'Object Removal', icon: 'healing' },
        { id: 'removeBackground', name: 'Background Removal', icon: 'content_cut' },
        { id: 'outpainting', name: 'Image Expansion', icon: 'zoom_out_map' },
        { id: 'generate', name: 'Generative Fill', icon: 'auto_fix_high' },
        { id: 'generateBackground', name: 'Background Generation', icon: 'auto_awesome', disabled: true }
      ]
    };
  },
  computed: {
    getActiveTool() {
      return this.tools.find(tool => tool.id === this.activeTool) || {};
    },
    getToolGuide() {
      const guides = {
        removeObjects: 'Use the brush to paint over objects you want to remove, then click the "Remove Objects" button.',
        removeBackground: 'Click the button below to automatically remove the background from your image.',
        outpainting: 'Select an expansion option and direction, then describe what you want in the expanded area.',
        generate: 'Paint the area you want to fill, describe what you want to generate, then click "Generate".',
        generateBackground: 'This feature is coming soon!'
      };
      return guides[this.activeTool] || '';
    }
  },
  methods: {
    getToolName(toolId) {
      const tool = this.tools.find(t => t.id === toolId);
      return tool ? tool.name : '';
    },
    isToolDisabled(toolId) {
      const tool = this.tools.find(t => t.id === toolId);
      return tool && tool.disabled === true;
    },
    getToolDescription(toolId) {
      const descriptions = {
        removeObjects: 'Select and erase unwanted objects easily',
        removeBackground: 'Automatically detect and remove image backgrounds',
        outpainting: 'Expand your image in any direction with AI-generated content',
        generate: 'Smart fill that matches the surrounding context',
        generateBackground: 'Generate new backgrounds for your images with AI'
      };
      return descriptions[toolId] || '';
    },
    handleClearMask() {
      this.$emit('clearMask');
    },
    toggleTool(toolId) {
      if (this.activeTool === toolId) {
        this.activeTool = '';
      } else {
        this.activeTool = toolId;
      }
    },
    showTooltip(toolId, event) {
      const buttonRect = event.currentTarget.getBoundingClientRect();
      this.tooltipStyles = {
        top: `${buttonRect.top + buttonRect.height / 2 - 50}px`,
        left: `${buttonRect.right + 16}px`
      };
      this.hoveredTool = toolId;
    },
    hideTooltip() {
      this.hoveredTool = null;
    },
    handlePrompt() {
      this.$emit('update:prompt', this.prompt);
    }
  },
  watch: {
    activeTool(newTool) {
      // Emit current active tool to parent component
      this.$emit('update:activeTool', newTool);
    }
  }
};
</script>

<style lang="scss" scoped>
p,
label {
  @include inter-font();
  margin-bottom: 0.5rem;
}

.editor-layout {
  display: flex;
  height: 100%;
  max-height: calc(100vh - 48px - 75px);
  position: relative;
}

/* Sticky Sidebar Styles */
.sticky-sidebar {
  position: sticky;
  top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 6px;
  margin-right: 24px;
  height: fit-content;
}

/* Content Sidebar Styles */
.content-sidebar {
  flex: 1;
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  font-family: 'Inter', sans-serif;
  max-width: 460px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
}

.tool-content {
  flex: 1;
}

.tool-header {
  margin-bottom: 12px;

  .tool-title {
    @include mona-sans-font($size: 24px, $bolded: true, $color: var(--main-text-color));
    margin-bottom: 8px;
  }

  .tool-description {
    color: #666;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
  }
}

.tool-guide {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  background: #f9fafb;
  padding: 16px;
  border-radius: 12px;
  border-left: 4px solid #fecb2f;
  margin-bottom: 20px;
}

.tool-controls {
  margin-bottom: 20px;
}
</style>
