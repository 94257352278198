<template>
  <div class="upload-section">
    <div
      class="upload-area"
      :class="{ 'drag-over': isDragging, 'has-files': files.length > 0 }"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
      @drop.prevent="onDrop"
      @click="triggerFileInput"
    >
      <input type="file" ref="fileInput" @change="onFileSelected" multiple accept="image/jpeg,image/jpg,image/png,video/mp4,video/quicktime" class="hidden-input" />

      <div v-if="files.length === 0" class="upload-placeholder">
        <q-icon name="cloud_upload" size="36px" color="grey-5" />
        <div class="upload-text">
          <p class="upload-main-text">Drag & drop files here</p>
          <p class="upload-sub-text">or click to select files</p>
        </div>
        <div class="upload-formats">Supported formats: JPEG, JPG, PNG</div>
      </div>

      <div class="selected-files-container" v-else>
        <div class="selected-files-grid">
          <div v-for="(file, index) in files.slice(0, 3)" :key="index" class="file-preview-container">
            <div class="file-preview">
              <template v-if="file.file.type.startsWith('image/')">
                <img :src="file.preview" alt="Image preview" class="preview-image" />
              </template>
              <template v-else>
                <video :src="file.preview" class="preview-image" />
                <div class="video-overlay">
                  <q-icon name="play_circle" size="32px" color="white" />
                </div>
              </template>
              <q-btn round flat dense class="remove-btn" @click.stop="$emit('remove-file', index)">
                <q-icon name="close" size="16px" />
              </q-btn>
            </div>
            <div class="file-name">{{ truncateFileName(file.file.name) }}</div>
          </div>

          <!-- Add More button when exactly 3 images -->
          <div v-if="files.length <= 3" class="file-preview-container" @click.stop="triggerFileInput">
            <div class="add-more-preview">
              <q-icon name="add" size="24px" color="grey-7" />
              <div class="add-more-text">Add more</div>
            </div>
          </div>

          <!-- Counter for more than 3 images -->
          <div v-else class="more-images-counter">
            <div class="more-counter">+{{ files.length - 3 }} more</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  name: 'UploadArea',
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  emits: ['add-files', 'remove-file'],
  setup(props, { emit }) {
    const fileInput = ref(null);
    const isDragging = ref(false);

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const onFileSelected = event => {
      const files = Array.from(event.target.files);
      addFiles(files);
      event.target.value = '';
    };

    const addFiles = files => {
      const supportedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'video/mp4', 'video/quicktime'];
      const validFiles = files.filter(file => supportedTypes.includes(file.type));
      const newFiles = validFiles.map(file => {
        const preview = URL.createObjectURL(file);
        return { file, preview };
      });

      emit('add-files', newFiles);
    };

    const removeFile = index => {
      emit('remove-file', index);
    };

    const onDragOver = () => {
      isDragging.value = true;
    };

    const onDragLeave = () => {
      isDragging.value = false;
    };

    const onDrop = event => {
      isDragging.value = false;
      const files = Array.from(event.dataTransfer.files);
      addFiles(files);
    };

    const truncateFileName = fileName => {
      if (fileName.length > 15) {
        return fileName.substring(0, 12) + '...';
      }
      return fileName;
    };

    return {
      fileInput,
      isDragging,
      triggerFileInput,
      onFileSelected,
      removeFile,
      onDragOver,
      onDragLeave,
      onDrop,
      truncateFileName
    };
  }
};
</script>

<style lang="scss" scoped>
.upload-section {
  margin-bottom: 1.5rem;
}

.upload-area {
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #fafafa;
  min-height: 200px;
  height: auto;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.upload-area.has-files {
  padding: 1rem;
  min-height: 150px;
}

.upload-area:hover {
  border-color: var(--main-btn-color);
  background-color: rgba(0, 0, 0, 0.02);
}

.upload-area.drag-over {
  border-color: var(--main-btn-color);
  background-color: rgba(0, 0, 0, 0.03);
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.upload-text {
  margin: 1rem 0;
  text-align: center;
}

.upload-main-text {
  @include mona-sans-font($size: 16px, $bolded: true, $color: var(--primary-text-color));
  margin: 0.5rem 0;
}

.upload-sub-text {
  @include inter-font($size: 14px, $color: var(--secondary-text-color));
  margin: 0.25rem 0;
}

.upload-formats {
  @include inter-font($size: 12px, $color: var(--secondary-text-color));
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
}

.hidden-input {
  display: none;
}

.selected-files-container {
  margin-top: 1rem;
}

.selected-files-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
}

.file-preview-container {
  width: 120px;
  position: relative;
}

.file-preview {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.remove-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 24px;
  height: 24px;
  min-height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  :deep(.q-icon) {
    font-size: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.file-name {
  @include inter-font($size: 12px, $color: var(--secondary-text-color));
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.more-images-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.03);
}

.more-counter {
  @include inter-font($size: 14px, $bolded: true, $color: var(--secondary-text-color));
}

.add-more-preview {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: var(--main-btn-color);
    background-color: rgba(0, 0, 0, 0.04);

    .add-more-text {
      color: var(--main-btn-color);
    }

    .q-icon {
      color: var(--main-btn-color);
    }
  }
}

.add-more-text {
  @include inter-font($size: 13px, $bolded: true, $color: var(--secondary-text-color));
  margin-top: 8px;
  transition: color 0.2s ease;
}
</style>
