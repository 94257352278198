<template>
  <div>
    <q-btn class="action-button action-button--primary" :loading="isGeneratingBackground" @click="generateBackground" flat no-caps>
      <template v-if="!isGeneratingBackground"> <q-icon name="auto_awesome" size="xs" class="button-icon" color="grey-7" /> Generate Background </template>
      <template v-slot:loading>
        <q-spinner color="grey-7" class="on-left" />
        <span v-if="operationStatus === 'preparing'">AI At Work… <q-icon name="fas fa-magic" size="xs" color="grey-7" /> </span>
        <span v-else-if="operationStatus === 'starting'">Working Our Magic! <q-icon name="fas fa-hat-wizard" size="xs" color="grey-7" /> </span>
        <span v-else-if="operationStatus === 'processing'">Almost Ready... <q-icon name="fas fa-hourglass-half" size="xs" color="grey-7" /></span>
      </template>
    </q-btn>

    <div class="coming-soon-note">
      <q-icon name="info_outline" size="sm" color="primary" class="info-icon" />
      <p>This feature is currently in development and will be available soon!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackgroundGenerationControls',
  props: {
    isGeneratingBackground: {
      type: Boolean,
      required: true
    },
    operationStatus: {
      type: String,
      default: null
    }
  },
  methods: {
    generateBackground() {
      this.$emit('generateBackground');
    }
  }
};
</script>

<style lang="scss" scoped>
.action-button {
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  @include mona-sans-font($size: 15px, $bolded: true, $color: var(--main-text-color));
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &--primary {
    background: #fecb2f;
    color: #333333;

    &:hover {
      background: #fed95f;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button-icon {
  margin-right: 8px;
  font-size: 16px;
}

.coming-soon-note {
  margin-top: 16px;
  padding: 16px;
  background-color: #fffbf0;
  border: 2px dashed #fecb2f;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;

  .info-icon {
    margin-right: 10px;
    color: #fecb2f;
    flex-shrink: 0;
  }

  p {
    @include inter-font($size: 14px, $color: var(--secondary-text-color));
    margin: 0;
    line-height: 1.5;
  }
}
</style>
