<template>
  <CollectionOption
    :label="label"
    :value="value"
    :modelValue="modelValue"
    :expanded="expanded"
    @update:modelValue="$emit('update:modelValue', $event)"
    @update:expanded="$emit('update:expanded', $event)"
  >
    <div class="q-pa-md">
      <div class="input-label q-mb-sm">Collection Name</div>
      <q-input
        v-model="collectionNameModel"
        outlined
        dense
        :rules="[val => !!val || 'Name is required']"
        :error="isDuplicateName(collectionName)"
        error-message=""
        class="q-mb-sm collection-input"
        maxlength="50"
        counter
        bg-color="white"
        placeholder="Enter collection name"
        hint="Collections help you organize related content for easier access"
      >
        <template v-slot:prepend>
          <q-icon name="folder" color="primary" size="sm" />
        </template>
        <template v-slot:append v-if="collectionName">
          <q-icon name="close" color="grey-6" class="cursor-pointer clear-icon hover-scale" @click="collectionNameModel = ''" />
        </template>
      </q-input>

      <div v-if="isDuplicateName(collectionName)" class="duplicate-warning">
        <q-icon name="warning" color="negative" size="16px" class="q-mr-xs" />
        <span>This collection already exists. Please choose a different name.</span>
      </div>
    </div>
  </CollectionOption>
</template>

<script>
import { computed } from '@vue/composition-api';
import CollectionOption from './CollectionOption.vue';

export default {
  name: 'NewCollectionOption',
  components: {
    CollectionOption
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      required: true
    },
    collectionName: {
      type: String,
      required: true
    },
    collections: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue', 'update:expanded', 'update:collection-name'],
  setup(props, { emit }) {
    const collectionNameModel = computed({
      get: () => props.collectionName,
      set: value => emit('update:collection-name', value)
    });

    // Check if collection name already exists
    const isDuplicateName = name => {
      if (!name || !props.collections || props.collections.length === 0) {
        return false;
      }

      const trimmedName = name.trim().toLowerCase();
      return props.collections.some(collection => collection.name.trim().toLowerCase() === trimmedName);
    };

    return {
      collectionNameModel,
      isDuplicateName
    };
  }
};
</script>

<style lang="scss" scoped>
.input-label {
  @include mona-sans-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
  margin-bottom: 10px;
}

.collection-input {
  :deep(.q-field__control) {
    height: 44px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }

  :deep(.q-field--focused .q-field__control) {
    box-shadow: 0 0 0 2px rgba(2, 123, 227, 0.2);
    border-color: var(--main-btn-color);
  }

  :deep(.q-field__counter) {
    color: rgba(0, 0, 0, 0.5);
  }

  :deep(.q-field--error .q-field__control) {
    border-color: var(--q-negative);
  }
}

.duplicate-warning {
  @include inter-font($size: 12px, $color: var(--q-negative));
  display: flex;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.05);
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.clear-icon {
  font-size: 16px;
  color: #9e9e9e;
}

.hover-scale:hover {
  transform: scale(1.1);
  color: #666;
}
</style>
