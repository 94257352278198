<template>
  <q-expansion-item style="text-align: left" class="sidebar-section" header-class="sidebar-section-header">
    <template v-slot:header>
      <q-item-section class="section-title">Content Filters</q-item-section>
    </template>

    <div class="filters-wrapper">
      <div class="filters-container q-mt-sm">
        <template v-if="userRole === 'admin'">
          <!-- Client Filter -->
          <div class="input-caption">Filter Content by Client</div>
          <q-select
            v-model="clientModel"
            outlined
            dense
            :options="clientOptions"
            use-input
            input-debounce="0"
            @filter="onFilterClient"
            :loading="isLoadingClients"
            transition-show
            behavior="menu"
            class="q-mb-md filter-select"
            :placeholder="isLoadingClients ? 'Loading Clients...' : clientModel ? '' : 'Select Client'"
            clearable
            @update:model-value="onClientSelect"
            hide-selected
            fill-input
          >
            <template v-slot:prepend>
              <q-icon name="o_person" size="18px" />
            </template>
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> No Results </q-item-section>
              </q-item>
            </template>
          </q-select>
        </template>

        <!-- Business Context Search -->
        <div class="input-caption">
          Search Content by Booking Metadata
          <q-icon name="info" size="16px" class="info-icon">
            <q-tooltip max-width="150px"> Search by Shoot ID, Shoot Name, Notes, Outlet Details, or Filename </q-tooltip>
          </q-icon>
        </div>
        <q-input outlined dense v-model="metadataModel" placeholder="Enter Search Term..." class="q-mb-md filter-input" clearable @update:model-value="onMetadataSearch">
          <template v-slot:prepend>
            <q-icon name="search" size="18px" />
          </template>
        </q-input>

        <!-- Date Range Filter -->
        <div class="input-caption">Filter Content by Booking Date</div>
        <q-date v-model="dateModel" range today-btn minimal class="date-picker q-mb-md" flat bordered @update:model-value="onDateRangeChange" />

        <div class="input-caption">Additional Filters</div>
        <q-list bordered class="rounded-borders filter-list q-mb-md">
          <!-- Aspect Ratio Filter -->
          <q-expansion-item style="text-align: left" group="filters" class="filter-expansion-item">
            <template v-slot:header>
              <q-icon size="18px" name="o_aspect_ratio" color="grey-6" style="align-self: center; margin-right: 12px" />
              <q-item-section class="expansion-item-text"> Aspect Ratio </q-item-section>
            </template>

            <div class="checkbox">
              <q-option-group v-model="aspectRatioModel" :options="aspectRatioOptions" type="checkbox" @input="onAspectRatioChange" />
            </div>
          </q-expansion-item>

          <!-- Services Filter -->
          <q-expansion-item style="text-align: left" group="filters" class="filter-expansion-item">
            <template v-slot:header>
              <q-icon size="18px" name="o_category" color="grey-6" style="align-self: center; margin-right: 12px" />
              <q-item-section class="expansion-item-text">Category </q-item-section>
            </template>
            <div class="checkbox">
              <q-option-group v-model="servicesModel" :options="serviceOptions" type="checkbox" @input="onServicesChange" style="text-align: left" />
            </div>
          </q-expansion-item>
        </q-list>
      </div>

      <q-btn
        flat
        label="Clear Filters"
        no-caps
        :class="hasActiveFilters ? 'clear-filters-btn-active' : 'clear-filters-btn'"
        class="q-mt-md"
        @click="$emit('clear-filters')"
        :disable="!hasActiveFilters"
      />
    </div>
  </q-expansion-item>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'FiltersSection',

  props: {
    dateRange: {
      type: Object,
      default: () => ({ from: null, to: null })
    },
    selectedServices: {
      type: Array,
      default: () => []
    },
    selectedAspectRatios: {
      type: Array,
      default: () => []
    },
    metadataSearchQuery: {
      type: String,
      default: ''
    },
    selectedClient: {
      type: Object,
      default: null
    },
    clientOptions: {
      type: Array,
      default: () => []
    },
    serviceOptions: {
      type: Array,
      default: () => []
    },
    aspectRatioOptions: {
      type: Array,
      default: () => []
    },
    hasActiveFilters: {
      type: Boolean,
      default: false
    },
    isLoadingClients: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: ''
    }
  },

  emits: ['update:date-range', 'update:services', 'update:aspect-ratios', 'update:metadata-search', 'update:client', 'filter-clients', 'clear-filters'],

  setup(props, { emit }) {
    // For direct two-way binding
    const dateModel = ref({ ...props.dateRange });
    const servicesModel = ref([...props.selectedServices]);
    const aspectRatioModel = ref([...props.selectedAspectRatios]);
    const metadataModel = ref(props.metadataSearchQuery);
    const clientModel = ref(props.selectedClient);

    // Keep local state in sync with props
    watch(
      () => props.dateRange,
      val => {
        if (val && JSON.stringify(dateModel.value) !== JSON.stringify(val)) {
          dateModel.value = { ...val };
        }
      },
      { deep: true }
    );

    watch(
      () => props.selectedServices,
      val => {
        if (JSON.stringify(servicesModel.value) !== JSON.stringify(val)) {
          servicesModel.value = [...val];
        }
      },
      { deep: true }
    );

    watch(
      () => props.selectedAspectRatios,
      val => {
        if (JSON.stringify(aspectRatioModel.value) !== JSON.stringify(val)) {
          aspectRatioModel.value = [...val];
        }
      },
      { deep: true }
    );

    watch(
      () => props.metadataSearchQuery,
      val => {
        if (metadataModel.value !== val) {
          metadataModel.value = val;
        }
      }
    );

    watch(
      () => props.selectedClient,
      val => {
        clientModel.value = val;
      },
      { deep: true }
    );

    // Watch local models and emit changes
    watch(
      dateModel,
      val => {
        emit('update:date-range', val);
      },
      { deep: true }
    );

    watch(
      servicesModel,
      val => {
        emit('update:services', val);
      },
      { deep: true }
    );

    watch(
      aspectRatioModel,
      val => {
        emit('update:aspect-ratios', val);
      },
      { deep: true }
    );

    watch(metadataModel, val => {
      emit('update:metadata-search', val);
    });

    watch(
      clientModel,
      val => {
        emit('update:client', val);
      },
      { deep: true }
    );

    // Direct event handlers - simplified for clearer flow
    const onFilterClient = (val, update) => {
      emit('filter-clients', [val, update]);
    };

    const onDateRangeChange = value => {
      emit('update:date-range', value);
    };

    const onServicesChange = value => {
      emit('update:services', value);
    };

    const onAspectRatioChange = value => {
      emit('update:aspect-ratios', value);
    };

    const onMetadataSearch = value => {
      emit('update:metadata-search', value);
    };

    const onClientSelect = value => {
      emit('update:client', value);
    };

    return {
      dateModel,
      servicesModel,
      aspectRatioModel,
      metadataModel,
      clientModel,
      onFilterClient,
      onDateRangeChange,
      onServicesChange,
      onAspectRatioChange,
      onMetadataSearch,
      onClientSelect
    };
  }
});
</script>

<style lang="scss" scoped>
.sidebar-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 16px;
  background: white;
  overflow: hidden;
  border: 1px solid var(--terziary-btn-color);
}

.section-title {
  @include mona-sans-font($size: 16px, $bolded: true, $color: var(--main-text-color));
  letter-spacing: -0.02em;
}

.filters-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8px;
}

.filters-container {
  overflow-y: visible;
  overflow-x: hidden;
  padding-right: 2px;
  padding-left: 2px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.filter-list {
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #ececec;
  overflow: hidden;
  margin: 8px 0 16px 0;
}

.date-picker {
  min-width: unset !important;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  background-color: white;
  box-sizing: border-box;
  border: 1px solid #ececec;
  margin: 8px auto 16px;

  :deep(.q-date) {
    width: 100%;
    min-width: 290px;
  }

  :deep(.q-date__calendar-item) {
    height: 32px;
    width: 32px;
  }

  :deep(.q-date__calendar-days-container) {
    min-width: 100%;
    justify-content: center;
  }

  :deep(.q-date__calendar-days) {
    width: 100%;
  }
}

.checkbox {
  text-align: left;
  padding: 8px 16px;
  max-height: 200px;
  overflow-y: auto;
  overscroll-behavior: contain;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: scrollbar-color 0.3s ease;

  &:hover {
    scrollbar-color: rgba(136, 136, 136, 0.5) rgba(241, 241, 241, 0.3);
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
    transition: background 0.3s ease;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.5);
  }

  :deep(.q-checkbox) {
    margin-right: 8px;
    margin-bottom: 4px;
  }

  :deep(.q-option-group) {
    padding: 4px 0;
  }

  :deep(.q-option-group > div) {
    margin-bottom: 6px;
  }
}

.input-caption {
  text-align: left;
  @include inter-font($size: 12px, $color: var(--main-text-color));
  opacity: 0.75;
  margin-bottom: 10px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-left: 4px;
}

.filters-container > .input-caption:first-child {
  margin-top: 8px;
}

.info-icon {
  cursor: pointer;
  margin-left: auto;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  color: #666;

  &:hover {
    opacity: 1;
    color: #333;
  }
}

.expansion-item-text {
  text-align: left;
  @include inter-font($size: 13px, $color: var(--primary-text-color));
  opacity: 0.85;
  align-self: center;
  font-weight: 500;
}

.clear-filters-btn {
  height: 40px;
  width: 100%;
  @include inter-font($size: 14px, $bolded: true, $color: var(--secondary-text-color));
  opacity: 0.75;
  border-radius: 8px;
  transition: opacity 0.2s ease;
  background-color: var(--terziary-btn-color);

  &:hover {
    opacity: 1;
  }
}

.clear-filters-btn-active {
  height: 40px;
  width: 100%;
  @include inter-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
  opacity: 0.9;
  border-radius: 8px;
  transition: all 0.2s ease;
  background-color: var(--main-btn-color);

  &:hover {
    opacity: 1;
    transform: translateY(-1px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.filter-select {
  :deep(.q-field__native) {
    line-height: 20px;
    padding: 4px 0;
  }

  :deep(.q-field__control) {
    height: 40px;
  }

  :deep(.q-field__marginal) {
    height: 40px;
  }

  :deep(.q-field__input) {
    max-width: 100%;
    text-overflow: ellipsis;
  }
}

.filter-input {
  :deep(.q-field__control) {
    height: 40px;
  }

  :deep(.q-field__marginal) {
    height: 40px;
  }
}

.filter-expansion-item {
  :deep(.q-item__section--side) {
    padding-right: 0;
  }

  :deep(.q-expansion-item__content) {
    padding: 0;
  }
}
</style>
