<template>
  <div>
    <p class="section-label">Connected business assets</p>
    <q-select
      outlined
      v-model="localSelectedAccounts"
      :options="groupedAccounts"
      label="Select business assets"
      multiple
      use-chips
      stack-label
      option-value="id"
      option-label="name"
      class="business-assets-select"
    >
      <!-- Custom option rendering -->
      <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
        <!-- If this is a header item, render it as a header -->
        <template v-if="opt.header">
          <q-item-label header class="platform-header"> {{ opt.header }} ({{ opt.count }}) </q-item-label>
        </template>

        <!-- If this is a regular account item, render it normally -->
        <template v-else>
          <q-item v-bind="itemProps" :class="{ unpublished: !opt.isPublished }" clickable @click="toggleOption(opt)">
            <q-item-section avatar>
              <q-avatar size="32px">
                <q-icon :name="opt.platform === 'instagram' ? 'fab fa-instagram' : 'fab fa-facebook'" :class="opt.platform === 'instagram' ? 'instagram-icon' : 'facebook-icon'" size="24px" />
              </q-avatar>
            </q-item-section>

            <q-item-section>
              <q-item-label class="account-name">
                {{ opt.name || opt.username }}
              </q-item-label>
              <q-item-label caption class="account-category">
                {{ opt.category || opt.connectedPageName || 'Business' }}
              </q-item-label>
              <div class="account-stats">
                <q-chip v-if="opt.isPrivate" size="xs" color="warning">Private</q-chip>
                <q-chip v-if="!opt.isPublished" size="xs">Unpublished</q-chip>
              </div>
            </q-item-section>
            <q-item-section side>
              <q-checkbox :value="selected" color="primary" />
            </q-item-section>
          </q-item>
        </template>
      </template>

      <!-- No options available message -->
      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey"> No business assets available </q-item-section>
        </q-item>
      </template>

      <!-- Selected item display as chip -->
      <template v-slot:selected-item="{ opt, removeAtIndex }">
        <q-chip
          v-if="!opt.header"
          :icon="opt.platform === 'instagram' ? 'fab fa-instagram' : 'fab fa-facebook'"
          removable
          @remove="() => handleRemoveChip(opt, removeAtIndex)"
          color="primary"
          text-color="grey-7"
          class="selected-account-chip"
        >
          {{ opt.name || opt.username }}
        </q-chip>
      </template>
    </q-select>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  name: 'BusinessAssetsSelector',
  props: {
    metaIntegration: {
      type: Object,
      required: true
    },
    selectedAccounts: {
      type: Array,
      required: true
    }
  },
  emits: ['update:selected-accounts'],
  setup(props, { emit }) {
    // Computed properties for different account types
    const instagramAccounts = computed(() => {
      return (props.metaIntegration.instagramAccounts || []).map(account => ({
        ...account,
        platform: 'instagram',
        name: account.username
      }));
    });

    const facebookPages = computed(() => {
      return (props.metaIntegration.pages || []).map(page => ({
        ...page,
        platform: 'facebook'
      }));
    });

    // Group accounts with headers
    const groupedAccounts = computed(() => {
      const result = [];

      // Add Instagram header
      if (instagramAccounts.value.length > 0) {
        result.push({
          header: 'Instagram accounts',
          count: instagramAccounts.value.length,
          type: 'instagram',
          id: 'instagram-header',
          disabled: true
        });

        // Add Instagram accounts
        result.push(...instagramAccounts.value);
      }

      // Add Facebook header
      if (facebookPages.value.length > 0) {
        result.push({
          header: 'Facebook pages',
          count: facebookPages.value.length,
          type: 'facebook',
          id: 'facebook-header',
          disabled: true
        });

        // Add Facebook pages
        result.push(...facebookPages.value);
      }

      return result;
    });

    // Combine all accounts for filtering selected items
    const combinedAccounts = computed(() => {
      return [...instagramAccounts.value, ...facebookPages.value];
    });

    // Filter out header items from selections
    const localSelectedAccounts = computed({
      get: () => props.selectedAccounts,
      set: value => {
        // Filter out any header items that might have been selected
        const filteredValue = value.filter(item => !item.header);
        emit('update:selected-accounts', filteredValue);
      }
    });

    // Handle chip removal
    const handleRemoveChip = (opt, removeAtIndex) => {
      // First remove the item from the UI
      removeAtIndex();
      // Then update the selectedAccounts by filtering out the removed item
      const updatedSelection = props.selectedAccounts.filter(item => item.id !== opt.id);
      emit('update:selected-accounts', updatedSelection);
    };

    return {
      instagramAccounts,
      facebookPages,
      groupedAccounts,
      combinedAccounts,
      localSelectedAccounts,
      handleRemoveChip
    };
  }
};
</script>

<style lang="scss" scoped>
.section-label {
  font-size: 16px;
  color: var(--main-text-color);
  margin-bottom: 6px;
}

.business-assets-select {
  border-radius: 8px;

  :deep(.q-field__control) {
    border-radius: 8px;
  }

  :deep(.q-menu) {
    .q-virtual-scroll__content {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}

.platform-header {
  font-size: 12px;
  font-weight: 500;
  padding: 8px 12px;
  background-color: #f9f9f9;
}

.account-name {
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.account-category {
  font-size: 12px;
  color: #666;
}

.account-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
}

.unpublished {
  opacity: 0.7;
}

.instagram-icon {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.facebook-icon {
  background: linear-gradient(45deg, #0062e0 0%, #19afff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.selected-account-chip {
  margin: 2px;
}
</style>
