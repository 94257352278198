import { render, staticRenderFns } from "./BackgroundRemovalControls.vue?vue&type=template&id=4999cefa&scoped=true&"
import script from "./BackgroundRemovalControls.vue?vue&type=script&lang=js&"
export * from "./BackgroundRemovalControls.vue?vue&type=script&lang=js&"
import style0 from "./BackgroundRemovalControls.vue?vue&type=style&index=0&id=4999cefa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4999cefa",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QIcon,QSpinner});
