<template>
  <div class="top-bar">
    <div class="logo-container">
      <img src="https://flashy-assets20211018140904806600000006.s3.eu-central-1.amazonaws.com/email/Flashy_LogoExtended.png" alt="Flashy Logo" class="logo" />
      <span class="logo-text">PHOTO EDITOR</span>
    </div>
    <div class="actions">
      <template v-if="isGeneratedImage">
        <q-btn no-caps :loading="isDownloading" @click.native="handleDownload" flat class="action-btn" color="grey-6" padding="10px">
          <template v-slot:default>
            <q-icon name="o_download" class="q-mr-sm" color="grey-7" size="sm" />
            <q-tooltip>Download the image directly to your machine</q-tooltip>
            {{ isDownloading ? 'Downloading...' : 'Download' }}
          </template>
        </q-btn>
        <q-btn no-caps :loading="isSaving" @click="handleSave" flat class="action-btn" color="grey-6" padding="10px">
          <template v-slot:default>
            <q-icon name="o_save" class="q-mr-sm" color="grey-7" size="sm" />
            {{ isSaving ? 'Saving...' : 'Save As Copy' }}
            <q-tooltip>Save a copy without discarding the original</q-tooltip>
          </template>
        </q-btn>
        <q-btn no-caps @click="handleDiscard" flat class="action-btn" color="negative" padding="10px">
          <template v-slot:default>
            <q-icon name="o_delete" class="q-mr-sm" color="surface" size="sm" />
            Discard
            <q-tooltip>Discard the current result</q-tooltip>
          </template>
        </q-btn>
      </template>
      <q-btn no-caps @click="handleToggleHistory" flat class="action-btn" color="primary" padding="10px">
        <template v-slot:default>
          <q-icon name="o_history" class="q-mr-sm" color="primary" size="sm" />
          History
          <q-tooltip>Toggle generation history visibility</q-tooltip>
        </template>
      </q-btn>
      <q-btn flat round dense icon="close" @click="handleClose" class="close-btn" />
    </div>
  </div>
</template>

<script>
import { ContentApi } from '@api/index';

export default {
  name: 'ImageEditorTopBar',
  props: {
    isGeneratedImage: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDownloading: false,
      isSaving: false
    };
  },
  methods: {
    handleClose() {
      this.$emit('update:isOpen', false);
      this.$emit('close');
    },
    async handleDownload() {
      if (this.isDownloading) return;
      this.isDownloading = true;
      try {
        const response = await fetch(this.image.optimized_url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-').slice(0, 19);
        link.download = `edited-image-${timestamp}.jpeg`;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading image:', error);
      } finally {
        this.isDownloading = false;
      }
    },
    async handleSave() {
      if (this.isSaving) return;
      this.isSaving = true;
      try {
        const response = await fetch(this.image.optimized_url);
        const blob = await response.blob();
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-').slice(0, 19);
        const file = new File([blob], `edited-image-${timestamp}.jpeg`, { type: 'image/jpeg' });

        // Get image dimensions
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = URL.createObjectURL(file);
        });

        console.log('image file', file);

        const fileData = {
          name: file.name,
          relativePath: file.name,
          contentType: file.type,
          aspectRatio: parseFloat((img.naturalWidth / img.naturalHeight).toFixed(3))
        };

        // Get presigned URL
        const urlResponse = await ContentApi.getPresignedUrls({
          id: this.image.shoot_id,
          files: [fileData]
        });

        const { presignedUrls, imagesMetadata } = urlResponse.data;

        // Upload file
        const xhr = new XMLHttpRequest();
        await new Promise((resolve, reject) => {
          xhr.open('PUT', presignedUrls[0].presignedUrl, true);
          xhr.setRequestHeader('Content-Type', file.type);

          xhr.onload = () => (xhr.status === 200 ? resolve() : reject(new Error(`Upload failed: ${xhr.status}`)));
          xhr.onerror = () => reject(new Error('Network error during upload'));
          xhr.send(file);
        });

        // Create image batch
        await ContentApi.createImagesBatch({
          id: this.image.shoot_id,
          payload: {
            images: imagesMetadata
          }
        });

        this.$store.dispatch('notification/addSuccessNotification', 'Image saved successfully');
      } catch (error) {
        console.error('Error saving image:', error);
        this.$store.dispatch('notification/addFailureNotification', `Failed to save image: ${error.message}`);
      } finally {
        this.isSaving = false;
      }
    },
    handleDiscard() {
      this.$emit('discard');
    },
    handleToggleHistory() {
      this.$emit('toggle-history-panel');
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px;
  height: fit-content;
  min-height: 76px;
  max-height: 76px;
  background-color: white;
  overflow: visible;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex: 0 0 auto;
  }

  .logo-text {
    @include mona-sans-font($size: 20px, $bolded: true, $color: var(--main-text-color));
    text-align: center;
  }

  .logo {
    height: 30px;
    width: auto;
    object-fit: contain;
  }

  .actions {
    display: flex;
    gap: 12px;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex: 1 1 auto;
  }

  .action-btn {
    @include inter-font($size: 15px, $bolded: true, $color: var(--main-text-color));
    border-radius: 8px;
    min-height: 44px;
    height: 44px;
    padding: 0 18px;
    white-space: nowrap;
    transition: all 0.2s ease;
  }

  .close-btn {
    width: 44px;
    height: 44px;
    transition: all 0.2s ease;
    margin-left: 4px;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }
}
</style>
