<template>
  <CollectionOption
    :label="label"
    :value="value"
    :modelValue="modelValue"
    :expanded="expanded"
    @update:modelValue="$emit('update:modelValue', $event)"
    @update:expanded="$emit('update:expanded', $event)"
  >
    <div class="q-pa-md">
      <div class="input-label q-mb-sm">Select a collection</div>
      <q-select
        v-model="selectedCollectionModel"
        :options="collectionsOptions"
        outlined
        dense
        :loading="isLoadingCollections"
        :disable="collections.length === 0"
        :label="isLoadingCollections ? 'Loading collections...' : 'Select a collection'"
        class="q-mb-sm collection-select"
        bg-color="white"
        use-input
        input-debounce="300"
        options-dense
        behavior="menu"
        dropdown-icon="arrow_drop_down"
        popup-content-class="collection-dropdown-menu"
        menu-anchor="bottom left"
        menu-self="top left"
      >
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey"> No collections found. Create one below. </q-item-section>
          </q-item>
        </template>
        <template v-slot:prepend>
          <q-icon name="folder" color="primary" size="sm" />
        </template>
      </q-select>

      <div class="text-caption text-grey q-mt-sm" v-if="collections.length === 0 && !isLoadingCollections">
        <q-icon name="info" size="12px" class="q-mr-xs" />
        You don't have any collections yet. Create one below.
      </div>
    </div>
  </CollectionOption>
</template>

<script>
import { computed } from '@vue/composition-api';
import CollectionOption from './CollectionOption.vue';

export default {
  name: 'ExistingCollectionOption',
  components: {
    CollectionOption
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      required: true
    },
    collections: {
      type: Array,
      required: true
    },
    collectionsOptions: {
      type: Array,
      required: true
    },
    isLoadingCollections: {
      type: Boolean,
      required: true
    },
    selectedCollection: {
      type: Object,
      default: null
    }
  },
  emits: ['update:modelValue', 'update:expanded', 'update:selected-collection'],
  setup(props, { emit }) {
    const selectedCollectionModel = computed({
      get: () => props.selectedCollection,
      set: value => emit('update:selected-collection', value)
    });

    return {
      selectedCollectionModel
    };
  }
};
</script>

<style lang="scss" scoped>
.input-label {
  @include mona-sans-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
  margin-bottom: 10px;
}

.collection-select {
  :deep(.q-field__control) {
    height: 44px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }

  :deep(.q-field--focused .q-field__control) {
    box-shadow: 0 0 0 2px rgba(2, 123, 227, 0.2);
    border-color: var(--main-btn-color);
  }
}
</style>

<style>
.collection-dropdown-menu {
  max-height: 150px !important;
  overflow-y: auto !important;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.collection-dropdown-menu::-webkit-scrollbar {
  width: 6px;
}

.collection-dropdown-menu::-webkit-scrollbar-track {
  background: transparent;
}

.collection-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
</style>
