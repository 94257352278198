import instance from '../index';
const RESOURCE_COLLECTIONS = 'collections';

export const CollectionsApi = {
  /**
   * Create a new collection
   * @param {string} name - Name of the collection
   * @returns {Promise<Object>} Created collection details
   * @property {boolean} success - Operation success status
   * @property {Object} collection - The created collection
   * @property {number} collection.id - Collection ID
   * @property {string} collection.name - Collection name
   * @property {number} collection.user_id - User ID
   * @property {string} collection.created_at - Creation timestamp
   * @property {string} collection.updated_at - Update timestamp
   */
  createCollection(name) {
    return instance.post(`/${RESOURCE_COLLECTIONS}`, {
      name
    });
  },

  /**
   * Get all collections for the current user with item counts
   * @returns {Promise<Object>} List of collections with item counts
   * @property {boolean} success - Operation success status
   * @property {Array} collections - List of collections
   * @property {number} collections[].id - Collection ID
   * @property {string} collections[].name - Collection name
   * @property {number} collections[].user_id - User ID
   * @property {string} collections[].created_at - Creation timestamp
   * @property {string} collections[].updated_at - Update timestamp
   * @property {number} collections[].item_count - Number of items in collection
   */
  getAllCollections() {
    return instance.get(`/${RESOURCE_COLLECTIONS}`);
  },

  /**
   * Update a collection (rename)
   * @param {number} id - Collection ID
   * @param {string} name - New name for the collection
   * @returns {Promise<Object>} Updated collection details
   * @property {boolean} success - Operation success status
   * @property {Object} collection - The updated collection
   * @property {number} collection.id - Collection ID
   * @property {string} collection.name - Collection name
   * @property {number} collection.user_id - User ID
   * @property {string} collection.created_at - Creation timestamp
   * @property {string} collection.updated_at - Update timestamp
   */
  updateCollection(id, name) {
    return instance.put(`/${RESOURCE_COLLECTIONS}/${id}`, {
      name
    });
  },

  /**
   * Delete a collection and all its associations
   * @param {number} id - Collection ID
   * @returns {Promise<Object>} Deletion result
   * @property {boolean} success - Operation success status
   */
  deleteCollection(id) {
    return instance.delete(`/${RESOURCE_COLLECTIONS}/${id}`);
  },

  /**
   * Add multiple images to a collection
   * @param {number} collectionId - Collection ID
   * @param {Array<number>} imageIds - Array of image IDs to add to the collection
   * @returns {Promise<Object>} Updated collection with item count
   * @property {boolean} success - Operation success status
   * @property {Object} collection - The updated collection
   * @property {number} collection.id - Collection ID
   * @property {string} collection.name - Collection name
   * @property {number} collection.user_id - User ID
   * @property {string} collection.created_at - Creation timestamp
   * @property {string} collection.updated_at - Update timestamp
   * @property {number} collection.item_count - Number of items in collection
   */
  addItemsToCollection(collectionId, imageIds) {
    return instance.post(`/${RESOURCE_COLLECTIONS}/${collectionId}/items`, {
      image_ids: imageIds
    });
  },

  /**
   * Remove multiple images from a collection
   * @param {number} collectionId - Collection ID
   * @param {Array<number>} imageIds - Array of image IDs to remove from the collection
   * @returns {Promise<Object>} Removal result
   * @property {boolean} success - Operation success status
   * @property {number} removed_count - Number of items removed
   */
  removeItemsFromCollection(collectionId, imageIds) {
    return instance.delete(`/${RESOURCE_COLLECTIONS}/${collectionId}/items`, {
      data: {
        image_ids: imageIds
      }
    });
  }
};

export default CollectionsApi;
