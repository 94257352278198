<template>
  <div class="tool-button-container">
    <q-btn
      @click="tool.disabled ? null : $emit('toggle', tool.id)"
      :class="['tool-icon', { active: isActive, disabled: tool.disabled }]"
      flat
      no-caps
      :disable="false"
      @mouseenter="$emit('hover', tool.id, $event)"
      @mouseleave="$emit('hover-exit')"
    >
      <q-icon :name="`o_${tool.icon}`" size="22px" :color="tool.disabled ? 'grey-5' : isActive ? 'primary' : 'grey-7'" />
    </q-btn>
  </div>
</template>

<script>
export default {
  name: 'ToolIcon',
  props: {
    tool: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.tool-button-container {
  position: relative;
}

.tool-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background: rgba(243, 244, 246, 0.8);
  }

  &.active {
    background: var(--secondary-bg-color);
    box-shadow: 0 2px 4px rgba(230, 213, 255, 0.3);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: var(--main-btn-color);
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
