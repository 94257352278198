import { render, staticRenderFns } from "./NewCollectionOption.vue?vue&type=template&id=45c06dcc&scoped=true&"
import script from "./NewCollectionOption.vue?vue&type=script&lang=js&"
export * from "./NewCollectionOption.vue?vue&type=script&lang=js&"
import style0 from "./NewCollectionOption.vue?vue&type=style&index=0&id=45c06dcc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c06dcc",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QField});
