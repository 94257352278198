<template>
  <q-dialog v-model="dialogModel" transition-show="scale" transition-hide="scale" seamless no-backdrop-dismiss class="no-shadow-dialog">
    <q-card class="upload-dialog-card">
      <q-card-section class="upload-dialog-header">
        <div class="header-content">
          <div class="upload-dialog-title">Upload Content</div>
          <div class="upload-dialog-subtitle">Select content to upload to your gallery</div>
        </div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup class="close-btn" />
      </q-card-section>

      <q-separator />

      <!-- Progress overlay when uploading -->
      <UploadProgressOverlay :isVisible="isUploading" :statusText="uploadStatusText" :progress="uploadProgress" />

      <q-card-section class="upload-dialog-body scroll-container" :class="{ 'blur-content': isUploading }">
        <!-- Upload area -->
        <UploadArea :files="selectedFiles" @add-files="handleAddFiles" @remove-file="removeFile" />

        <!-- Collection selection -->
        <CollectionSelector
          :destination="uploadDestination"
          :selected-collection="selectedCollection"
          :new-collection-name="newCollectionName"
          :collections="collections"
          :collectionsOptions="collectionsOptions"
          :isLoadingCollections="isLoadingCollections"
          @update:destination="handleDestinationUpdate"
          @update:selectedCollection="selectedCollection = $event"
          @update:newCollectionName="newCollectionName = $event"
        />
      </q-card-section>

      <q-card-actions align="right" class="upload-dialog-actions">
        <q-btn flat no-caps label="Cancel" color="grey-7" v-close-popup class="cancel-btn" :disable="isUploading" />
        <q-btn flat no-caps :loading="isUploading" :disabled="!canUpload" label="Upload" @click="handleUploadClick" class="upload-btn">
          <template v-slot:loading>
            <q-spinner-dots color="white" size="24px" />
          </template>
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { ContentApi } from '@api/index';
import { CollectionsApi } from '@api/modules/collections';
import UploadArea from './upload/UploadArea.vue';
import CollectionSelector from './upload/CollectionSelector.vue';
import UploadProgressOverlay from './upload/UploadProgressOverlay.vue';

export default {
  name: 'UploadImagesDialog',
  components: {
    UploadArea,
    CollectionSelector,
    UploadProgressOverlay
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:show', 'upload-success', 'upload-error'],
  setup(props, { emit }) {
    // Dialog state
    const dialogModel = computed({
      get: () => props.show,
      set: value => emit('update:show', value)
    });

    // File state
    const selectedFiles = ref([]);

    // Upload state
    const isUploading = ref(false);
    const uploadStatusText = ref('');
    const uploadProgress = ref(0);

    // Collection state
    const uploadDestination = ref('all');
    const selectedCollection = ref(null);
    const newCollectionName = ref('');

    // Get query client for cache invalidation
    const queryClient = useQueryClient();

    // Collections data
    const { data: collectionsData, isLoading: isLoadingCollections } = useQuery({
      queryKey: ['collections'],
      queryFn: fetchCollections,
      staleTime: 1000 * 60 * 5 // 5 minutes
    });

    async function fetchCollections() {
      const response = await CollectionsApi.getAllCollections();
      return response.data.collections || [];
    }

    const collections = computed(() => collectionsData.value || []);
    const collectionsOptions = computed(() =>
      collections.value.map(collection => ({
        label: collection.name,
        value: collection.id,
        description: `${collection.item_count} items`
      }))
    );

    // File handling
    const handleAddFiles = newFiles => {
      selectedFiles.value = [...selectedFiles.value, ...newFiles];
    };

    const removeFile = index => {
      URL.revokeObjectURL(selectedFiles.value[index].preview);
      selectedFiles.value.splice(index, 1);
    };

    // Check for duplicate collection name
    const isDuplicateCollectionName = name => {
      if (!name || !collections.value || collections.value.length === 0) {
        return false;
      }

      const trimmedName = name.trim().toLowerCase();
      return collections.value.some(collection => collection.name.trim().toLowerCase() === trimmedName);
    };

    // Validation
    const isNewCollectionNameValid = computed(() => {
      if (uploadDestination.value !== 'new') return true;
      if (!newCollectionName.value.trim()) return false;

      return !isDuplicateCollectionName(newCollectionName.value);
    });

    const canUpload = computed(() => {
      if (selectedFiles.value.length === 0) return false;

      if (uploadDestination.value === 'existing') {
        return selectedCollection.value !== null;
      } else if (uploadDestination.value === 'new') {
        return newCollectionName.value.trim() !== '' && isNewCollectionNameValid.value;
      }

      return true; // For 'all' destination
    });

    // Upload process
    const handleDestinationUpdate = newDestination => {
      uploadDestination.value = newDestination;

      // Reset related values when changing destination
      if (newDestination === 'all') {
        selectedCollection.value = null;
        newCollectionName.value = '';
      } else if (newDestination === 'existing') {
        newCollectionName.value = '';
      } else if (newDestination === 'new') {
        selectedCollection.value = null;
      }
    };

    const handleUploadClick = () => {
      if (uploadDestination.value === 'new' && !isNewCollectionNameValid.value) {
        // Show error notification
        emit('upload-error', 'A collection with this name already exists. Please choose a different name.');
        return;
      }

      uploadContent();
    };

    const uploadContent = async () => {
      if (!canUpload.value) return;

      try {
        startUpload();

        // Store upload parameters
        const uploadParams = getUploadParameters();

        try {
          // Prepare file data with dimensions
          const fileDataArray = await prepareFileData();
          console.log('File Data Array:', fileDataArray);

          // Get presigned URLs for upload
          const uploadData = await getPresignedUrls(fileDataArray);

          // Upload files using presigned URLs
          await uploadFilesToStorage(fileDataArray, uploadData.presignedUrls);

          // Create content batch
          const contentIds = await createContent(uploadData.contentMetadata);

          // Handle collection operations
          const collectionResult = await handleCollectionOperations(uploadParams, contentIds);

          // Update caches and finalize upload
          await finalizeUpload(uploadParams.uploadedCount, collectionResult.collectionName);
        } catch (uploadError) {
          handleUploadError(uploadError);
          throw uploadError;
        }
      } catch (error) {
        failUpload(error);
      } finally {
        // Reset state after a short delay (to show final status)
        setTimeout(() => {
          isUploading.value = false;
          uploadStatusText.value = '';
          uploadProgress.value = 0;
        }, 500);
      }
    };

    // Upload initialization
    function startUpload() {
      isUploading.value = true;
      uploadStatusText.value = 'Preparing your files...';
      uploadProgress.value = 0.1;
    }

    // Store the upload parameters
    function getUploadParameters() {
      return {
        uploadedCount: selectedFiles.value.length,
        uploadToNewCollection: uploadDestination.value === 'new',
        newCollectionNameToCreate: uploadDestination.value === 'new' ? newCollectionName.value : null,
        existingCollectionId: uploadDestination.value === 'existing' ? selectedCollection.value.value : null,
        existingCollectionName: uploadDestination.value === 'existing' ? selectedCollection.value.label : null
      };
    }

    // Prepare file data with media metadata
    async function prepareFileData() {
      uploadStatusText.value = 'Getting ready...';
      uploadProgress.value = 0.2;

      return Promise.all(
        selectedFiles.value.map(async ({ file }) => {
          // Get media metadata
          const metadata = await getMediaMetadata(file);
          const isVideo = file.type.startsWith('video/');

          return {
            name: file.name,
            relativePath: file.name,
            contentType: file.type,
            aspectRatio: parseFloat((metadata.width / metadata.height).toFixed(3)),
            duration: isVideo ? metadata.duration : 0,
            file: file
          };
        })
      );
    }

    // Get dimensions and metadata from media file
    async function getMediaMetadata(file) {
      return new Promise((resolve, reject) => {
        const objectUrl = URL.createObjectURL(file);

        if (file.type.startsWith('video/')) {
          const video = document.createElement('video');
          video.preload = 'metadata';

          video.onloadedmetadata = () => {
            URL.revokeObjectURL(objectUrl);
            resolve({
              width: video.videoWidth,
              height: video.videoHeight,
              duration: Math.round(video.duration)
            });
          };

          video.onerror = () => {
            URL.revokeObjectURL(objectUrl);
            reject(new Error(`Failed to load video metadata for ${file.name}`));
          };

          video.src = objectUrl;
        } else if (file.type.startsWith('image/')) {
          const img = new Image();

          img.onload = () => {
            URL.revokeObjectURL(objectUrl);
            resolve({
              width: img.naturalWidth,
              height: img.naturalHeight,
              duration: 0
            });
          };

          img.onerror = () => {
            URL.revokeObjectURL(objectUrl);
            reject(new Error(`Failed to load image ${file.name}`));
          };

          img.src = objectUrl;
        } else {
          URL.revokeObjectURL(objectUrl);
          reject(new Error(`Unsupported file type: ${file.type}`));
        }
      });
    }

    // Get presigned URLs for upload
    async function getPresignedUrls(fileDataArray) {
      try {
        uploadStatusText.value = 'Getting ready...';
        uploadProgress.value = 0.3;

        const shootId = 0; // Non-shoot-related content as specified
        const response = await ContentApi.getPresignedUrls({
          id: shootId,
          files: fileDataArray.map(fileData => ({
            name: fileData.name,
            relativePath: fileData.relativePath,
            contentType: fileData.contentType,
            aspectRatio: fileData.aspectRatio,
            duration: fileData.duration
          }))
        });

        return response.data;
      } catch (error) {
        if (error.message.includes('network') || !navigator.onLine) {
          throw new Error('Unable to prepare for upload. Please check your internet connection and try again.');
        }
        throw error;
      }
    }

    // Upload files using presigned URLs
    async function uploadFilesToStorage(fileDataArray, presignedUrls) {
      try {
        uploadStatusText.value = 'Uploading your photos...';
        uploadProgress.value = 0.4;

        return Promise.all(
          presignedUrls.map((urlData, index) => {
            const fileData = fileDataArray[index];
            return uploadSingleFile(urlData.presignedUrl, fileData, index, presignedUrls.length);
          })
        );
      } catch (error) {
        throw new Error('An error occurred while uploading your photos. Please try again later.');
      }
    }

    // Upload a single file
    function uploadSingleFile(presignedUrl, fileData, index, total) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', presignedUrl, true);
        xhr.setRequestHeader('Content-Type', fileData.contentType);

        xhr.onload = () => {
          if (xhr.status === 200) {
            // Update progress based on completed uploads
            uploadProgress.value = 0.4 + 0.2 * ((index + 1) / total);
            resolve();
          } else {
            reject(new Error(`Upload failed: ${xhr.status}`));
          }
        };

        xhr.onerror = () => {
          reject(new Error('Network error during upload. Please check your connection and try again.'));
        };

        xhr.send(fileData.file);
      });
    }

    // Create content
    async function createContent(contentMetadata) {
      try {
        uploadStatusText.value = 'Processing...';
        uploadProgress.value = 0.6;

        const shootId = 0;
        const batchResponse = await ContentApi.addContent({
          id: shootId,
          payload: {
            content: contentMetadata
          }
        });

        // Get the image IDs from the batch response
        const contentIds = batchResponse.data.content.map(content => content.id);

        if (!contentIds || contentIds.length === 0) {
          throw new Error('No content were created. The server may be experiencing issues.');
        }

        return contentIds;
      } catch (error) {
        if (error.message.includes('No content were created')) {
          throw new Error('The server was unable to process your content. Please try again later or contact support if the issue persists.');
        }
        throw error;
      }
    }

    // Handle collection operations
    async function handleCollectionOperations(uploadParams, contentIds) {
      let targetCollectionId = null;
      let collectionName = 'All Assets';

      if (uploadParams.uploadToNewCollection) {
        const result = await createNewCollection(uploadParams.newCollectionNameToCreate);
        targetCollectionId = result.collectionId;
        collectionName = result.collectionName;
      } else if (uploadParams.existingCollectionId) {
        // Use existing collection
        targetCollectionId = uploadParams.existingCollectionId;
        collectionName = uploadParams.existingCollectionName;
      }

      // Add to collection if needed
      if (targetCollectionId) {
        await addContentToCollection(targetCollectionId, contentIds, collectionName);
      }

      return { collectionName };
    }

    // Create a new collection
    async function createNewCollection(collectionName) {
      uploadStatusText.value = `Creating "${collectionName}"...`;
      uploadProgress.value = 0.8;

      try {
        // Double-check for duplicate collection name before creating
        if (isDuplicateCollectionName(collectionName)) {
          throw new Error('A collection with this name already exists. Please choose a different name.');
        }

        const createResponse = await CollectionsApi.createCollection(collectionName);
        return {
          collectionId: createResponse.data.collection.id,
          collectionName
        };
      } catch (collectionError) {
        if (collectionError.message.includes('already exists')) {
          emit('upload-success', {
            count: selectedFiles.value.length,
            collectionName: 'All Assets',
            partialSuccess: true,
            errorMessage: 'Your content was uploaded but a collection with this name already exists.'
          });
        } else {
          emit('upload-success', {
            count: selectedFiles.value.length,
            collectionName: 'All Assets',
            partialSuccess: true,
            errorMessage: 'Your content was uploaded but we could not create the collection.'
          });
        }
        // content was uploaded, but collection creation failed
        resetForm();
        dialogModel.value = false;
        isUploading.value = false;
        throw collectionError;
      }
    }

    // Add content to a collection
    async function addContentToCollection(collectionId, contentIds, collectionName) {
      uploadStatusText.value = `Adding to "${collectionName}"...`;
      uploadProgress.value = 0.9;

      try {
        await CollectionsApi.addItemsToCollection(collectionId, contentIds);
      } catch (addError) {
        emit('upload-success', {
          count: selectedFiles.value.length,
          collectionName: 'All Assets',
          partialSuccess: true,
          errorMessage: 'Your content was uploaded but could not be added to the collection.'
        });
        // Images were uploaded, but adding to collection failed
        resetForm();
        dialogModel.value = false;
        isUploading.value = false;
        throw addError;
      }
    }

    // Finalize upload
    async function finalizeUpload(count, collectionName) {
      // Invalidate caches to refresh data
      uploadStatusText.value = 'Finishing up...';
      uploadProgress.value = 0.95;

      await queryClient.invalidateQueries({ queryKey: ['content'] });
      await queryClient.invalidateQueries({ queryKey: ['collections'] });

      // Clear form and close dialog
      uploadProgress.value = 1;
      uploadStatusText.value = 'Upload complete!';

      setTimeout(() => {
        resetForm();
        dialogModel.value = false;

        // Notify success
        emit('upload-success', {
          count,
          collectionName
        });
      }, 500); // Short delay to show completion
    }

    // Handle upload errors with user-friendly messages
    function handleUploadError(uploadError) {
      let errorMessage = 'We could not complete your upload. Please try again.';
      const errorMsg = uploadError?.message || '';

      // Handle specific error types with user-friendly messages
      if (errorMsg.includes('Network') || errorMsg.includes('internet') || errorMsg.includes('connection')) {
        errorMessage = 'Please check your internet connection and try again.';
      } else if (errorMsg.includes('large') || errorMsg.includes('413')) {
        errorMessage = 'The files you are trying to upload may be too large. Try uploading fewer or smaller files.';
      } else if (errorMsg.includes('500') || errorMsg.includes('503') || errorMsg.includes('server')) {
        errorMessage = 'Our system is currently busy. Please try again in a few minutes.';
      } else if (errorMsg.includes('process your images')) {
        errorMessage = 'The server was unable to process your files. Please ensure they are valid JPEG or MP4 files.';
      } else if (errorMsg.includes('collection with this name already exists')) {
        errorMessage = 'A collection with this name already exists. Please choose a different name.';
      }

      // Re-throw with simplified message
      throw new Error(errorMessage);
    }

    // Handle final upload failure
    function failUpload(error) {
      uploadProgress.value = 0;
      uploadStatusText.value = 'Upload failed';

      // Use emit for notification
      console.log(error, 'upload failed');
      emit('upload-error', error.message);
    }

    // Form state management
    // Reset form state
    const resetForm = () => {
      // Release object URLs to prevent memory leaks
      selectedFiles.value.forEach(file => URL.revokeObjectURL(file.preview));

      // Reset all form state
      selectedFiles.value = [];
      uploadDestination.value = 'all';
      selectedCollection.value = null;
      newCollectionName.value = '';
    };

    // Clean up on dialog close
    watch(
      () => props.show,
      isVisible => {
        if (!isVisible) {
          resetForm();
        }
      }
    );

    return {
      dialogModel,
      selectedFiles,
      isUploading,
      uploadDestination,
      selectedCollection,
      newCollectionName,
      isLoadingCollections,
      collections,
      collectionsOptions,
      canUpload,
      handleAddFiles,
      removeFile,
      uploadContent,
      uploadStatusText,
      uploadProgress,
      handleDestinationUpdate,
      handleUploadClick,
      isDuplicateCollectionName
    };
  }
};
</script>

<style lang="scss" scoped>
.no-shadow-dialog {
  :deep(.q-dialog__backdrop) {
    background: rgba(0, 0, 0, 0.4) !important;
  }

  :deep(.q-card) {
    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15) !important;
  }
}

.upload-dialog-card {
  min-width: 600px;
  max-width: 90vw;
  max-height: 90vh; /* Limit height to 90% of viewport */
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
}

.upload-dialog-header {
  padding: 24px 24px;
  background: linear-gradient(to right, #f7f7f7, #ffffff);
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0; /* Prevent header from shrinking */
}

.header-content {
  display: flex;
  flex-direction: column;
}

.upload-dialog-title {
  @include mona-sans-font($size: 20px, $bolded: true, $color: var(--primary-text-color));
  letter-spacing: 0.3px;
  margin: 0;
  line-height: 1.2;
}

.upload-dialog-subtitle {
  @include inter-font($size: 14px, $color: var(--secondary-text-color));
  margin-top: 4px;
  margin-bottom: 0;
}

.upload-dialog-body {
  padding: 24px;
  position: relative;
  transition: filter 0.3s ease;
  overflow-y: auto; /* Enable vertical scrolling */
  flex: 1 1 auto; /* Allow body to grow and shrink */
  max-height: calc(90vh - 140px); /* Subtract header and footer heights */
}

.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.scroll-container::-webkit-scrollbar {
  width: 6px;
}

.scroll-container::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.blur-content {
  filter: blur(2px);
  pointer-events: none;
}

.upload-dialog-actions {
  padding: 16px 24px 24px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
  flex-shrink: 0; /* Prevent footer from shrinking */
}

.close-btn {
  opacity: 0.7;
  transition: opacity 0.2s ease, background-color 0.2s ease;
}

.close-btn:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.05);
}

.cancel-btn,
.upload-btn {
  @include inter-font($size: 14px, $bolded: true, $color: #333);
  height: 44px;
  border-radius: 8px;
  letter-spacing: 0.3px;
  padding: 0 20px;
  transition: all 0.2s ease;
}

.cancel-btn {
  margin-right: 12px;
}

.cancel-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.upload-btn {
  background-color: var(--main-btn-color);
  color: var(--primary-text-color);
}

.upload-btn:hover {
  opacity: 0.9;
}
</style>
