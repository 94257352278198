<template>
  <div>
    <!-- Create Collection Dialog -->
    <q-dialog v-model="createDialogModel" transition-show="scale" transition-hide="scale" seamless @hide="$emit('update:show-create', false)">
      <q-card class="modal-card">
        <div class="modal-pattern-bg"></div>
        <q-card-section class="modal-header create-header">
          <div class="modal-title">Create New Collection</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup class="close-btn" />
        </q-card-section>

        <q-separator />

        <q-card-section class="modal-body">
          <div class="input-label">Collection Name</div>
          <q-input
            v-model="newNameModel"
            outlined
            :rules="[val => !!val || 'Name is required']"
            ref="createInput"
            class="modal-input focus-glow"
            maxlength="50"
            counter
            dense
            bg-color="white"
            autofocus
            @update:model-value="$emit('update:new-name', $event)"
          >
            <template v-slot:prepend>
              <q-icon name="folder" color="primary" size="sm" />
            </template>
            <template v-slot:append v-if="newNameModel">
              <q-icon name="close" class="cursor-pointer clear-icon hover-scale" @click="$emit('update:new-name', '')" />
            </template>
          </q-input>
          <div class="info-message">
            <q-icon name="info" class="info-icon-small" />
            <div>Name your collection to start organizing your content.</div>
          </div>
        </q-card-section>

        <q-card-actions class="modal-actions">
          <q-btn flat no-caps label="Cancel" color="grey-7" v-close-popup class="modal-btn cancel-btn btn-with-transition" />
          <q-btn flat no-caps label="Create Collection" :loading="isCreating" @click="$emit('create')" :disable="!newNameModel" class="modal-btn confirm-btn btn-with-transition" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Delete Collection Confirmation Dialog -->
    <q-dialog v-model="deleteDialogModel" v-if="collectionToDelete" transition-show="scale" transition-hide="scale" seamless @hide="$emit('update:show-delete', false)">
      <q-card class="modal-card">
        <div class="modal-pattern-bg delete-pattern"></div>
        <q-card-section class="modal-header delete-header">
          <q-icon name="delete" color="negative" class="modal-header-icon" />
          <div class="modal-title">Delete Collection</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup class="close-btn" />
        </q-card-section>

        <q-separator class="delete-separator" />

        <q-card-section class="modal-body">
          <div class="warning-box">
            <q-icon name="warning" color="negative" class="warning-icon" />
            <div class="warning-text">This action cannot be undone.</div>
          </div>

          <p class="confirmation-text">
            Do you want to delete "<span class="collection-name-highlight">{{ collectionToDelete.name }}</span
            >" collection?
          </p>

          <div class="note-text">
            <q-icon name="info" class="note-icon" />
            <div>This will only delete the collection, not the images inside it.</div>
          </div>
        </q-card-section>

        <q-card-actions class="modal-actions">
          <q-btn flat no-caps label="Cancel" color="grey-7" v-close-popup class="modal-btn cancel-btn btn-with-transition" />
          <q-btn flat no-caps label="Delete" :loading="isDeleting" @click="$emit('delete')" class="modal-btn delete-btn btn-with-transition" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Edit Collection Dialog -->
    <q-dialog v-model="editDialogModel" v-if="collectionToEdit" transition-show="scale" transition-hide="scale" seamless @hide="$emit('update:show-edit', false)">
      <q-card class="modal-card">
        <div class="modal-pattern-bg edit-pattern"></div>
        <q-card-section class="modal-header edit-header">
          <q-icon name="edit" color="primary" class="modal-header-icon" />
          <div class="modal-title">Rename Collection</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup class="close-btn" />
        </q-card-section>

        <q-separator />

        <q-card-section class="modal-body">
          <div class="input-label">Collection Name</div>
          <q-input
            v-model="editNameModel"
            outlined
            :rules="[val => !!val || 'Name is required']"
            ref="editInput"
            class="modal-input focus-glow"
            maxlength="50"
            counter
            dense
            bg-color="white"
            autofocus
            @update:model-value="$emit('update:edit-name', $event)"
          >
            <template v-slot:prepend>
              <q-icon name="folder" color="primary" size="sm" />
            </template>
            <template v-slot:append v-if="editNameModel">
              <q-icon name="close" class="cursor-pointer clear-icon hover-scale" @click="$emit('update:edit-name', collectionToEdit.name)" />
            </template>
          </q-input>

          <div class="current-name-box">
            <div class="current-name-content">
              <q-icon name="info" color="primary" class="current-name-icon" />
              <span class="current-name-label">Current name:</span>
              <span class="current-name-value">{{ collectionToEdit.name }}</span>
            </div>
          </div>
        </q-card-section>

        <q-card-actions class="modal-actions">
          <q-btn flat no-caps label="Cancel" color="grey-7" v-close-popup class="modal-btn cancel-btn btn-with-transition" />
          <q-btn flat no-caps label="Save Changes" :loading="isEditing" @click="$emit('update')" :disable="!editNameModel" class="modal-btn confirm-btn btn-with-transition" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';

export default defineComponent({
  name: 'CollectionDialogs',

  props: {
    showCreateDialog: {
      type: Boolean,
      default: false
    },
    showEditDialog: {
      type: Boolean,
      default: false
    },
    showDeleteDialog: {
      type: Boolean,
      default: false
    },
    newCollectionName: {
      type: String,
      default: ''
    },
    editCollectionName: {
      type: String,
      default: ''
    },
    collectionToEdit: {
      type: Object,
      default: null
    },
    collectionToDelete: {
      type: Object,
      default: null
    },
    isCreating: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isDeleting: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:show-create', 'update:show-edit', 'update:show-delete', 'update:new-name', 'update:edit-name', 'create', 'update', 'delete', 'register:create-input', 'register:edit-input'],

  setup(props, { emit }) {
    const createInput = ref(null);
    const editInput = ref(null);

    // Local models for v-model binding
    const createDialogModel = computed({
      get: () => props.showCreateDialog,
      set: value => emit('update:show-create', value)
    });

    const editDialogModel = computed({
      get: () => props.showEditDialog,
      set: value => emit('update:show-edit', value)
    });

    const deleteDialogModel = computed({
      get: () => props.showDeleteDialog,
      set: value => emit('update:show-delete', value)
    });

    const newNameModel = computed({
      get: () => props.newCollectionName,
      set: value => emit('update:new-name', value)
    });

    const editNameModel = computed({
      get: () => props.editCollectionName,
      set: value => emit('update:edit-name', value)
    });

    // Register inputs with parent component
    onMounted(() => {
      emit('register:create-input', createInput.value);
      emit('register:edit-input', editInput.value);
    });

    return {
      createInput,
      editInput,
      createDialogModel,
      editDialogModel,
      deleteDialogModel,
      newNameModel,
      editNameModel
    };
  }
});
</script>

<style lang="scss" scoped>
/* Modal Components Styling */
.modal-card {
  min-width: 450px;
  border-radius: 16px;
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15);
  will-change: transform, opacity;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.2s ease;
}

.modal-pattern-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: radial-gradient(circle at 10px 10px, rgba(0, 0, 0, 0.03) 1px, transparent 0);
  background-size: 20px 20px;
  z-index: 0;
  opacity: 0.3;
  pointer-events: none;
}

.delete-pattern {
  background-image: radial-gradient(circle at 10px 10px, rgba(244, 67, 54, 0.03) 1px, transparent 0);
}

.edit-pattern {
  background-image: radial-gradient(circle at 10px 10px, rgba(2, 123, 227, 0.03) 1px, transparent 0);
}

.modal-header {
  display: flex;
  align-items: center;
  border-radius: 16px 16px 0 0;
  padding: 24px 24px;
  position: relative;
  z-index: 1;
}

.create-header,
.edit-header {
  background: linear-gradient(135deg, #f7f7f7 0%, #ffffff 100%);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.delete-header {
  background: white;
  border-bottom: 1px solid rgba(244, 67, 54, 0.1);
}

.modal-header-icon {
  font-size: 24px;
  margin-right: 12px;
}

.modal-title {
  @include mona-sans-font($size: 20px, $bolded: true, $color: #333);
}

.close-btn {
  opacity: 0.7;
  transition: opacity 0.2s ease, background-color 0.2s ease;

  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.05);
  }
}

.modal-body {
  padding: 28px 24px 16px;
  position: relative;
  z-index: 1;
  background-color: white;
}

.input-label {
  @include mona-sans-font($size: 14px, $bolded: true, $color: #555);
  margin-bottom: 10px;
}

.modal-input {
  margin-bottom: 12px;

  :deep(.q-field__control) {
    height: 48px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }

  :deep(.q-field__counter) {
    color: rgba(0, 0, 0, 0.5);
  }
}

.focus-glow {
  :deep(.q-field--focused .q-field__control) {
    box-shadow: 0 0 0 2px rgba(2, 123, 227, 0.2);
    border-color: var(--main-btn-color);
  }
}

.clear-icon {
  font-size: 16px;
  color: #9e9e9e;
  transition: transform 0.2s ease, color 0.2s ease;
}

.hover-scale:hover {
  transform: scale(1.1);
  color: #666;
}

.info-message {
  @include inter-font($size: 11px, $bolded: false, $color: var(--secondary-text-color));
  margin-top: 10px;
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1.5;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 10px;
  border-radius: 6px;
}

.info-icon-small {
  font-size: 14px;
  color: var(--secondary-text-color);
  margin-right: 6px;
  flex-shrink: 0;
}

.warning-box {
  display: flex;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.04);
  padding: 16px;
  border-radius: 10px;
  border-left: 4px solid #f44336;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(244, 67, 54, 0.1);
}

.warning-icon {
  font-size: 24px;
  margin-right: 12px;
}

.warning-text {
  @include mona-sans-font($size: 15px, $bolded: true, $color: var(--danger));
}

.confirmation-text {
  @include inter-font($size: 15px, $color: var(--primary-text-color));
  margin: 20px 0;
  line-height: 1.6;
}

.collection-name-highlight {
  font-weight: 700;
  color: #333;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.note-text {
  @include inter-font($size: 11px, $bolded: false, $color: var(--secondary-text-color));
  margin-top: 28px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 10px;
  border-radius: 6px;
}

.note-icon {
  font-size: 14px;
  margin-right: 6px;
  flex-shrink: 0;
}

.current-name-box {
  background-color: #f0f9ff;
  padding: 14px;
  border-radius: 8px;
  border-left: 3px solid #027be3;
  margin-top: 20px;
  box-shadow: 0 2px 6px rgba(2, 123, 227, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 3px 8px rgba(2, 123, 227, 0.12);
  }
}

.current-name-content {
  display: flex;
  align-items: center;
}

.current-name-icon {
  font-size: 18px;
  margin-right: 6px;
}

.current-name-label {
  @include inter-font($size: 13px, $bolded: true, $color: #455a64);
}

.current-name-value {
  @include inter-font($size: 13px, $bolded: true, $color: #027be3);
  margin-left: 6px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px 24px;
  background-color: white;
  position: relative;
  z-index: 1;
}

.modal-btn {
  @include inter-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
  height: 44px;
  border-radius: 8px;
  letter-spacing: 0.3px;
  padding: 0 20px;
}

.btn-with-transition {
  transition: background-color 0.2s ease, opacity 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
  }
}

.cancel-btn {
  margin-right: 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.confirm-btn {
  background-color: var(--main-btn-color);
  color: var(--primary-text-color);

  &:hover {
    background-color: var(--main-btn-color);
  }
}

.delete-btn {
  background-color: var(--button-bg-danger);
  color: var(--terziary-text-color);

  &:hover {
    background-color: var(--button-bg-danger);
  }
}

.delete-separator {
  background-color: #ffebee;
  height: 2px;
}

/* Fix for dialog width */
:deep(.q-dialog__inner--minimized > div) {
  max-width: 500px;
}
</style>
