<template>
  <div class="collection-section">
    <div class="collection-selection-container">
      <div class="section-title">Upload to ...</div>

      <CollectionOption
        label="All assets"
        value="all"
        :expanded="allAssetsExpanded"
        :modelValue="destination"
        @update:expanded="
          allAssetsExpanded = $event;
          updatePanelSelection(true, 'all');
        "
        @update:modelValue="$emit('update:destination', $event)"
      />

      <ExistingCollectionOption
        label="Add to existing collection"
        value="existing"
        :expanded="existingCollectionExpanded"
        :modelValue="destination"
        :collections="collections"
        :collectionsOptions="collectionsOptions"
        :isLoadingCollections="isLoadingCollections"
        :selectedCollection="selectedCollection"
        @update:expanded="
          existingCollectionExpanded = $event;
          updatePanelSelection(true, 'existing');
        "
        @update:modelValue="$emit('update:destination', $event)"
        @update:selected-collection="$emit('update:selectedCollection', $event)"
      />

      <NewCollectionOption
        label="Create a new collection"
        value="new"
        :expanded="newCollectionExpanded"
        :modelValue="destination"
        :collectionName="newCollectionName"
        :collections="collections"
        @update:expanded="
          newCollectionExpanded = $event;
          updatePanelSelection(true, 'new');
        "
        @update:modelValue="$emit('update:destination', $event)"
        @update:collection-name="$emit('update:newCollectionName', $event)"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import CollectionOption from './CollectionOption.vue';
import ExistingCollectionOption from './ExistingCollectionOption.vue';
import NewCollectionOption from './NewCollectionOption.vue';

export default {
  name: 'CollectionSelector',
  components: {
    CollectionOption,
    ExistingCollectionOption,
    NewCollectionOption
  },
  props: {
    destination: {
      type: String,
      required: true
    },
    collections: {
      type: Array,
      required: true
    },
    collectionsOptions: {
      type: Array,
      required: true
    },
    isLoadingCollections: {
      type: Boolean,
      required: true
    },
    selectedCollection: {
      type: Object,
      default: null
    },
    newCollectionName: {
      type: String,
      required: true
    }
  },
  emits: ['update:destination', 'update:selectedCollection', 'update:newCollectionName'],
  setup(props, { emit }) {
    const allAssetsExpanded = ref(true);
    const existingCollectionExpanded = ref(false);
    const newCollectionExpanded = ref(false);

    watch(() => props.destination, updateExpansionPanels);
    watch(allAssetsExpanded, newVal => updatePanelSelection(newVal, 'all'));
    watch(existingCollectionExpanded, newVal => updatePanelSelection(newVal, 'existing'));
    watch(newCollectionExpanded, newVal => updatePanelSelection(newVal, 'new'));

    function updateExpansionPanels(newVal) {
      if (newVal === 'all') {
        allAssetsExpanded.value = true;
        existingCollectionExpanded.value = false;
        newCollectionExpanded.value = false;
      } else if (newVal === 'existing') {
        allAssetsExpanded.value = false;
        existingCollectionExpanded.value = true;
        newCollectionExpanded.value = false;
      } else if (newVal === 'new') {
        allAssetsExpanded.value = false;
        existingCollectionExpanded.value = false;
        newCollectionExpanded.value = true;
      }
    }

    function updatePanelSelection(isExpanded, destinationType) {
      if (isExpanded) {
        allAssetsExpanded.value = destinationType === 'all';
        existingCollectionExpanded.value = destinationType === 'existing';
        newCollectionExpanded.value = destinationType === 'new';
        emit('update:destination', destinationType);
      }
    }

    return {
      allAssetsExpanded,
      existingCollectionExpanded,
      newCollectionExpanded,
      updateExpansionPanels,
      updatePanelSelection
    };
  }
};
</script>

<style lang="scss" scoped>
.collection-section {
  margin-bottom: 1.5rem;
}

.collection-selection-container {
  margin-top: 1rem;
}

.section-title {
  @include mona-sans-font($size: 16px, $bolded: true, $color: var(--primary-text-color));
  margin-bottom: 1rem;
}

.input-label {
  @include mona-sans-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
  margin-bottom: 10px;
}
</style>
