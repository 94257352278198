<template>
  <div>
    <div class="control-header">
      <label class="input-caption">Brush Size</label>
      <span class="value-badge">{{ brushSize }}</span>
    </div>
    <div class="range-container">
      <span class="range-label">15</span>
      <input type="range" :value="brushSize" @input="updateBrushSize" min="15" max="150" class="range-slider" />
      <span class="range-label">150</span>
    </div>

    <q-btn class="action-button action-button--secondary mt-3 mb-2" @click="clearMask" flat no-caps> <q-icon name="o_delete" color="grey-7" size="xs" class="button-icon" /> Clear Canvas </q-btn>

    <div class="control-header">
      <label class="input-caption">Generative Fill</label>
      <q-icon name="help" size="xs" class="help-icon" color="grey-7" />
    </div>
    <q-input autogrow outlined v-model="localPrompt" type="text" placeholder="Describe what you want to generate" @input="updatePrompt" />

    <q-btn class="action-button action-button--primary mt-3" :loading="isGenerating" @click="generateFill" :disable="!hasMaskData || !localPrompt.trim()" flat no-caps>
      <template v-if="!isGenerating"> <q-icon name="auto_fix_high" size="xs" class="button-icon" color="grey-7" /> Generate </template>
      <template v-slot:loading>
        <q-spinner color="grey-7" class="on-left" />
        <span v-if="operationStatus === 'preparing'">AI At Work… <q-icon name="fas fa-magic" size="xs" color="grey-7" /> </span>
        <span v-else-if="operationStatus === 'starting'">Working Our Magic! <q-icon name="fas fa-hat-wizard" size="xs" color="grey-7" /> </span>
        <span v-else-if="operationStatus === 'processing'">Almost Ready... <q-icon name="fas fa-hourglass-half" size="xs" color="grey-7" /></span>
      </template>
    </q-btn>

    <div v-if="!hasMaskData" class="usage-hint">
      <q-icon name="info_outline" size="sm" color="grey-7" class="info-icon" />
      <p>Use the brush to paint the areas you want to fill</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GenerativeFillControls',
  props: {
    brushSize: {
      type: Number,
      required: true
    },
    isGenerating: {
      type: Boolean,
      required: true
    },
    operationStatus: {
      type: String,
      default: null
    },
    prompt: {
      type: String,
      default: ''
    },
    hasMaskData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localPrompt: this.prompt
    };
  },
  watch: {
    prompt(newVal) {
      this.localPrompt = newVal;
    }
  },
  methods: {
    updateBrushSize(event) {
      this.$emit('update:brushSize', parseInt(event.target.value, 10));
    },
    updatePrompt() {
      this.$emit('update:prompt', this.localPrompt);
    },
    clearMask() {
      this.$emit('clearMask');
    },
    generateFill() {
      this.$emit('generateFill');
    }
  }
};
</script>

<style lang="scss" scoped>
.control-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.input-caption {
  @include mona-sans-font($size: 15px, $bolded: true, $color: var(--main-text-color));
  margin-bottom: 12px;
  display: block;
}

.value-badge {
  background: #fecb2f;
  color: #333333;
  padding: 3px 8px;
  border-radius: 10px;
  @include mona-sans-font($size: 13px, $bolded: true, $color: var(--main-text-color));
}

.range-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.range-label {
  @include mona-sans-font($size: 13px, $bolded: true, $color: var(--main-text-color));
  min-width: 28px;
}

.range-slider {
  flex: 1;
  height: 5px;
  border-radius: 3px;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  background: #e5e7eb;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    background: #fecb2f;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease;
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(254, 203, 47, 0.5);

    &:hover {
      transform: scale(1.1);
    }
  }
}

.action-button {
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  @include mona-sans-font($size: 15px, $bolded: true, $color: var(--main-text-color));
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &--primary {
    background: #fecb2f;
    color: #333333;

    &:hover {
      background: #fed95f;
    }
  }

  &--secondary {
    background: #f3f4f6;
    color: #333;

    &:hover {
      background: #e5e7eb;
    }
    &.active {
      background: linear-gradient(135deg, #ffffff, #f0e6ff) !important;
      border: 1px solid #e6d5ff !important;
      box-shadow: 0 2px 4px rgba(230, 213, 255, 0.3) !important;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.help-icon {
  color: #9ca3af;
  cursor: pointer;
}

.button-icon {
  margin-right: 8px;
  font-size: 16px;
}

.mt-3 {
  margin-top: 16px;
}

.mb-2 {
  margin-bottom: 12px;
}

.usage-hint {
  margin-top: 16px;
  padding: 12px;
  background-color: #f9fafb;
  border-radius: 8px;
  display: flex;
  align-items: center;

  .info-icon {
    margin-right: 10px;
    flex-shrink: 0;
  }

  p {
    @include inter-font($size: 14px, $color: var(--secondary-text-color));
    margin: 0;
    line-height: 1.5;
  }
}
</style>
