<template>
  <div class="welcome-screen">
    <div class="empty-state">
      <q-icon name="o_photo_filter" size="48px" color="grey-5" />
      <h2 class="empty-state-title">Choose a tool</h2>
      <p class="empty-state-text">Select a tool from the sidebar to enhance your image</p>
      <p class="empty-state-hint">Hover over the icons to see what each tool does</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState'
};
</script>

<style lang="scss" scoped>
.welcome-screen {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  max-width: 320px;
  background: transparent;

  .empty-state-title {
    @include mona-sans-font($size: 22px, $bolded: true, $color: var(--main-text-color));
    margin: 16px 0 8px 0;
  }

  .empty-state-text {
    @include inter-font($size: 15px, $color: var(--secondary-text-color));
    margin: 0 0 16px 0;
    line-height: 1.5;
  }

  .empty-state-hint {
    @include inter-font($size: 13px, $color: var(--secondary-text-color));
    opacity: 0.7;
    margin: 0;
    line-height: 1.5;
    font-style: italic;
    background: rgba(248, 249, 250, 0.5);
    padding: 8px 12px;
    border-radius: 6px;
  }
}
</style>
