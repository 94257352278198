<template>
  <div v-if="isVisible" class="upload-progress-overlay">
    <div class="upload-progress-container">
      <q-spinner size="40px" color="primary" class="q-mb-md" />
      <div class="upload-status-text">{{ statusText }}</div>
      <q-linear-progress :value="progress" class="q-mt-sm" rounded color="primary" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadProgressOverlay',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    statusText: {
      type: String,
      default: 'Processing...'
    },
    progress: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

.upload-progress-container {
  text-align: center;
  padding: 2rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
}

.upload-status-text {
  @include mona-sans-font($size: 18px, $bolded: true, $color: var(--primary-text-color));
  margin: 1rem 0;
}
</style>
