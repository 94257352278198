<template>
  <div class="image-container" :style="containerStyle">
    <q-img :src="image.optimized_url" :placeholder-src="image.preview_url" class="image-preview" :contain="true" @load="onImageLoad" ref="imageElement" />
    <canvas
      v-if="imageLoaded"
      ref="brushCanvas"
      class="brush-canvas"
      :class="{ 'brush-enabled': isBrushToolActive }"
      @mousedown="startDrawing"
      @mousemove="draw"
      @mouseup="stopDrawing"
      @mouseleave="stopDrawing"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="stopDrawing"
    />
  </div>
</template>

<script>
export default {
  name: 'ImageEditorCanvas',
  props: {
    image: {
      type: Object,
      required: true
    },
    brushSize: {
      type: Number,
      required: true
    },
    brushColor: {
      type: String,
      required: true
    },
    showHistoryPanel: {
      type: Boolean,
      default: false
    },
    activeTool: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isDrawing: false,
      startX: 0,
      startY: 0,
      imageLoaded: false,
      hasMaskData: false,
      resizeObserver: null,
      canvasRect: null
    };
  },
  computed: {
    containerStyle() {
      // History panel height is approximately 230px (adjust based on your design)
      const historyPanelHeight = this.showHistoryPanel ? '230px' : '0px';
      return {
        height: `calc(100vh - 48px - 75px - 68px - ${historyPanelHeight})`
      };
    },
    isBrushToolActive() {
      // Only show brush cursor for these tools
      return this.activeTool === 'removeObjects' || this.activeTool === 'generate';
    }
  },
  mounted() {
    // Create a resize observer to update canvas when container resizes
    this.resizeObserver = new ResizeObserver(this.handleResize);

    // Add window resize event listener
    window.addEventListener('resize', this.handleResize);

    // Observer the image container
    if (this.$el) {
      this.resizeObserver.observe(this.$el);
    }
  },
  beforeDestroy() {
    // Clean up resize observer
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    // Remove window resize event listener
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    clearCanvas() {
      const brushCanvas = this.$refs.brushCanvas;
      if (brushCanvas) {
        const ctx = brushCanvas.getContext('2d');
        ctx.clearRect(0, 0, brushCanvas.width, brushCanvas.height);
        this.hasMaskData = false;
        this.$emit('update:hasMaskData', false);
      }
    },
    handleResize() {
      if (this.imageLoaded) {
        // Delay to ensure DOM is updated
        setTimeout(() => {
          this.initializeCanvas();
        }, 100);
      }
    },
    initializeCanvas() {
      const brushCanvas = this.$refs.brushCanvas;
      if (!brushCanvas) return;

      this.updateCursor();

      // Get the image element from q-img component
      const imageElement = this.$refs.imageElement.$el.querySelector('.q-img__image');
      if (!imageElement) return;

      // Get the natural dimensions of the original image
      const img = new Image();
      img.src = this.image.optimized_url;

      // Get the actual dimensions of the displayed image
      const imageRect = imageElement.getBoundingClientRect();

      // Set canvas dimensions to match the original image dimensions
      brushCanvas.width = img.naturalWidth || imageElement.naturalWidth;
      brushCanvas.height = img.naturalHeight || imageElement.naturalHeight;

      // Set canvas display size to match exactly the displayed image size
      brushCanvas.style.width = `${imageRect.width}px`;
      brushCanvas.style.height = `${imageRect.height}px`;

      // Update position to exactly match the image
      brushCanvas.style.top = `${imageElement.offsetTop}px`;
      brushCanvas.style.left = `${imageElement.offsetLeft}px`;
      brushCanvas.style.transform = 'none'; // Remove the transform that was centering it

      // Store the canvas rect for coordinate calculations
      this.canvasRect = brushCanvas.getBoundingClientRect();

      // Initialize brush canvas
      const brushCtx = brushCanvas.getContext('2d');
      brushCtx.lineJoin = 'round';
      brushCtx.lineCap = 'round';
      brushCtx.strokeStyle = this.brushColor;

      // Reset mask data flag
      this.hasMaskData = false;
      this.$emit('update:hasMaskData', false);
    },
    onImageLoad() {
      this.imageLoaded = true;
      this.$nextTick(() => {
        this.initializeCanvas();
        this.updateCursor();
      });
    },
    startDrawing(e) {
      if (!this.isBrushToolActive) return;
      e.preventDefault();

      const brushCanvas = this.$refs.brushCanvas;
      const brushContext = brushCanvas.getContext('2d');

      // Clear canvas if starting new stroke
      brushContext.beginPath();

      this.isDrawing = true;

      // Get fresh canvas rect - this is important for zoomed browsers
      this.canvasRect = brushCanvas.getBoundingClientRect();

      // Calculate scale factors
      const scaleX = brushCanvas.width / this.canvasRect.width;
      const scaleY = brushCanvas.height / this.canvasRect.height;

      // Apply scaling to coordinates
      this.startX = (e.clientX - this.canvasRect.left) * scaleX;
      this.startY = (e.clientY - this.canvasRect.top) * scaleY;
    },
    draw(e) {
      if (!this.isDrawing || !this.isBrushToolActive) return;
      e.preventDefault();

      const brushCanvas = this.$refs.brushCanvas;
      const brushContext = brushCanvas.getContext('2d');

      // Get fresh canvas rect for every draw event
      this.canvasRect = brushCanvas.getBoundingClientRect();

      // Calculate scale factors
      const scaleX = brushCanvas.width / this.canvasRect.width;
      const scaleY = brushCanvas.height / this.canvasRect.height;

      // Apply scaling to coordinates
      const x = (e.clientX - this.canvasRect.left) * scaleX;
      const y = (e.clientY - this.canvasRect.top) * scaleY;

      brushContext.lineWidth = this.brushSize;
      brushContext.strokeStyle = this.brushColor;
      brushContext.globalCompositeOperation = 'source-over';

      brushContext.beginPath();
      brushContext.moveTo(this.startX, this.startY);
      brushContext.lineTo(x, y);
      brushContext.stroke();

      this.startX = x;
      this.startY = y;

      // Set mask data flag to true as soon as the user draws something
      if (!this.hasMaskData) {
        this.hasMaskData = true;
        this.$emit('update:hasMaskData', true);
      }
    },
    stopDrawing() {
      this.isDrawing = false;
    },
    updateCursor() {
      const brushCanvas = this.$refs.brushCanvas;
      if (!brushCanvas) return;

      if (!this.isBrushToolActive) {
        brushCanvas.style.cursor = 'default';
        return;
      }

      // Scale the cursor size relative to the canvas dimensions
      const rect = brushCanvas.getBoundingClientRect();
      const scaleX = brushCanvas.width / rect.width;
      const displaySize = Math.max(8, this.brushSize / scaleX);

      const color = this.brushColor.replace('#', '%23');
      const svg = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${displaySize}" height="${displaySize}" viewBox="0 0 ${displaySize} ${displaySize}"><circle cx="${
        displaySize / 2
      }" cy="${displaySize / 2}" r="${displaySize / 2 - 1}" fill="none" stroke="${color}" stroke-width="1"/></svg>`;

      brushCanvas.style.cursor = `url('${svg}') ${displaySize / 2} ${displaySize / 2}, crosshair`;
    },
    extractMask() {
      return new Promise(resolve => {
        const brushCanvas = this.$refs.brushCanvas;
        const brushContext = brushCanvas.getContext('2d');
        const imageData = brushContext.getImageData(0, 0, brushCanvas.width, brushCanvas.height);

        const maskCanvas = document.createElement('canvas');
        const maskContext = maskCanvas.getContext('2d');

        maskCanvas.width = brushCanvas.width;
        maskCanvas.height = brushCanvas.height;

        for (let i = 0; i < imageData.data.length; i += 4) {
          const isFilled = imageData.data[i + 3] !== 0;
          const maskValue = isFilled ? 255 : 0;

          imageData.data[i] = maskValue;
          imageData.data[i + 1] = maskValue;
          imageData.data[i + 2] = maskValue;
          imageData.data[i + 3] = 255;
        }

        maskContext.putImageData(imageData, 0, 0);
        maskCanvas.toBlob(
          blob => {
            resolve(blob);
          },
          'image/png',
          1.0
        ); // Use PNG format with maximum quality to preserve exact dimensions
      });
    },
    checkForMaskData() {
      const brushCanvas = this.$refs.brushCanvas;
      if (!brushCanvas) return false;

      const ctx = brushCanvas.getContext('2d');
      const imageData = ctx.getImageData(0, 0, brushCanvas.width, brushCanvas.height);
      const data = imageData.data;

      // Check if any pixel has alpha > 0
      for (let i = 3; i < data.length; i += 4) {
        if (data[i] > 0) {
          return true;
        }
      }

      return false;
    },
    // Add touch support
    handleTouchStart(e) {
      if (!this.isBrushToolActive) return;
      if (e.touches.length !== 1) return;

      e.preventDefault();

      const touch = e.touches[0];
      const mouseEvent = new MouseEvent('mousedown', {
        clientX: touch.clientX,
        clientY: touch.clientY
      });

      this.startDrawing(mouseEvent);
    },
    handleTouchMove(e) {
      if (!this.isDrawing || !this.isBrushToolActive) return;
      if (e.touches.length !== 1) return;

      e.preventDefault();

      const touch = e.touches[0];
      const mouseEvent = new MouseEvent('mousemove', {
        clientX: touch.clientX,
        clientY: touch.clientY
      });

      this.draw(mouseEvent);
    }
  },
  watch: {
    activeTool() {
      this.updateCursor();
      if (this.isBrushToolActive) {
        // Check if canvas already has data
        this.$nextTick(() => {
          const hasData = this.checkForMaskData();
          this.hasMaskData = hasData;
          this.$emit('update:hasMaskData', hasData);
        });
      }
    },
    brushColor() {
      this.updateCursor();
    },
    brushSize() {
      this.updateCursor();
    },
    showHistoryPanel() {
      // Reinitialize canvas when history panel visibility changes
      this.$nextTick(() => {
        this.initializeCanvas();
      });
    },
    isBrushToolActive() {
      this.updateCursor();
    },
    'image.optimized_url'() {
      this.$nextTick(() => {
        this.initializeCanvas();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: height 0.3s ease;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
}

.image-preview {
  max-height: 100%;
  z-index: 1;
}

.brush-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  cursor: default;
  pointer-events: all;
  z-index: 2;

  /* Enable brush cursor only for specific tools */
  &.brush-enabled {
    cursor: crosshair;
  }

  /* Hide scrollbars */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
}
</style>
