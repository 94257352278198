import { render, staticRenderFns } from "./BusinessAssets.vue?vue&type=template&id=46ac32ce&scoped=true&"
import script from "./BusinessAssets.vue?vue&type=script&lang=js&"
export * from "./BusinessAssets.vue?vue&type=script&lang=js&"
import style0 from "./BusinessAssets.vue?vue&type=style&index=0&id=46ac32ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ac32ce",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QField from 'quasar/src/components/field/QField.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QItemLabel,QItem,QItemSection,QAvatar,QIcon,QChip,QCheckbox,QField,QMenu,QVirtualScroll});
