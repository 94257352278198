<template>
  <div class="tooltip-overlay" v-if="toolId" :style="positionStyle">
    <div class="tooltip-content" :class="{ 'coming-soon': isDisabled, 'no-wrap': toolId === 'generateBackground' }">
      <div class="tooltip-header">
        <h3>{{ name }}</h3>
        <div v-if="isDisabled" class="coming-soon-badge">Coming Soon!</div>
      </div>
      <p class="tooltip-description" :class="{ 'no-wrap': toolId === 'generateBackground' }">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolTooltip',
  props: {
    toolId: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    positionStyle: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-overlay {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  width: auto;
  height: auto;
}

.tooltip-content {
  background: white;
  border-radius: 8px;
  padding: 16px;
  min-width: 260px;
  max-width: 320px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.coming-soon {
    border: 2px dashed #ccc;
    background: #f9f9f9;
  }

  &.no-wrap {
    white-space: nowrap;
    width: auto;
    min-width: auto;
    max-width: none;
    padding: 16px 24px;
  }
}

.tooltip-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;

  h3 {
    @include mona-sans-font($size: 18px, $bolded: true, $color: var(--main-text-color));
    margin: 0;
  }
}

.coming-soon-badge {
  background: #fecb2f;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 12px;
}

.tooltip-description {
  @include inter-font($size: 14px, $color: var(--secondary-text-color));
  margin: 0;
  line-height: 1.5;

  &.no-wrap {
    white-space: nowrap;
  }
}
</style>
