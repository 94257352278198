<template>
  <q-expansion-item v-model="expandedModel" dense expand-icon="none" class="outlined-section" :class="{ 'active-section': expandedModel }">
    <template v-slot:header>
      <q-item-section avatar class="radio-section">
        <q-radio v-model="radioModel" :val="value" color="primary" />
      </q-item-section>
      <q-item-section>
        <span class="text-weight-medium">{{ label }}</span>
      </q-item-section>
    </template>
    <slot></slot>
  </q-expansion-item>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  name: 'CollectionOption',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue', 'update:expanded'],
  setup(props, { emit }) {
    const expandedModel = computed({
      get: () => props.expanded,
      set: value => emit('update:expanded', value)
    });

    const radioModel = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });

    return {
      expandedModel,
      radioModel
    };
  }
};
</script>

<style lang="scss" scoped>
.outlined-section {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 12px;
  transition: box-shadow 0.2s ease;
  overflow: hidden;
}

.outlined-section:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.active-section {
  background-color: rgba(0, 0, 0, 0.02);
}

:deep(.q-expansion-item__toggle-icon) {
  display: none !important;
}

.radio-section {
  min-width: 32px;
}
</style>
