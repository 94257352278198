<template>
  <div class="sidebar q-pa-md">
    <!-- Collections Section -->
    <CollectionsSection
      :collections="collections"
      :isLoading="collectionsLoading"
      :selectedCollection="selectedCollection"
      @select-collection="$emit('select-collection', $event)"
      @confirm-edit-collection="$emit('confirm-edit-collection', $event)"
      @confirm-delete-collection="$emit('confirm-delete-collection', $event)"
      @create-collection="$emit('create-collection')"
    />

    <!-- Content Filters Section -->
    <FiltersSection
      :dateRange="dateRange"
      :selectedServices="selectedServices"
      :selectedAspectRatios="selectedAspectRatios"
      :metadataSearchQuery="metadataSearchQuery"
      :selectedClient="selectedClient"
      :clientOptions="clientOptions"
      :serviceOptions="serviceOptions"
      :aspectRatioOptions="aspectRatioOptions"
      :hasActiveFilters="hasActiveFilters"
      :isLoadingClients="isLoadingClients"
      :userRole="userRole"
      @update:date-range="$emit('update:date-range', $event)"
      @update:services="$emit('update:services', $event)"
      @update:aspect-ratios="$emit('update:aspect-ratios', $event)"
      @update:metadata-search="$emit('update:metadata-search', $event)"
      @update:client="$emit('update:client', $event)"
      @filter-clients="$emit('filter-clients', $event[0], $event[1])"
      @clear-filters="$emit('clear-filters')"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import CollectionsSection from './CollectionsSection.vue';
import FiltersSection from './FiltersSection.vue';

export default defineComponent({
  name: 'GallerySidebar',
  components: {
    CollectionsSection,
    FiltersSection
  },

  props: {
    collections: {
      type: Array,
      default: () => []
    },
    collectionsLoading: {
      type: Boolean,
      default: false
    },
    selectedCollection: {
      type: [String, Number],
      default: null
    },
    dateRange: {
      type: Object,
      default: () => ({ from: null, to: null })
    },
    selectedServices: {
      type: Array,
      default: () => []
    },
    selectedAspectRatios: {
      type: Array,
      default: () => []
    },
    metadataSearchQuery: {
      type: String,
      default: ''
    },
    selectedClient: {
      type: Object,
      default: null
    },
    clientOptions: {
      type: Array,
      default: () => []
    },
    serviceOptions: {
      type: Array,
      default: () => []
    },
    aspectRatioOptions: {
      type: Array,
      default: () => []
    },
    hasActiveFilters: {
      type: Boolean,
      default: false
    },
    isLoadingClients: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: ''
    }
  },

  emits: [
    'select-collection',
    'confirm-edit-collection',
    'confirm-delete-collection',
    'create-collection',
    'update:date-range',
    'update:services',
    'update:aspect-ratios',
    'update:metadata-search',
    'update:client',
    'filter-clients',
    'clear-filters'
  ]
});
</script>

<style lang="scss" scoped>
.sidebar {
  background: white;
  border-right: 1px solid #eee;
  padding: 24px 16px;
  overflow-y: auto;
  height: calc(100vh - 76px);
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  min-width: 320px;
  overscroll-behavior: contain;

  & > * + * {
    margin-top: 24px;
  }

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: scrollbar-color 0.3s ease;

  &:hover {
    scrollbar-color: rgba(136, 136, 136, 0.5) rgba(241, 241, 241, 0.3);
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
    transition: background 0.3s ease;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.5);
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
</style>
