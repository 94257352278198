<template>
  <q-dialog v-model="dialogModel" transition-show="scale" transition-hide="scale" seamless>
    <q-card class="modal-card">
      <q-card-section class="modal-header">
        <div>
          <div class="modal-title">Add to Collection</div>
          <div class="modal-subtitle">Add {{ imageCount }} selected images to a collection</div>
        </div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup class="close-btn" />
      </q-card-section>

      <q-separator />

      <q-card-section class="selected-images-section" v-if="imageCount > 0">
        <div class="selected-images-header">
          <q-icon name="photo_library" size="sm" class="q-mr-xs" />
          <span>Selected Images</span>
        </div>
        <div class="selected-images-container">
          <q-chip v-for="image in selectedImages.slice(0, 5)" :key="image.id" size="sm" class="image-chip" dense> ID: {{ image.id }} </q-chip>
          <q-chip v-if="imageCount > 5" size="sm" class="image-chip more-chip" dense> +{{ imageCount - 5 }} more </q-chip>
        </div>
      </q-card-section>

      <q-card-section class="modal-body">
        <!-- Add to existing collection section -->
        <q-expansion-item v-model="addToExistingExpanded" :header-class="{ 'active-section': addToExistingExpanded }" dense expand-icon="none" class="outlined-section">
          <template v-slot:header>
            <q-item-section avatar>
              <q-radio v-model="selectionType" val="existing" color="primary" />
            </q-item-section>
            <q-item-section>
              <span class="text-weight-medium">Add to existing collection</span>
            </q-item-section>
          </template>

          <div class="q-pa-md">
            <div class="section-content">
              <div class="input-label q-mb-sm">Select a collection</div>
              <q-select
                v-model="selectedCollection"
                :options="collectionsOptions"
                outlined
                dense
                :loading="isLoadingCollections"
                :disable="collections.length === 0"
                :label="isLoadingCollections ? 'Loading collections...' : 'Select a collection'"
                class="q-mb-sm collection-select"
                bg-color="white"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey"> No collections found. Create one below. </q-item-section>
                  </q-item>
                </template>
                <template v-slot:prepend>
                  <q-icon name="folder" color="primary" size="sm" />
                </template>
              </q-select>

              <div class="text-caption text-grey q-mt-sm" v-if="collections.length === 0 && !isLoadingCollections">
                <q-icon name="info" size="12px" class="q-mr-xs" />
                You don't have any collections yet. Create one below.
              </div>
            </div>
          </div>
        </q-expansion-item>

        <!-- Create new collection section -->
        <q-expansion-item v-model="createNewExpanded" :header-class="{ 'active-section': createNewExpanded }" dense expand-icon="none" class="outlined-section">
          <template v-slot:header>
            <q-item-section avatar>
              <q-radio v-model="selectionType" val="new" color="primary" />
            </q-item-section>
            <q-item-section>
              <span class="text-weight-medium">Create a new collection</span>
            </q-item-section>
          </template>

          <div class="q-pa-md">
            <div class="section-content">
              <div class="input-label q-mb-sm">Collection Name</div>
              <q-input
                v-model="newCollectionName"
                outlined
                dense
                :rules="[val => !!val || 'Name is required']"
                class="q-mb-sm collection-input"
                maxlength="50"
                counter
                bg-color="white"
                placeholder="Enter collection name"
                hint="Collections help you organize related content for easier access"
              >
                <template v-slot:prepend>
                  <q-icon name="folder" color="primary" size="sm" />
                </template>
                <template v-slot:append v-if="newCollectionName">
                  <q-icon name="close" class="cursor-pointer clear-icon hover-scale" @click="newCollectionName = ''" />
                </template>
              </q-input>
            </div>
          </div>
        </q-expansion-item>
      </q-card-section>

      <q-card-actions class="modal-actions">
        <q-btn flat no-caps label="Cancel" color="grey-7" v-close-popup class="modal-btn cancel-btn" />
        <q-btn
          flat
          no-caps
          :label="createNewExpanded ? 'Create & Add' : 'Add to Collection'"
          :loading="isAddingImages"
          @click="handleAddToCollection"
          :disable="!isFormValid"
          class="modal-btn confirm-btn"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { CollectionsApi } from '@api/modules/collections';

export default {
  name: 'AddToCollectionDialog',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectedImages: {
      type: Array,
      default: () => []
    }
  },

  emits: ['update:show', 'add-success', 'loading-state'],

  setup(props, { emit }) {
    // Dialog state
    const dialogModel = computed({
      get: () => props.show,
      set: value => emit('update:show', value)
    });

    // Get query client for cache invalidation
    const queryClient = useQueryClient();

    // Selection mode state
    const selectionType = ref('existing');
    const addToExistingExpanded = ref(true);
    const createNewExpanded = ref(false);
    const selectedCollection = ref(null);
    const newCollectionName = ref('');
    const isAddingImages = ref(false);

    // Update expansion panels based on selection type
    watch(selectionType, newVal => {
      if (newVal === 'existing') {
        addToExistingExpanded.value = true;
        createNewExpanded.value = false;
      } else if (newVal === 'new') {
        addToExistingExpanded.value = false;
        createNewExpanded.value = true;
      }
    });

    // Synchronize expanded states
    watch(addToExistingExpanded, newVal => {
      if (newVal) {
        createNewExpanded.value = false;
        selectionType.value = 'existing';
      }
    });

    watch(createNewExpanded, newVal => {
      if (newVal) {
        addToExistingExpanded.value = false;
        selectionType.value = 'new';
      }
    });

    // Get collections
    const { data: collectionsData, isLoading: isLoadingCollections } = useQuery({
      queryKey: ['collections'],
      queryFn: async () => {
        const response = await CollectionsApi.getAllCollections();
        return response.data.collections || [];
      },
      staleTime: 1000 * 60 * 5 // 5 minutes
    });

    const collections = computed(() => collectionsData.value || []);

    const collectionsOptions = computed(() =>
      collections.value.map(collection => ({
        label: collection.name,
        value: collection.id,
        description: `${collection.item_count} items`
      }))
    );

    // Validation
    const isFormValid = computed(() => {
      if (addToExistingExpanded.value) {
        return selectedCollection.value !== null;
      } else if (createNewExpanded.value) {
        return newCollectionName.value.trim() !== '';
      }
      return false;
    });

    const imageCount = computed(() => props.selectedImages.length);

    // Handle add to collection
    const handleAddToCollection = async () => {
      if (!isFormValid.value) return;

      try {
        isAddingImages.value = true;
        emit('loading-state', true);
        const imageIds = props.selectedImages.map(img => img.id);

        if (addToExistingExpanded.value) {
          // Add to existing collection
          const collectionId = selectedCollection.value.value;
          await CollectionsApi.addItemsToCollection(collectionId, imageIds);
          // Invalidate collections query to update item counts
          queryClient.invalidateQueries({ queryKey: ['collections'] });
          emit('add-success', `Images added to collection "${selectedCollection.value.label}"`);
        } else if (createNewExpanded.value) {
          // Create new collection then add items
          const createResponse = await CollectionsApi.createCollection(newCollectionName.value);
          const newCollectionId = createResponse.data.collection.id;
          await CollectionsApi.addItemsToCollection(newCollectionId, imageIds);
          // Invalidate collections query to update the list
          queryClient.invalidateQueries({ queryKey: ['collections'] });
          emit('add-success', `Images added to new collection "${newCollectionName.value}"`);
        }

        // Reset form and close dialog
        dialogModel.value = false;
        selectedCollection.value = null;
        newCollectionName.value = '';
      } catch (error) {
        console.error('Error adding images to collection:', error);
        emit('loading-state', false);
      } finally {
        isAddingImages.value = false;
      }
    };

    // Reset form when dialog opens
    watch(
      () => props.show,
      isVisible => {
        if (isVisible) {
          selectionType.value = 'existing';
          addToExistingExpanded.value = true;
          createNewExpanded.value = false;
          selectedCollection.value = null;
          newCollectionName.value = '';
        }
      }
    );

    return {
      dialogModel,
      addToExistingExpanded,
      createNewExpanded,
      selectedCollection,
      newCollectionName,
      isAddingImages,
      isLoadingCollections,
      collections,
      collectionsOptions,
      isFormValid,
      imageCount,
      handleAddToCollection,
      selectionType
    };
  }
};
</script>

<style lang="scss" scoped>
.modal-card {
  min-width: 500px;
  border-radius: 16px;
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15);
  will-change: transform, opacity;
  overflow: hidden;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px;
  background: linear-gradient(to right, #f7f7f7, #ffffff);
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.modal-title {
  @include mona-sans-font($size: 20px, $bolded: true, $color: #333);
  letter-spacing: 0.3px;
}

.modal-subtitle {
  @include inter-font($size: 14px, $color: var(--secondary-text-color));
  margin-top: 4px;
}

.selected-images-section {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 16px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.selected-images-header {
  @include inter-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.selected-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 80px;
  overflow-y: auto;
  padding: 4px 0;
}

.image-chip {
  @include inter-font($size: 12px, $color: var(--primary-text-color));
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.08);
  transition: background-color 0.2s ease;
  height: 28px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
}

.more-chip {
  background-color: rgba(0, 0, 0, 0.06);
  color: var(--secondary-text-color);
}

.close-btn {
  opacity: 0.7;
  transition: opacity 0.2s ease, background-color 0.2s ease;

  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.05);
  }
}

.modal-body {
  padding: 16px 16px;
  background-color: white;
}

.active-section {
  background-color: rgba(0, 0, 0, 0.02);
  border-left: 3px solid var(--main-btn-color);
}

.outlined-section {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 12px;
  transition: box-shadow 0.2s ease;
  overflow: hidden;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  /* Hide the expansion arrow */
  :deep(.q-expansion-item__toggle-icon) {
    display: none !important;
  }
}

.section-content {
  padding: 16px 0;
}

.input-label {
  @include mona-sans-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
}

.clear-icon {
  font-size: 16px;
  color: #9e9e9e;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #666;
  }
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px 24px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
}

.modal-btn {
  @include inter-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
  height: 44px;
  border-radius: 8px;
  letter-spacing: 0.3px;
  padding: 0 20px;
  transition: all 0.2s ease;
}

.cancel-btn {
  margin-right: 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.confirm-btn {
  background-color: var(--main-btn-color);
  color: var(--primary-text-color);

  &:hover {
    opacity: 0.9;
  }
}

.collection-select,
.collection-input {
  :deep(.q-field__control) {
    height: 44px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }

  :deep(.q-field--focused .q-field__control) {
    box-shadow: 0 0 0 2px rgba(2, 123, 227, 0.2);
    border-color: var(--main-btn-color);
  }

  :deep(.q-field__counter) {
    color: rgba(0, 0, 0, 0.5);
  }
}

.hover-scale {
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
}
</style>
