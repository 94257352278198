import { render, staticRenderFns } from "./CollectionDialogs.vue?vue&type=template&id=650e3b74&scoped=true&"
import script from "./CollectionDialogs.vue?vue&type=script&lang=js&"
export * from "./CollectionDialogs.vue?vue&type=script&lang=js&"
import style0 from "./CollectionDialogs.vue?vue&type=style&index=0&id=650e3b74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "650e3b74",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSpace,QBtn,QSeparator,QInput,QIcon,QCardActions,QField});qInstall(component, 'directives', {ClosePopup});
