<template>
  <div>
    <q-btn class="action-button action-button--primary" :loading="isRemoving" @click="removeBackground" flat no-caps>
      <template v-if="!isRemoving"> <q-icon name="content_cut" size="xs" class="button-icon" color="grey-7" /> Remove Background </template>
      <template v-slot:loading>
        <q-spinner color="grey-7" class="on-left" />
        <span v-if="operationStatus === 'preparing'">AI At Work… <q-icon name="fas fa-magic" size="xs" color="grey-7" /> </span>
        <span v-else-if="operationStatus === 'starting'">Working Our Magic! <q-icon name="fas fa-hat-wizard" size="xs" color="grey-7" /> </span>
        <span v-else-if="operationStatus === 'processing'">Almost Ready... <q-icon name="fas fa-hourglass-half" size="xs" color="grey-7" /></span>
      </template>
    </q-btn>
  </div>
</template>

<script>
export default {
  name: 'BackgroundRemovalControls',
  props: {
    isRemoving: {
      type: Boolean,
      required: true
    },
    operationStatus: {
      type: String,
      default: null
    }
  },
  methods: {
    removeBackground() {
      this.$emit('removeBackground');
    }
  }
};
</script>

<style lang="scss" scoped>
.action-button {
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  @include mona-sans-font($size: 15px, $bolded: true, $color: var(--main-text-color));
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &--primary {
    background: #fecb2f;
    color: #333333;

    &:hover {
      background: #fed95f;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button-icon {
  margin-right: 8px;
  font-size: 16px;
}
</style>
