import { render, staticRenderFns } from "./UploadImagesDialog.vue?vue&type=template&id=86329d2a&scoped=true&"
import script from "./UploadImagesDialog.vue?vue&type=script&lang=js&"
export * from "./UploadImagesDialog.vue?vue&type=script&lang=js&"
import style0 from "./UploadImagesDialog.vue?vue&type=style&index=0&id=86329d2a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86329d2a",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSpace,QBtn,QSeparator,QCardActions,QSpinnerDots});qInstall(component, 'directives', {ClosePopup});
