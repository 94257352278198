<template>
  <div class="container">
    <div class="content-container">
      <div class="header-section">
        <div class="title-container">
          <p class="header-title">Share across platforms</p>
          <span class="ai-badge">AI POWERED</span>
        </div>
        <p class="header-description">Share your content across multiple Instagram accounts and Facebook pages at once. Save time with our simple sharing solution.</p>
      </div>
      <BusinessAssets
        :meta-integration="metaIntegration"
        :selected-accounts="selectedAccounts"
        @update:selected-accounts="updateSelectedAccounts"
        :format-number="formatNumber"
        :is-account-selected="isAccountSelected"
        :toggle-account="toggleAccount"
      />
      <PostContent
        :content="rawPostContent"
        :hashtags="hashtags"
        :new-hashtag="newHashtag"
        :has-error="hasError"
        :error-message="errorMessage"
        :max-length="maxLength"
        @update:content="updateContent"
        @update:hashtags="updateHashtags"
        @update:new-hashtag="updateNewHashtag"
        @validate="validateContent"
        @add-hashtag="addHashtag"
        @remove-hashtag="removeHashtag"
        @improve-caption="improveCaption"
        :isImprovingCaption="isImprovingCaption"
        @generate-hashtags="generateHashtags"
        :isGeneratingHashtags="isGeneratingHashtags"
      />
      <div class="actions-container">
        <q-btn flat color="grey-7" no-caps label="Cancel" class="q-mr-sm" @click="showCancelDialog = true" />
        <q-btn unelevated color="primary" text-color="grey-7" no-caps :loading="isSharing" :disable="!isValid" label="Publish" @click="showShareDialog = true" />
      </div>
    </div>
    <PreviewSection
      :preview-type="previewType"
      @update:preview-type="updatePreviewType"
      :content="finalContent"
      :preview-url="previewUrl"
      :placeholder-url="placeholderUrl"
      :image-ratio="imageRatio"
      :selected-accounts="selectedAccounts"
      :formatted-date-time="formattedDateTime"
      :selected-page-name="selectedPageName"
      :selected-page-avatar="selectedPageAvatar"
    />

    <!-- Cancel Confirmation Dialog -->
    <q-dialog v-model="showCancelDialog" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Cancel post</div>
        </q-card-section>
        <q-card-section> Are you sure you want to cancel? Your changes will be lost. </q-card-section>
        <q-card-actions align="right">
          <q-btn flat no-caps label="Keep editing" color="grey-7" v-close-popup />
          <q-btn no-caps label="Cancel" color="red-8" @click="handleCancel" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Share Confirmation Dialog -->
    <q-dialog v-model="showShareDialog" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Share post</div>
        </q-card-section>
        <q-card-section> Ready to share your post to selected platforms? </q-card-section>
        <q-card-actions align="right">
          <q-btn flat no-caps label="Review" color="grey-7" v-close-popup />
          <q-btn no-caps label="Share" color="primary" @click="handleShare" :loading="isSharing" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Success Dialog -->
    <q-dialog v-model="showSuccessDialog" persistent>
      <q-card class="success-dialog">
        <q-card-section class="column items-center q-py-lg">
          <div class="success-icon">
            <q-icon name="check" size="32px" color="white" />
          </div>
          <div class="text-h6 q-mt-md">Success!</div>
          <p class="text-center q-mt-sm">Your post has been successfully shared.</p>
        </q-card-section>
        <q-card-actions align="center" style="padding: 24px">
          <q-btn no-caps label="Close" color="primary" textColor="grey-7" class="full-width" @click="closeSuccessDialog" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';
import BusinessAssets from './BusinessAssets.vue';
import PostContent from './PostContent.vue';
import PreviewSection from './PreviewSection.vue';
import { MetaApi } from '@/api/modules/meta';
import { ContentApi } from '@/api/modules/content'; // Import ContentApi

export default {
  name: 'MetaShareDialog',
  components: { BusinessAssets, PostContent, PreviewSection },
  emits: ['hide'],
  props: {
    metaIntegration: {
      type: Object,
      required: true
    },
    mediaUrl: {
      type: String,
      default: null
    },
    previewUrl: {
      type: String,
      default: null
    },
    placeholderUrl: {
      type: String,
      default: null
    },
    imageRatio: {
      type: Number,
      default: 1
    }
  },
  setup(props, { root, emit }) {
    const store = root.$store;
    const showCancelDialog = ref(false);
    const showShareDialog = ref(false);
    const showSuccessDialog = ref(false);

    function handleCancel() {
      console.log('Cancelling post...');
      emit('hide');
    }

    function closeSuccessDialog() {
      showSuccessDialog.value = false;
      emit('hide');
    }

    const rawPostContent = ref('');
    const selectedAccounts = ref([]);
    const isSharing = ref(false);
    const hasError = ref(false);
    const errorMessage = ref('');
    const maxLength = 2200;
    const previewType = ref('facebook');

    const hashtags = ref([]);
    const newHashtag = ref('');
    const isImprovingCaption = ref(false); // New ref for tracking improvement status
    const isGeneratingHashtags = ref(false); // New ref for tracking hashtag generation status

    // Computed final content
    const finalContent = computed(() => {
      const hashtagsStr = hashtags.value.length ? '\n\n' + hashtags.value.map(tag => `#${tag.replace(/^#/, '')}`).join(' ') : '';
      return `${rawPostContent.value}${hashtagsStr}`;
    });

    const postContent = computed({
      get: () => finalContent.value,
      set: val => (rawPostContent.value = val)
    });

    const isOverLimit = computed(() => finalContent.value.length > maxLength);

    const isValid = computed(() => {
      return finalContent.value.trim().length > 0 && finalContent.value.length <= maxLength && selectedAccounts.value.length > 0;
    });

    function validateContent() {
      if (finalContent.value.trim().length === 0) {
        hasError.value = true;
        errorMessage.value = 'Post content cannot be empty';
      } else if (finalContent.value.length > maxLength) {
        hasError.value = true;
        errorMessage.value = `Content exceeds maximum length of ${maxLength} characters`;
      } else {
        hasError.value = false;
        errorMessage.value = '';
      }
    }

    // Hashtag functions
    function addHashtag() {
      if (!newHashtag.value.trim()) return;
      const input = newHashtag.value.trim();
      const tags = input.split(/(?=#)/).map(tag => tag.trim());
      const result = [];

      tags.forEach(tag => {
        if (tag.startsWith('#')) {
          const cleanedTag = tag.replace(/^#/, '');
          if (cleanedTag) {
            result.push(cleanedTag);
          }
        } else {
          if (result.length > 0) {
            result[result.length - 1] += tag;
          } else {
            result.push(tag);
          }
        }
      });

      hashtags.value = result;
      newHashtag.value = '';
      validateContent();
    }

    function removeHashtag(tag) {
      hashtags.value = hashtags.value.filter(t => t !== tag);
      validateContent();
    }

    function isAccountSelected(id) {
      return selectedAccounts.value.some(selected => selected.id === id);
    }

    // Modified toggleAccount function
    function toggleAccount(account) {
      const index = selectedAccounts.value.findIndex(selected => selected.id === account.id);
      if (index === -1) {
        selectedAccounts.value.push(account);
      } else {
        selectedAccounts.value.splice(index, 1);
      }
    }

    function formatNumber(num) {
      return num >= 1e6 ? (num / 1e6).toFixed(1) + 'M' : num >= 1e3 ? (num / 1e3).toFixed(1) + 'K' : num.toString();
    }

    // New improveCaption method implementation
    async function improveCaption() {
      if (!rawPostContent.value.trim()) {
        return;
      }

      isImprovingCaption.value = true;

      try {
        const improvedCaption = await ContentApi.improveCaption({
          caption: rawPostContent.value
        });

        if (improvedCaption) {
          // Update the raw post content with the improved caption
          rawPostContent.value = improvedCaption;
          // Show success notification
          store.dispatch('notification/addSuccessNotification', 'Caption improved successfully!');
        }
      } catch (error) {
        console.error('Error improving caption:', error);
        store.dispatch('notification/addFailureNotification', 'Failed to improve caption');
      } finally {
        isImprovingCaption.value = false;
        validateContent();
      }
    }

    // Generate hashtags method implementation
    async function generateHashtags() {
      if (!rawPostContent.value.trim()) {
        return;
      }

      isGeneratingHashtags.value = true;

      try {
        const generatedHashtags = await ContentApi.generateHashtags({
          caption: rawPostContent.value
        });

        if (generatedHashtags && Array.isArray(generatedHashtags)) {
          // Update the hashtags array with the generated ones
          // Filter out any duplicates that might already exist in the array
          const newTags = generatedHashtags.filter(tag => !hashtags.value.includes(tag.replace(/^#/, ''))).map(tag => tag.replace(/^#/, ''));

          hashtags.value = [...hashtags.value, ...newTags];

          // Show success notification
          store.dispatch('notification/addSuccessNotification', 'Hashtags generated successfully!');
        } else {
          store.dispatch('notification/addWarningNotification', 'No hashtags could be generated for this content');
        }
      } catch (error) {
        console.error('Error generating hashtags:', error);
        store.dispatch('notification/addFailureNotification', 'Failed to generate hashtags');
      } finally {
        isGeneratingHashtags.value = false;
        validateContent();
      }
    }

    async function handleShare() {
      if (!isValid.value) return;
      isSharing.value = true;
      try {
        // Filter Facebook pages and Instagram accounts
        const facebookPages = selectedAccounts.value.filter(account => !account.username);
        const instagramAccounts = selectedAccounts.value.filter(account => account.username);

        // Create arrays of promises for parallel execution
        const facebookPromises = facebookPages.map(page =>
          MetaApi.createFacebookPost(page.id, {
            imageUrl: props.previewUrl,
            message: finalContent.value
          }).catch(error => {
            console.error(`Error posting to Facebook page ${page.name}:`, error);
            store.dispatch('notification/addFailureNotification', `Failed to post to ${page.name}`);
            return Promise.reject(error); // Re-throw to be caught by Promise.all
          })
        );

        const instagramPromises = instagramAccounts.map(account =>
          MetaApi.createInstagramPost(account.instagramBusinessId, {
            imageUrl: props.previewUrl,
            message: finalContent.value
          }).catch(error => {
            console.error(`Error posting to Instagram ${account.username}:`, error);
            store.dispatch('notification/addFailureNotification', `Failed to post to ${account.username}`);
            return Promise.reject(error); // Re-throw to be caught by Promise.all
          })
        );

        // Execute all sharing operations in parallel
        await Promise.all([...facebookPromises, ...instagramPromises]);

        // Show success dialog instead of individual notifications
        showSuccessDialog.value = true;
      } catch (error) {
        console.error('Error in share process:', error);
        store.dispatch('notification/addFailureNotification', 'An error occurred while sharing');
        emit('hide'); // Close the share dialog on error
      } finally {
        isSharing.value = false;
        showShareDialog.value = false; // Close the share confirmation dialog
      }
    }

    // Watchers
    watch([rawPostContent, hashtags], () => {
      validateContent();
    });

    const formattedDateTime = computed(() => {
      const now = new Date();
      return (
        now.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric'
        }) +
        ' at ' +
        now.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        })
      );
    });

    const selectedPageName = computed(() => {
      if (previewType.value === 'instagram') {
        const selectedInstagram = selectedAccounts.value.find(account => account.username);
        return selectedInstagram?.username || 'Instagram Account';
      } else {
        const selectedPage = selectedAccounts.value.find(account => !account.username);
        return selectedPage?.name || 'Facebook Page';
      }
    });

    const selectedPageAvatar = computed(() => {
      if (previewType.value === 'instagram') {
        const selectedInstagram = selectedAccounts.value.find(account => account.username);
        return selectedInstagram?.profilePic || null;
      } else {
        // Facebook pages don't have profile pictures in the integration data
        return null;
      }
    });

    // Add the missing update methods
    function updateSelectedAccounts(value) {
      selectedAccounts.value = value;
    }

    function updateContent(value) {
      rawPostContent.value = value;
    }

    function updateHashtags(value) {
      hashtags.value = value;
    }

    function updateNewHashtag(value) {
      newHashtag.value = value;
    }

    function updatePreviewType(value) {
      previewType.value = value;
    }

    return {
      showCancelDialog,
      showShareDialog,
      handleCancel,
      rawPostContent,
      postContent,
      selectedAccounts,
      isSharing,
      isValid,
      isAccountSelected,
      toggleAccount,
      handleShare,
      formatNumber,
      hasError,
      errorMessage,
      isOverLimit,
      validateContent,
      hashtags,
      newHashtag,
      addHashtag,
      removeHashtag,
      finalContent,
      maxLength,
      previewType,
      formattedDateTime,
      selectedPageAvatar,
      selectedPageName,
      // Add the caption improvement methods to the return object
      improveCaption,
      isImprovingCaption,
      generateHashtags,
      isGeneratingHashtags,
      // Other update methods
      updateSelectedAccounts,
      updateContent,
      updateHashtags,
      updateNewHashtag,
      updatePreviewType,
      closeSuccessDialog,
      showSuccessDialog
    };
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 24px !important;
  background-color: white;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 24px;

  :deep(.q-field) {
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .q-field__control {
      height: 44px;
      border-radius: 8px;
      background-color: white;

      &:before {
        border: 1px solid var(--separator-color, #e5e7eb);
        border-radius: 8px;
      }

      &:hover:before {
        border-color: var(--primary-color, #6366f1);
      }
    }

    &.q-field--focused .q-field__control:before {
      border-color: var(--primary-color, #6366f1);
    }
  }

  :deep(.q-card) {
    border-radius: 8px;
    border: 1px solid var(--separator-color, #e5e7eb);
    transition: all 0.2s ease;

    &:hover {
      border-color: var(--primary-color, #6366f1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &.selected {
      border-color: var(--primary-color, #6366f1);
      background-color: rgba(99, 102, 241, 0.05);
    }
  }

  .header-section {
    margin-bottom: 18px;
    padding: 12px;
    border-radius: 4px;
    background: linear-gradient(to right, rgba(124, 58, 237, 0.15), /* Light purple */ rgba(59, 130, 246, 0.15) /* Light blue */);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);

    .header-title {
      font-size: 28px;
      font-weight: 600;
      color: #151515;
      margin: 0;
      padding: 0;
    }

    .header-description {
      font-size: 16px;
      line-height: 1.5;
      color: var(--secondary-text-color, #666666);
      margin: 0;
      max-width: 580px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .actions-container {
    margin-top: auto;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--separator-color);
  }

  .custom-dialog {
    width: 400px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);

    .dialog-header {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      padding: 24px;

      .dialog-title-container {
        flex: 1;

        .text-h6 {
          margin: 0;
          color: var(--primary-text-color);
        }

        .text-body1 {
          margin: 0;
          color: var(--secondary-text-color);
          line-height: 1.5;
        }
      }
    }

    .q-card-actions {
      padding: 0;
      margin-top: 8px;

      .q-btn {
        padding: 10px;
        font-weight: 500;
      }
    }
  }
  .title-container {
    display: flex;
    align-items: center;
  }

  .ai-badge {
    font-family: 'Courier New', monospace;
    font-size: 10px;
    font-weight: 500;
    padding: 4px 6px;
    border-radius: 4px;
    background: linear-gradient(135deg, #8b5cf6 0%, #a78bfa 100%);
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 4px rgba(99, 102, 241, 0.2);
    margin-left: 10px;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}
.success-dialog {
  width: 400px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);

  .success-icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #4caf50;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
}
</style>
