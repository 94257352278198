var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"section-label"},[_vm._v("Connected business assets")]),_c('q-select',{staticClass:"business-assets-select",attrs:{"outlined":"","options":_vm.groupedAccounts,"label":"Select business assets","multiple":"","use-chips":"","stack-label":"","option-value":"id","option-label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var itemProps = ref.itemProps;
var opt = ref.opt;
var selected = ref.selected;
var toggleOption = ref.toggleOption;
return [(opt.header)?[_c('q-item-label',{staticClass:"platform-header",attrs:{"header":""}},[_vm._v(" "+_vm._s(opt.header)+" ("+_vm._s(opt.count)+") ")])]:[_c('q-item',_vm._b({class:{ unpublished: !opt.isPublished },attrs:{"clickable":""},on:{"click":function($event){return toggleOption(opt)}}},'q-item',itemProps,false),[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-avatar',{attrs:{"size":"32px"}},[_c('q-icon',{class:opt.platform === 'instagram' ? 'instagram-icon' : 'facebook-icon',attrs:{"name":opt.platform === 'instagram' ? 'fab fa-instagram' : 'fab fa-facebook',"size":"24px"}})],1)],1),_c('q-item-section',[_c('q-item-label',{staticClass:"account-name"},[_vm._v(" "+_vm._s(opt.name || opt.username)+" ")]),_c('q-item-label',{staticClass:"account-category",attrs:{"caption":""}},[_vm._v(" "+_vm._s(opt.category || opt.connectedPageName || 'Business')+" ")]),_c('div',{staticClass:"account-stats"},[(opt.isPrivate)?_c('q-chip',{attrs:{"size":"xs","color":"warning"}},[_vm._v("Private")]):_vm._e(),(!opt.isPublished)?_c('q-chip',{attrs:{"size":"xs"}},[_vm._v("Unpublished")]):_vm._e()],1)],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-checkbox',{attrs:{"value":selected,"color":"primary"}})],1)],1)]]}},{key:"no-option",fn:function(){return [_c('q-item',[_c('q-item-section',{staticClass:"text-grey"},[_vm._v(" No business assets available ")])],1)]},proxy:true},{key:"selected-item",fn:function(ref){
var opt = ref.opt;
var removeAtIndex = ref.removeAtIndex;
return [(!opt.header)?_c('q-chip',{staticClass:"selected-account-chip",attrs:{"icon":opt.platform === 'instagram' ? 'fab fa-instagram' : 'fab fa-facebook',"removable":"","color":"primary","text-color":"grey-7"},on:{"remove":function () { return _vm.handleRemoveChip(opt, removeAtIndex); }}},[_vm._v(" "+_vm._s(opt.name || opt.username)+" ")]):_vm._e()]}}]),model:{value:(_vm.localSelectedAccounts),callback:function ($$v) {_vm.localSelectedAccounts=$$v},expression:"localSelectedAccounts"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }