<script>
import { ReplicateApi } from '@api/index';
import ImageEditorTopBar from './editor/ImageEditorTopBar.vue';
import ImageEditorSidebar from './editor/ImageEditorSidebar.vue';
import ImageEditorCanvas from './editor/ImageEditorCanvas.vue';
import Compressor from 'compressorjs';
import axios from 'axios';

export default {
  name: 'ImageEditor',
  components: {
    ImageEditorTopBar,
    ImageEditorSidebar,
    ImageEditorCanvas
  },
  props: {
    image: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      brushSize: 75,
      brushColor: '#8800FF',
      prompt: '',
      editingHistory: [],
      currentHistoryIndex: -1,
      isGenerating: false,
      isRemoving: false,
      isOutpainting: false,
      operationStatus: null,
      originalImageUrl: null,
      showHistoryPanel: false,
      activeTool: '',
      hasMaskData: false,
      isGeneratingBackground: false
    };
  },
  mounted() {
    if (this.image && this.image.optimized_url) {
      this.originalImageUrl = this.image.optimized_url; // keep track of the original image url
      // Initialize editing history with original image
      this.editingHistory = [{ url: this.image.optimized_url, placeholder_url: this.image.preview_url, type: 'original' }];
      this.currentHistoryIndex = 0;
    }
    // Add event listener for Escape key
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    // Remove event listener for cleanup
    document.removeEventListener('keydown', this.handleKeydown);

    // Clear the editImage query parameter while preserving other query parameters
    const query = { ...this.$router.currentRoute.query };
    delete query.editImage;
    this.$router.replace({ query });

    // Reset all data properties to their initial state
    this.brushSize = 75;
    this.brushColor = '#8800FF';
    this.prompt = '';
    this.editingHistory = [];
    this.currentHistoryIndex = -1;
    this.isGenerating = false;
    this.isRemoving = false;
    this.isOutpainting = false;
    this.operationStatus = 'preparing';
    this.image.optimized_url = this.originalImageUrl; // revert back to the original image url
    this.showHistoryPanel = false;
    this.activeTool = '';
    this.hasMaskData = false;
    this.isGeneratingBackground = false;

    // Clear canvas if it exists
    if (this.$refs.canvas) {
      this.$refs.canvas.clearCanvas();
    }
  },
  methods: {
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.$emit('update:isOpen', false);
      }
    },
    clearMask() {
      if (this.$refs.canvas) {
        this.$refs.canvas.clearCanvas();
        this.hasMaskData = false;
      }
    },

    async optimizeImage(blob, referenceWidth = null, referenceHeight = null) {
      try {
        console.log(`Optimizing ${referenceHeight ? 'mask:' : 'image:'}`, blob);
        // Load image to get dimensions
        const img = new Image();
        await new Promise(resolve => {
          img.onload = resolve;
          img.src = URL.createObjectURL(blob);
        });

        // Define max resolution (1440p)
        const MAX_HEIGHT = 1440;
        const MAX_WIDTH = 2560;

        // Use reference dimensions if provided, otherwise calculate from constraints
        let targetWidth, targetHeight;

        if (referenceWidth && referenceHeight) {
          targetWidth = referenceWidth;
          targetHeight = referenceHeight;
        } else {
          const aspectRatio = img.width / img.height;
          targetWidth = img.width;
          targetHeight = img.height;

          // Check if image exceeds 1440p resolution
          if (img.height > MAX_HEIGHT || img.width > MAX_WIDTH) {
            if (aspectRatio > 1) {
              // Landscape orientation
              targetWidth = Math.min(MAX_WIDTH, img.width);
              targetHeight = Math.round(targetWidth / aspectRatio);
            } else {
              // Portrait orientation
              targetHeight = Math.min(MAX_HEIGHT, img.height);
              targetWidth = Math.round(targetHeight * aspectRatio);
            }
          }
        }

        // Ensure dimensions are integers
        targetWidth = Math.floor(targetWidth);
        targetHeight = Math.floor(targetHeight);

        // Compress image using compressor.js
        return new Promise((resolve, reject) => {
          new Compressor(blob, {
            width: targetWidth,
            height: targetHeight,
            resize: 'cover',
            strict: true, // Ensure output matches target dimensions exactly
            success: result => {
              resolve(result);
            },
            error: reject
          });
        });
      } catch (error) {
        console.error('Error optimizing image:', error);
        throw error;
      }
    },

    async removeObjects() {
      try {
        this.isRemoving = true;
        this.operationStatus = 'preparing';
        // Extract the mask blob from the canvas
        const mask = await this.$refs.canvas.extractMask();
        this.$refs.canvas.clearCanvas();

        // Download the image & get the blob
        const response = await axios.get(this.image.optimized_url, {
          responseType: 'blob'
        });
        const imageBlob = response.data;
        const image = new Blob([imageBlob], { type: this.currentHistoryIndex === 0 ? 'image/webp' : 'image/jpeg' });

        const result = await ReplicateApi.removeObjects({ imageId: this.image.id, image, mask });

        if (result.data && result.data.prediction_id) {
          await this.pollPredictionStatus(result.data.prediction_id);
        } else {
          throw new Error('No prediction ID in the response');
        }
      } catch (error) {
        console.error('Error in removeObjects:', error);
      } finally {
        this.isRemoving = false;
      }
    },
    async generateObjects() {
      if (!this.prompt) {
        console.warn('Please provide a prompt for generation');
        return;
      }

      try {
        this.isGenerating = true;
        this.operationStatus = 'preparing';
        // Extract the mask blob from the canvas
        const mask = await this.$refs.canvas.extractMask();
        this.$refs.canvas.clearCanvas();

        // Download the image & get the blob
        const response = await axios.get(this.image.optimized_url, {
          responseType: 'blob'
        });
        const imageBlob = response.data;
        const image = new Blob([imageBlob], { type: this.currentHistoryIndex === 0 ? 'image/webp' : 'image/jpeg' });

        // Call ReplicateApi
        const result = await ReplicateApi.generateObjects({
          imageId: this.image.id,
          image,
          mask,
          prompt: this.prompt
        });

        if (result.data && result.data.prediction_id) {
          await this.pollPredictionStatus(result.data.prediction_id);
        } else {
          throw new Error('No prediction ID in the response');
        }
      } catch (error) {
        console.error('Error in generative fill:', error);
      } finally {
        this.isGenerating = false;
      }
    },
    async outpaint(direction) {
      console.log('Outpainting with direction:', direction);
      try {
        this.isOutpainting = true;
        this.operationStatus = 'preparing';
        // Extract the mask blob from the canvas
        this.$refs.canvas.clearCanvas();

        // Download the image & get the blob
        const response = await axios.get(this.image.optimized_url, {
          responseType: 'blob'
        });
        const imageBlob = response.data;
        const image = new Blob([imageBlob], { type: this.currentHistoryIndex === 0 ? 'image/webp' : 'image/jpeg' });

        // Call ReplicateApi with direction
        const result = await ReplicateApi.outpaint({
          imageId: this.image.id,
          image,
          outpaint: direction,
          prompt: this.prompt
        });

        if (result.data && result.data.prediction_id) {
          await this.pollPredictionStatus(result.data.prediction_id);
        } else {
          throw new Error('No prediction ID in the response');
        }
      } catch (error) {
        console.error('Error in outpainting:', error);
      } finally {
        this.isOutpainting = false;
      }
    },
    async removeBackground() {
      try {
        this.isRemoving = true;
        this.operationStatus = 'preparing';

        // Download the image & get the blob
        const response = await axios.get(this.image.optimized_url, {
          responseType: 'blob'
        });
        const imageBlob = response.data;
        const image = new Blob([imageBlob], { type: 'image/webp' });

        // Call ReplicateApi
        const result = await ReplicateApi.removeBackground({
          imageId: this.image.id,
          image
        });

        if (result.data && result.data.prediction_id) {
          await this.pollPredictionStatus(result.data.prediction_id);
        } else {
          throw new Error('No prediction ID in the response');
        }
      } catch (error) {
        console.error('Error in background removal:', error);
      } finally {
        this.isRemoving = false;
      }
    },

    async pollPredictionStatus(predictionId) {
      const pollInterval = 2000;
      const maxAttempts = 100;
      let attempts = 0;

      while (attempts < maxAttempts) {
        try {
          const result = await ReplicateApi.getPredictionStatus(this.image.id, predictionId);
          const { prediction } = result.data;

          this.operationStatus = prediction.status;

          if (prediction.output) {
            // Handle both single image and array of images
            const outputs = Array.isArray(prediction.output) ? prediction.output : [prediction.output];

            // Add all generated images to history with additional metadata
            outputs.forEach((output, index) => {
              this.editingHistory.push({
                url: output,
                placeholder_url: this.image.preview_url,
                type: 'edited',
                timestamp: Date.now(),
                isVariant: outputs.length > 1,
                variantIndex: index + 1,
                metrics: prediction.metrics,
                created_at: prediction.created_at,
                completed_at: prediction.completed_at
              });
            });

            // Update current history index to point to the last generated image
            this.currentHistoryIndex = this.editingHistory.length - 1;
            // Display the last generated image
            this.image.optimized_url = outputs[outputs.length - 1];
            this.operationStatus = null;
            return;
          }

          attempts++;
          await new Promise(resolve => setTimeout(resolve, pollInterval));
        } catch (error) {
          this.operationStatus = error.message;
          throw error;
        }
      }

      this.operationStatus = 'Operation timed out';
      throw new Error('Operation timed out');
    },
    selectHistoryImage(index) {
      if (index >= 0 && index < this.editingHistory.length) {
        this.currentHistoryIndex = index;
        this.image.optimized_url = this.editingHistory[index].url;
      }
    },
    handleDiscard() {
      if (this.currentHistoryIndex > 0) {
        // Remove current image from history
        this.editingHistory.splice(this.currentHistoryIndex, 1);

        // Update current history index to point to previous image
        this.currentHistoryIndex = this.currentHistoryIndex - 1;

        // Restore previous image
        this.image.optimized_url = this.editingHistory[this.currentHistoryIndex].url;

        // Reinitialize canvas with previous image

        this.$refs.canvas.initializeCanvas();
      }
    },
    toggleHistoryPanel() {
      this.showHistoryPanel = !this.showHistoryPanel;
    },
    async generateBackground() {
      try {
        this.isGeneratingBackground = true;
        this.operationStatus = 'preparing';

        // Download the image & get the blob
        const response = await axios.get(this.image.optimized_url, {
          responseType: 'blob'
        });
        const imageBlob = response.data;
        const image = new Blob([imageBlob], { type: this.currentHistoryIndex === 0 ? 'image/webp' : 'image/jpeg' });

        // Call ReplicateApi
        const result = await ReplicateApi.generateBackground({
          imageId: this.image.id,
          image
        });

        if (result.data && result.data.prediction_id) {
          await this.pollPredictionStatus(result.data.prediction_id);
        } else {
          throw new Error('No prediction ID in the response');
        }
      } catch (error) {
        console.error('Error in background generation:', error);
        this.$store.dispatch('notification/addFailureNotification', 'Failed to generate background: ' + error.message);
      } finally {
        this.isGeneratingBackground = false;
      }
    }
  }
};
</script>

<template>
  <q-dialog v-model="isOpen" persistent maximized transition-show="slide-up" transition-hide="slide-down">
    <ImageEditorTopBar
      :is-generated-image="currentHistoryIndex > 0"
      :image="image"
      @discard="handleDiscard"
      @update:isOpen="$emit('update:isOpen', $event)"
      :show-history-panel="showHistoryPanel"
      @toggle-history-panel="toggleHistoryPanel"
    />
    <q-card class="editor-container">
      <ImageEditorSidebar
        :brush-size.sync="brushSize"
        :prompt.sync="prompt"
        :is-removing="isRemoving"
        :is-generating="isGenerating"
        :is-outpainting="isOutpainting"
        :is-generating-background="isGeneratingBackground"
        :operation-status="operationStatus"
        :has-mask-data="hasMaskData"
        @update:activeTool="activeTool = $event"
        @clearMask="clearMask"
        @removeObjects="removeObjects"
        @removeBackground="removeBackground"
        @generateFill="generateObjects"
        @outpaint="outpaint"
        @generateBackground="generateBackground"
      />
      <div class="right-panel">
        <ImageEditorCanvas
          ref="canvas"
          :image="image"
          :brush-size="brushSize"
          :brush-color="brushColor"
          :show-history-panel="showHistoryPanel"
          :active-tool="activeTool"
          @update:hasMaskData="hasMaskData = $event"
        />

        <!-- History Panel at the bottom of right panel -->
        <div v-if="showHistoryPanel" class="history-panel">
          <div class="history-panel-header">
            <div class="header-title">
              <q-icon name="history" size="24px" class="history-icon" />
              <label class="input-caption">Generation History</label>
            </div>
            <q-btn flat round dense icon="close" @click="toggleHistoryPanel" class="close-history-btn" />
          </div>
          <div class="history-list">
            <div v-for="(item, index) in editingHistory" :key="index" class="history-item" :class="{ active: index === currentHistoryIndex }" @click="selectHistoryImage(index)">
              <q-img :src="item.url" :placeholder-src="item.placeholder_url" class="image-preview" fit="cover" />
              <div class="history-item-name">{{ item.type === 'original' ? 'Original' : 'Edit ' + index }}</div>
              <div v-if="item.type !== 'original'" class="history-item-time">
                {{ new Date(item.timestamp || Date.now()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<style lang="scss" scoped>
.editor-container {
  background: #f8f9fa;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 500px 1fr;
  padding: 24px;
  gap: 24px;
  background: linear-gradient(to bottom right, #f8f9fa, #ffffff);
}

.right-panel {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 48px - 75px);
}

.sidebar {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  height: calc(100vh - 48px - 75px) !important;
  overflow-y: auto;
  margin-right: 24px; /* Add spacing between sidebar and right panel */
}

.image-container {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview {
  height: 150px;
  max-width: 180px;
}

.brush-canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0.6;
  cursor: crosshair;
  pointer-events: all;
}

.history-panel {
  background: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  z-index: 10;
}

.history-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}

.header-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.history-icon {
  color: #fecb30;
}

.close-history-btn {
  padding: 4px;
  font-size: 18px;
  color: #333333;

  &:hover {
    color: #333;
    background: rgba(0, 0, 0, 0.05);
  }
}

.history-list {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  padding-bottom: 8px;
}

.history-item {
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid transparent;
  width: 180px;
  height: 150px;
  position: relative;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &.active {
    border-color: #fecb30;
  }

  .history-item-name {
    position: absolute;
    bottom: 8px;
    left: 8px;
    background: var(--main-btn-color);
    padding: 2px 8px;
    border-radius: 4px;
    @include inter-font($size: 12px, $bolded: true, $color: var(--main-text-color));
    z-index: 2;
  }

  .history-item-time {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(255, 255, 255, 0.9);
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    z-index: 2;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #fecb30;
  }
}

.input-caption {
  @include mona-sans-font($size: 18px, $bolded: true, $color: var(--main-text-color));
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  height: 75px;
  background-color: white;

  .logo-container {
    display: flex;
    align-items: center;
    gap: 0;
  }

  .logo {
    height: 32px;
    width: auto;
    object-fit: contain;
  }

  .edits-text {
    font-size: 24px;
    font-weight: bold;
    background: linear-gradient(90deg, #8700ff, #00c2ff, #8700ff);
    background-size: 200% auto;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: gradient 3s linear infinite;
    transition: transform 0.3s ease;
    margin: 0;

    &:hover {
      transform: scale(1.1);
    }
  }

  .close-btn {
    width: 40px;
    height: 40px;
    color: #151515;
    transition: all 0.2s ease;

    &:hover {
      background: #f0e6ff;
      color: #8700ff;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% center;
    }
    100% {
      background-position: 200% center;
    }
  }
}
</style>
