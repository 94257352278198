<template>
  <div>
    <div class="control-header">
      <label class="input-caption">Expansion Type</label>
    </div>
    <div class="expansion-options">
      <q-btn class="expansion-type-button" @click="selectMode('Zoom out 1.5x')" :class="{ active: selectedMode === 'Zoom out 1.5x' }" flat no-caps align="left">
        <div class="expansion-button-content">
          <div class="expansion-button-header">
            <q-icon name="zoom_out" size="sm" color="secondary" class="expansion-icon" />
            <span class="expansion-title">Expand 1.5x</span>
          </div>
          <div class="expansion-description">Expand image slightly (1.5x zoom out)</div>
        </div>
      </q-btn>

      <q-btn class="expansion-type-button" @click="selectMode('Zoom out 2x')" :class="{ active: selectedMode === 'Zoom out 2x' }" flat no-caps align="left">
        <div class="expansion-button-content">
          <div class="expansion-button-header">
            <q-icon name="zoom_out" size="sm" color="secondary" class="expansion-icon" />
            <span class="expansion-title">Expand 2x</span>
          </div>
          <div class="expansion-description">Expand image significantly (2x zoom out)</div>
        </div>
      </q-btn>

      <q-btn class="expansion-type-button" @click="selectMode('Make square')" :class="{ active: selectedMode === 'Make square' }" flat no-caps align="left">
        <div class="expansion-button-content">
          <div class="expansion-button-header">
            <q-icon name="crop_square" size="sm" color="secondary" class="expansion-icon" />
            <span class="expansion-title">Expand to Square</span>
          </div>
          <div class="expansion-description">Expand the image to become a square</div>
        </div>
      </q-btn>
    </div>

    <div class="control-header">
      <label class="input-caption">Expansion Direction</label>
    </div>
    <div class="direction-grid">
      <q-btn class="direction-button" @click="selectMode('Top outpaint')" :class="{ active: selectedMode === 'Top outpaint' }" flat no-caps>
        <div class="direction-content">
          <q-icon name="arrow_upward" size="sm" color="secondary" />
          <div class="direction-label">Up</div>
        </div>
      </q-btn>
      <q-btn class="direction-button" @click="selectMode('Right outpaint')" :class="{ active: selectedMode === 'Right outpaint' }" flat no-caps>
        <div class="direction-content">
          <q-icon name="arrow_forward" size="sm" color="secondary" />
          <div class="direction-label">Right</div>
        </div>
      </q-btn>
      <q-btn class="direction-button" @click="selectMode('Bottom outpaint')" :class="{ active: selectedMode === 'Bottom outpaint' }" flat no-caps>
        <div class="direction-content">
          <q-icon name="arrow_downward" size="sm" color="secondary" />
          <div class="direction-label">Down</div>
        </div>
      </q-btn>
      <q-btn class="direction-button" @click="selectMode('Left outpaint')" :class="{ active: selectedMode === 'Left outpaint' }" flat no-caps>
        <div class="direction-content">
          <q-icon name="arrow_back" size="sm" color="secondary" />
          <div class="direction-label">Left</div>
        </div>
      </q-btn>
    </div>

    <div class="control-header">
      <label class="input-caption">Describe The Extended Area</label>
    </div>
    <q-input v-model="localPrompt" outlined autogrow type="textarea" placeholder="Describe what you want in the expanded area" @input="updatePrompt" class="expansion-prompt" />

    <q-btn class="action-button action-button--primary mt-3" :loading="isOutpainting" :disable="!isFormValid" @click="applyOutpainting" flat no-caps>
      <template v-if="!isOutpainting"> <q-icon name="auto_awesome" size="xs" class="button-icon" color="grey-7" /> Apply Expansion </template>
      <template v-slot:loading>
        <q-spinner color="grey-7" class="on-left" />
        <span v-if="operationStatus === 'preparing'">AI At Work… <q-icon name="fas fa-magic" size="xs" color="grey-7" /> </span>
        <span v-else-if="operationStatus === 'starting'">Working Our Magic! <q-icon name="fas fa-hat-wizard" size="xs" color="grey-7" /> </span>
        <span v-else-if="operationStatus === 'processing'">Almost Ready... <q-icon name="fas fa-hourglass-half" size="xs" color="grey-7" /></span>
      </template>
    </q-btn>

    <div v-if="!isFormValid" class="usage-hint">
      <q-icon name="info_outline" size="sm" color="grey-7" class="info-icon" />
      <p>{{ validationMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutpaintingControls',
  props: {
    isOutpainting: {
      type: Boolean,
      required: true
    },
    operationStatus: {
      type: String,
      default: null
    },
    selectedMode: {
      type: String,
      default: ''
    },
    prompt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localSelectedMode: this.selectedMode,
      localPrompt: this.prompt
    };
  },
  computed: {
    isFormValid() {
      return this.localSelectedMode && this.localPrompt.trim().length >= 3;
    },
    validationMessage() {
      if (!this.localSelectedMode) {
        return 'Please select an expansion direction';
      }
      if (!this.localPrompt.trim()) {
        return 'Please describe what you want in the expanded area';
      }
      if (this.localPrompt.trim().length < 3) {
        return 'Your description should be at least 3 characters';
      }
      return '';
    }
  },
  watch: {
    selectedMode(newVal) {
      this.localSelectedMode = newVal;
    },
    prompt(newVal) {
      this.localPrompt = newVal;
    }
  },
  methods: {
    selectMode(mode) {
      this.$emit('update:selected-mode', mode);
    },
    updatePrompt() {
      this.$emit('update:prompt', this.localPrompt);
    },
    applyOutpainting() {
      if (this.isFormValid) {
        this.$emit('outpaint', this.localSelectedMode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.control-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.input-caption {
  @include mona-sans-font($size: 15px, $bolded: true, $color: var(--main-text-color));
  margin-bottom: 12px;
  display: block;
}

/* Expansion Type Styles */
.expansion-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.expansion-type-button {
  background: #f3f4f6;
  border-radius: 8px;
  padding: 6px;
  transition: all 0.2s ease;
  text-align: left;
  height: auto;

  &:hover {
    background: #e5e7eb;
  }

  &.active {
    background: linear-gradient(135deg, #ffffff, #fff5d9);
    border: 1px solid #fecb2f;
    box-shadow: 0 2px 4px rgba(254, 203, 47, 0.3);
  }
}

.expansion-button-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.expansion-button-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
  width: 100%;
}

.expansion-icon {
  margin-right: 10px;
}

.expansion-title {
  @include mona-sans-font($size: 16px, $bolded: true, $color: var(--main-text-color));
  text-align: left;
}

.expansion-description {
  @include inter-font($size: 13px, $bolded: false, $color: var(--secondary-text-color));
  text-align: left;
  align-self: flex-start;
}

/* Direction Grid Styles */
.direction-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px;
  margin-bottom: 20px;
}

.direction-button {
  height: 80px;
  border-radius: 10px;
  background: #f3f4f6;
  transition: all 0.2s ease;

  &:hover {
    background: #e5e7eb;
  }

  &.active {
    background: linear-gradient(135deg, #ffffff, #fff5d9);
    border: 1px solid #fecb2f;
    box-shadow: 0 2px 4px rgba(254, 203, 47, 0.3);
  }
}

.direction-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.direction-label {
  @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
  margin-top: 6px;
}

.expansion-prompt {
  margin-bottom: 12px;
}

.action-button {
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  @include mona-sans-font($size: 15px, $bolded: true, $color: var(--main-text-color));
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &--primary {
    background: #fecb2f;
    color: #333333;

    &:hover {
      background: #fed95f;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button-icon {
  margin-right: 8px;
  font-size: 16px;
}

.mt-3 {
  margin-top: 16px;
}

.usage-hint {
  margin-top: 16px;
  padding: 12px;
  background-color: #f9fafb;
  border-radius: 8px;
  display: flex;
  align-items: center;

  .info-icon {
    margin-right: 10px;
    flex-shrink: 0;
  }

  p {
    @include inter-font($size: 14px, $color: var(--secondary-text-color));
    margin: 0;
    line-height: 1.5;
  }
}
</style>
