import { render, staticRenderFns } from "./AddToCollectionDialog.vue?vue&type=template&id=08b613aa&scoped=true&"
import script from "./AddToCollectionDialog.vue?vue&type=script&lang=js&"
export * from "./AddToCollectionDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddToCollectionDialog.vue?vue&type=style&index=0&id=08b613aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b613aa",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSpace,QBtn,QSeparator,QIcon,QChip,QExpansionItem,QItemSection,QRadio,QSelect,QItem,QInput,QCardActions,QField});qInstall(component, 'directives', {ClosePopup});
