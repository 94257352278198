<template>
  <div class="gallery-container">
    <GallerySidebar
      :collections="collections"
      :collectionsLoading="collectionsQuery.isLoading.value"
      :selectedCollection="selectedCollection"
      :dateRange="dateRange"
      :selectedServices="selectedServices"
      :selectedAspectRatios="selectedAspectRatios"
      :metadataSearchQuery="metadataSearchQuery"
      :selectedClient="selectedClient"
      :clientOptions="filteredClientOptions"
      :serviceOptions="serviceOptions"
      :aspectRatioOptions="aspectRatioOptions"
      :hasActiveFilters="hasActiveFilters"
      :isLoadingClients="isLoadingClients"
      :userRole="user.role"
      @select-collection="selectCollection"
      @confirm-edit-collection="confirmEditCollection"
      @confirm-delete-collection="confirmDeleteCollection"
      @create-collection="showCreateCollectionDialog = true"
      @update:date-range="handleDateRangeChange"
      @update:services="handleServicesChange"
      @update:aspect-ratios="handleAspectRatioChange"
      @update:metadata-search="
        metadataSearchQuery = $event;
        debouncedHandleMetadataSearch();
      "
      @update:client="handleClientSelect"
      @filter-clients="filterFn"
      @clear-filters="clearFilters"
    />

    <div class="gallery-content" @scroll="handleScroll" ref="scrollContainer">
      <GalleryTopBar @search="handleVisualSearch" @open-upload="showUploadDialog = true" :uploadDialogOpen="showUploadDialog" />

      <!-- Initial Loading State -->
      <div v-if="imagesQuery.isLoading.value" class="row justify-center items-center full-height">
        <q-spinner-dots color="#151515" size="80px" />
      </div>

      <!-- Content -->
      <template v-else>
        <ImagesGrid
          v-if="allImages.length && currentPagination"
          :images="allImages"
          :pagination="currentPagination"
          :isMetaConnected="isMetaConnected"
          :metaIntegration="metaIntegration"
          :role="user.role"
          :selectedCollectionId="selectedCollection"
          @items-removed-from-collection="handleItemsRemovedFromCollection"
        />

        <!-- Empty State -->
        <div v-else-if="!imagesQuery.isFetching.value" class="row justify-center items-center full-height">
          <div class="text-subtitle1 text-grey">No Images Found</div>
        </div>

        <!-- Bottom Loading Spinner -->
        <div v-if="shouldShowLoadingSpinner" class="row justify-center q-my-md">
          <q-spinner-dots color="#151515" size="60px" />
        </div>
      </template>
    </div>

    <!-- Collection Dialogs -->
    <CollectionDialogs
      :showCreateDialog="showCreateCollectionDialog"
      :showEditDialog="showEditCollectionDialog"
      :showDeleteDialog="showDeleteCollectionDialog"
      :newCollectionName="newCollectionName"
      :editCollectionName="editCollectionName"
      :collectionToEdit="collectionToEdit"
      :collectionToDelete="collectionToDelete"
      :isCreating="isCreatingCollection"
      :isEditing="isEditingCollection"
      :isDeleting="isDeletingCollection"
      @update:show-create="showCreateCollectionDialog = $event"
      @update:show-edit="showEditCollectionDialog = $event"
      @update:show-delete="showDeleteCollectionDialog = $event"
      @update:new-name="newCollectionName = $event"
      @update:edit-name="editCollectionName = $event"
      @create="createCollection"
      @update="updateCollection"
      @delete="deleteCollection"
      @register:create-input="collectionNameInput = $event"
      @register:edit-input="editCollectionNameInput = $event"
    />

    <!-- Upload Images Dialog -->
    <UploadImagesDialog :show="showUploadDialog" @update:show="showUploadDialog = $event" @upload-success="handleUploadSuccess" @upload-error="handleUploadError" />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';
import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import debounce from 'lodash/debounce';
import { ContentApi, IntegrationsApi, UserApi } from '@api/index';
import { CollectionsApi } from '@api/modules/collections';
import ImagesGrid from '@components/ImagesGrid.vue';
import GalleryTopBar from '@components/GalleryTopBar.vue';
import GallerySidebar from '@components/gallery/GallerySidebar.vue';
import CollectionDialogs from '@components/gallery/CollectionDialogs.vue';
import UploadImagesDialog from '@components/gallery/UploadImagesDialog.vue';

export default {
  name: 'Gallery',
  components: {
    GalleryTopBar,
    GallerySidebar,
    ImagesGrid,
    CollectionDialogs,
    UploadImagesDialog
  },

  setup(props, { root }) {
    const store = root.$store;
    const scrollContainer = ref(null);

    // ==================== User Data ====================
    const user = computed(() => root.$store.getters['user/getUser']);

    // ==================== Filter States ====================
    const dateRange = ref({ from: null, to: null });
    const selectedServices = ref([]);
    const selectedAspectRatios = ref([]);
    const metadataSearchQuery = ref('');
    const visualSearchQuery = ref('');
    const debouncedVisualQuery = ref('');
    const selectedClient = ref(null);
    const allClients = ref([]);
    const clientOptions = ref([]);

    // Filter computed properties
    const services = computed(() => store.getters['services/getServices']);
    const serviceOptions = computed(
      () =>
        services.value &&
        services.value.map(service => ({
          label: service.name,
          value: service.id
        }))
    );
    const aspectRatioOptions = [
      { label: 'Square', value: 'square' },
      { label: 'Landscape', value: 'landscape' },
      { label: 'Portrait', value: 'portrait' }
    ];

    const filters = computed(() => ({
      date: {
        from: dateRange.value.from,
        to: dateRange.value.to
      },
      services: selectedServices.value,
      aspectRatios: selectedAspectRatios.value,
      metadataSearchQuery: typeof metadataSearchQuery.value === 'string' ? metadataSearchQuery.value.trim() : '',
      visualSearchQuery: typeof debouncedVisualQuery.value === 'string' ? debouncedVisualQuery.value.trim() : '',
      clientId: selectedClient.value?.value || null,
      collectionId: selectedCollection.value
    }));

    const hasActiveFilters = computed(() => {
      return (
        dateRange.value.from !== null ||
        dateRange.to !== null ||
        selectedServices.value.length > 0 ||
        selectedAspectRatios.value.length > 0 ||
        (typeof metadataSearchQuery.value === 'string' && metadataSearchQuery.value.trim() !== '') ||
        (typeof visualSearchQuery.value === 'string' && visualSearchQuery.value.trim() !== '') ||
        selectedCollection.value !== null
      );
    });

    // Filter handlers
    const handleDateRangeChange = range => {
      dateRange.value = range;
    };

    const handleServicesChange = services => {
      selectedServices.value = services;
    };

    const handleAspectRatioChange = ratios => {
      selectedAspectRatios.value = ratios;
    };

    const handleVisualSearch = query => {
      visualSearchQuery.value = query;
    };

    const clearFilters = () => {
      metadataSearchQuery.value = '';
      visualSearchQuery.value = '';
      dateRange.value = { from: null, to: null };
      selectedServices.value = [];
      selectedAspectRatios.value = [];
      selectedClient.value = null;
      selectedCollection.value = null;
    };

    const updateDebouncedVisual = debounce(value => {
      debouncedVisualQuery.value = value;
    }, 300);

    watch(visualSearchQuery, updateDebouncedVisual);

    const handleMetadataSearch = () => {
      imagesQuery.refetch();
    };

    const debouncedHandleMetadataSearch = debounce(handleMetadataSearch, 300);

    // ==================== Client Filtering ====================
    const { data: clientsData, isLoading: isLoadingClients } = useQuery({
      queryKey: ['clients'],
      queryFn: async () => {
        const response = await UserApi.searchUsers({
          payload: {
            filters: { role: 'client', statuses: ['active'] },
            fetchAll: true
          }
        });
        return response.data.users || [];
      },
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 60 * 8 // Cache for 8 hours
    });

    watch(clientsData, newVal => {
      if (newVal) {
        allClients.value = newVal;
        clientOptions.value = newVal.map(client => ({
          label: client.contact_email,
          value: client.client_id
        }));
      }
    });

    const filteredClientOptions = computed(() => clientOptions.value);

    const handleClientSelect = client => {
      selectedClient.value = client;
      if (client) {
        filters.value.clientId = client.value;
      } else {
        delete filters.value.clientId;
      }
    };

    const filterFn = (val, update) => {
      if (val === '') {
        update(() => {
          clientOptions.value = allClients.value.map(client => ({
            label: client.company_name,
            value: client.client_id
          }));
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        clientOptions.value = allClients.value
          .filter(v => v.company_name.toLowerCase().indexOf(needle) > -1)
          .map(client => ({
            label: client.company_name,
            value: client.client_id
          }));
      });
    };

    // ==================== Collections Management ====================
    const selectedCollection = ref(null);
    const showCreateCollectionDialog = ref(false);
    const newCollectionName = ref('');
    const isCreatingCollection = ref(false);
    const collectionNameInput = ref(null);
    const showDeleteCollectionDialog = ref(false);
    const collectionToDelete = ref(null);
    const isDeletingCollection = ref(false);
    const showEditCollectionDialog = ref(false);
    const collectionToEdit = ref(null);
    const editCollectionName = ref('');
    const isEditingCollection = ref(false);
    const editCollectionNameInput = ref(null);

    const queryClient = useQueryClient();

    // Collections Query
    const collectionsQuery = useQuery({
      queryKey: ['collections'],
      queryFn: async () => {
        const response = await CollectionsApi.getAllCollections();
        return response.data.collections || [];
      },
      staleTime: 1000 * 60 * 5 // 5 minutes
    });

    const collections = computed(() => collectionsQuery.data.value || []);

    // Select collection
    const selectCollection = id => {
      selectedCollection.value = selectedCollection.value === id ? null : id;
      imagesQuery.refetch();
    };

    // Check if collection name already exists
    const isCollectionNameDuplicate = (name, excludeId = null) => {
      if (!name) return false;
      const trimmedName = name.trim().toLowerCase();
      return collections.value.some(collection => collection.name.trim().toLowerCase() === trimmedName && collection.id !== excludeId);
    };

    // Create Collection Mutation
    const createCollectionMutation = useMutation({
      mutationFn: name => CollectionsApi.createCollection(name),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['collections'] });
        showCreateCollectionDialog.value = false;
        newCollectionName.value = '';
        isCreatingCollection.value = false;
        root.$q.notify({
          message: 'Collection created successfully',
          color: 'positive',
          position: 'top',
          timeout: 2000
        });
      },
      onError: error => {
        isCreatingCollection.value = false;
        root.$q.notify({
          message: `Failed to create collection: ${error.message}`,
          color: 'negative',
          position: 'top',
          timeout: 2000
        });
      }
    });

    // Delete Collection Mutation
    const deleteCollectionMutation = useMutation({
      mutationFn: id => CollectionsApi.deleteCollection(id),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['collections'] });
      },
      onError: error => {
        isDeletingCollection.value = false;
        root.$q.notify({
          message: `Failed to delete collection: ${error.message}`,
          color: 'negative',
          position: 'top',
          timeout: 2000
        });
      }
    });

    // Update Collection Mutation
    const updateCollectionMutation = useMutation({
      mutationFn: ({ id, name }) => CollectionsApi.updateCollection(id, name),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['collections'] });
        showEditCollectionDialog.value = false;
        collectionToEdit.value = null;
        editCollectionName.value = '';
        isEditingCollection.value = false;
        root.$q.notify({
          message: 'Collection updated successfully',
          color: 'positive',
          position: 'top',
          timeout: 2000
        });
      },
      onError: error => {
        isEditingCollection.value = false;
        root.$q.notify({
          message: `Failed to update collection: ${error.message}`,
          color: 'negative',
          position: 'top',
          timeout: 2000
        });
      }
    });

    // Collection Action Handlers
    const createCollection = async () => {
      if (!newCollectionName.value) return;

      // Check for duplicate name
      if (isCollectionNameDuplicate(newCollectionName.value)) {
        root.$q.notify({
          message: 'A collection with this name already exists',
          color: 'warning',
          position: 'top',
          timeout: 2000
        });
        return;
      }

      isCreatingCollection.value = true;
      await createCollectionMutation.mutateAsync(newCollectionName.value);
    };

    const confirmDeleteCollection = collection => {
      collectionToDelete.value = collection;
      showDeleteCollectionDialog.value = true;
    };

    const confirmEditCollection = collection => {
      collectionToEdit.value = collection;
      editCollectionName.value = collection.name;
      showEditCollectionDialog.value = true;
    };

    const updateCollection = async () => {
      if (!collectionToEdit.value || !editCollectionName.value) return;

      // Check for duplicate name
      if (isCollectionNameDuplicate(editCollectionName.value, collectionToEdit.value.id)) {
        root.$q.notify({
          message: 'A collection with this name already exists',
          color: 'warning',
          position: 'top',
          timeout: 2000
        });
        return;
      }

      isEditingCollection.value = true;
      await updateCollectionMutation.mutateAsync({
        id: collectionToEdit.value.id,
        name: editCollectionName.value
      });
    };

    const deleteCollection = async () => {
      if (!collectionToDelete.value) return;

      isDeletingCollection.value = true;
      try {
        const deletedCollectionId = collectionToDelete.value.id;
        await deleteCollectionMutation.mutateAsync(deletedCollectionId);

        // If the deleted collection was selected, reset to All Assets
        if (selectedCollection.value === deletedCollectionId) {
          selectedCollection.value = null;
          imagesQuery.refetch();
        }

        // Clear dialog state
        showDeleteCollectionDialog.value = false;
        collectionToDelete.value = null;
        isDeletingCollection.value = false;

        // Keep the notification only here
        root.$q.notify({
          message: 'Collection deleted successfully',
          color: 'positive',
          position: 'top',
          timeout: 2000
        });
      } catch (error) {
        isDeletingCollection.value = false;
        // Error notification is handled in mutation's onError
      }
    };

    // Handle images removed from collection
    const handleItemsRemovedFromCollection = async () => {
      // Refresh the collections data to update item counts
      await queryClient.invalidateQueries({ queryKey: ['collections'] });

      // Refresh the images in the current view
      imagesQuery.refetch();
    };

    // Watch for dialog open to focus input
    watch(showCreateCollectionDialog, isOpen => {
      if (isOpen) {
        // Clear previous input when dialog opens
        newCollectionName.value = '';

        // Use nextTick to ensure the DOM is updated
        root.$nextTick(() => {
          if (collectionNameInput.value) {
            collectionNameInput.value.focus();
          }
        });
      }
    });

    // Watch for edit dialog open to focus input
    watch(showEditCollectionDialog, isOpen => {
      if (isOpen) {
        // Use nextTick to ensure the DOM is updated
        root.$nextTick(() => {
          if (editCollectionNameInput.value) {
            editCollectionNameInput.value.focus();
          }
        });
      }
    });

    // ==================== Images Gallery ====================
    const imagesQuery = useInfiniteQuery({
      queryKey: ['images', filters],
      queryFn: async ({ pageParam = null }) => {
        const payload = {
          filters: {
            ...(filters.value.date.from || filters.value.date.to ? { date: filters.value.date } : {}),
            ...(filters.value.services?.length ? { services: filters.value.services } : {}),
            ...(filters.value.aspectRatios?.length ? { aspectRatios: filters.value.aspectRatios } : {}),
            ...(filters.value.metadataSearchQuery ? { metadataSearchQuery: filters.value.metadataSearchQuery } : {}),
            ...(filters.value.visualSearchQuery ? { visualSearchQuery: filters.value.visualSearchQuery } : {}),
            ...(filters.value.clientId ? { clientId: filters.value.clientId } : {}),
            ...(filters.value.collectionId ? { collectionId: filters.value.collectionId } : {})
          },
          pagination: {
            limit: 40,
            cursor: pageParam
          }
        };
        const response = await ContentApi.getImages(payload);

        return response;
      },
      getNextPageParam: lastPage => {
        return lastPage.pagination.hasMore ? lastPage.pagination.nextCursor : undefined;
      },
      initialPageParam: null
    });

    const currentPagination = computed(() => {
      if (!imagesQuery.data.value?.pages.length) return null;
      return imagesQuery.data.value.pages[imagesQuery.data.value.pages.length - 1].pagination;
    });

    const allImages = computed(() => {
      if (!imagesQuery.data.value) return [];
      return imagesQuery.data.value.pages.flatMap(page => page.images);
    });

    const shouldShowLoadingSpinner = computed(() => {
      return imagesQuery.isFetching.value && imagesQuery.hasNextPage.value && !imagesQuery.isLoading.value;
    });

    // Handle infinite scroll
    const handleScroll = debounce(async event => {
      const container = event.target;
      const scrollPosition = container.scrollTop + container.clientHeight;
      const scrollThreshold = container.scrollHeight - 200;

      if (scrollPosition >= scrollThreshold && !imagesQuery.isFetching.value && imagesQuery.hasNextPage.value) {
        await imagesQuery.fetchNextPage();
      }
    }, 100);

    // ==================== Integrations ====================
    // Query for fetching integration status
    const { data: integrationStatus } = useQuery({
      queryKey: ['facebook-status'],
      queryFn: async () => {
        const response = await IntegrationsApi.getFacebookStatus();
        return response.data;
      },
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    });

    const isMetaConnected = computed(() => integrationStatus.value?.connected || false);
    const metaIntegration = computed(() => integrationStatus.value?.integration || null);

    // Add new state for upload dialog
    const showUploadDialog = ref(false);

    // Upload success handler
    const handleUploadSuccess = result => {
      if (result.partialSuccess) {
        // Handle partial success with warning color
        root.$q.notify({
          message: `${result.count} images uploaded to All Assets. ${result.errorMessage}`,
          color: 'warning',
          position: 'top',
          timeout: 5000
        });
      } else {
        root.$q.notify({
          message: `Successfully uploaded ${result.count} images ${result.collectionName !== 'All Assets' ? `to collection "${result.collectionName}"` : ''}`,
          color: 'positive',
          position: 'top',
          timeout: 3000
        });
      }

      // Refresh images and collections
      imagesQuery.refetch();
      queryClient.invalidateQueries({ queryKey: ['collections'] });
    };

    // Upload error handler
    const handleUploadError = errorMessage => {
      root.$q.notify({
        message: errorMessage,
        color: 'negative',
        position: 'top',
        timeout: 3000
      });
    };

    return {
      // Scroll handling
      scrollContainer,
      handleScroll,

      // User data
      user,

      // Filter states and handlers
      metadataSearchQuery,
      visualSearchQuery,
      dateRange,
      selectedServices,
      selectedAspectRatios,
      serviceOptions,
      aspectRatioOptions,
      hasActiveFilters,
      handleDateRangeChange,
      handleServicesChange,
      handleAspectRatioChange,
      clearFilters,
      handleVisualSearch,
      debouncedHandleMetadataSearch,

      // Client filtering
      selectedClient,
      clientOptions,
      filteredClientOptions,
      handleClientSelect,
      isLoadingClients,
      filterFn,

      // Collections data and queries
      collectionsQuery,
      collections,
      selectedCollection,
      selectCollection,

      // Collection dialogs states and handlers
      showCreateCollectionDialog,
      newCollectionName,
      isCreatingCollection,
      collectionNameInput,
      createCollection,
      showDeleteCollectionDialog,
      collectionToDelete,
      isDeletingCollection,
      confirmDeleteCollection,
      deleteCollection,
      showEditCollectionDialog,
      collectionToEdit,
      editCollectionName,
      isEditingCollection,
      editCollectionNameInput,
      confirmEditCollection,
      updateCollection,

      // Images gallery
      imagesQuery,
      allImages,
      shouldShowLoadingSpinner,
      currentPagination,

      // Integrations
      isMetaConnected,
      metaIntegration,

      // New method
      handleItemsRemovedFromCollection,

      // Upload dialog
      showUploadDialog,

      // Upload success handler
      handleUploadSuccess,

      // Upload error handler
      handleUploadError
    };
  }
};
</script>

<style lang="scss" scoped>
.gallery-container {
  @include inter-font($size: 12px, $color: var(--main-text-color));
  display: grid;
  grid-template-columns: 320px 1fr;
  height: calc(100vh - 76px);
  background: #fafafa;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}

.gallery-content {
  overflow-y: scroll;
  padding: 0 16px;
  background-color: #fafafa;
  scroll-behavior: smooth;
}

.full-height {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
